<template>
    <v-container fluid fill-height>
        <v-layout justify-center>
            <v-responsive max-width="1280px" width="100%" height="100%">
                <v-row class="mt-8" justify="center" align="center">
                    <v-col cols="auto" class="pt-0 pb-0 headline text-start">Withdrawn User List</v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="pt-0 pb-0">
                        <v-btn class="ma-2" color="secondary" rounded @click="showsSearch = !showsSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                    </v-col>
                </v-row>

                <v-expand-transition>
                    <v-card v-show="showsSearch" class="elevation-1 mx-auto">
                        <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                        <v-divider />
                        <v-card-text>
                            <v-row align="center">
                                <v-col cols="12" sm="6">
                                    <v-text-field v-model="filter.name" dense hide-details type="text" label="사용자명" persistent-placeholder />
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field v-model="filter.phone" dense hide-details type="text" label="연락처" persistent-placeholder />
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-divider />
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" rounded text @click="search"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-expand-transition>

                <v-data-table :headers="usersHeaders" :items="users" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                    <template #[`item.createdAt`]="{ item }">
                        {{ item.createdAt ? item.createdAt.toDateTime() : null }}
                    </template>
                    <template #[`item.phone`]="{ item }">
                        {{ item.phone ? item.phone.phoneNumberFormat() : null }}
                    </template>
                    <template #[`item.level`]="{ item }">
                        {{ item.level ? item.level.name : "-" }}
                    </template>
                    <template #[`item.warning`]="{ item }">
                        <v-row justify="center" align="center">
                            <v-col cols="auto" class="pa-0">
                                <v-switch v-model="item.warning" color="red lighten-1" hide-details dens class="mt-0"></v-switch>
                            </v-col>
                        </v-row>
                    </template>
                    <template #[`item.actions`]="{ item }">
                        <v-icon small @click="remove(item)"> mdi-delete </v-icon>
                    </template>
                </v-data-table>
                <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12 mx-4" @input="search"></v-pagination>
            </v-responsive>
        </v-layout>
    </v-container>
</template>

<script>
import api from "@/api";
const usersHeaders = [
    { text: "아이디", align: "center", width: 200, value: "username" },
    { text: "사용자명", align: "center", width: 200, value: "name" },
    { text: "연락처", align: "center", width: 128, value: "phone" },
    { text: "이메일", align: "center", width: 128, value: "email" },
    // { text: "등급", align: "center", width: 140, value: "level" },
    { text: "주의회원", width: 100, align: "center", value: "warning" },
    { text: "탈퇴사유", align: "center", value: "withdrawalReason" },
    { text: "남기신말", align: "center", value: "withdrawalMessage" },
    { text: "가입일자", align: "center", width: 160, value: "createdAt" },
    { text: "회원삭제", align: "center", width: 60, value: "actions" },
];
const warningsItems = [
    { text: "전체", value: undefined },
    { text: "주의회원", value: true },
    { text: "미주의회원", value: false },
];
export default {
    data() {
        return {
            showsSearch: true,
            usersHeaders,
            warningsItems,

            page: 1,
            pageCount: 0,
            limit: 10,
            filter: {
                name: this.$route.query.name,
                phone: this.$route.query.phone,
                _level: this.$route.query._level,
                warning: undefined,
                isWithdrawn: true,
            },

            users: [],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async search() {
            try {
                var { summary, users } = await api.console.users.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.filter,
                });

                this.users = users;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        async remove(user) {
            try {
                const go = confirm(`해당 사용자를 삭제하시겠습니까?\n\이름:${user?.name}\n연락처:${user?.phone}\n이메일:${user?.email}\n\n해당 사용자와 연관된 정보는 모두 유실되며 한번 유실된 데이터는 복구할 수 없습니다.`);
                if (go) {
                    await api.console.users.delete(user);

                    alert("사용자가 삭제되었습니다.");
                    this.$router.go();
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
};
</script>

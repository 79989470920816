var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', _vm._b({
    attrs: {
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c('v-card-subtitle', {
          staticClass: "grey lighten-4 caption"
        }, [_vm._v(" 지역별 지사 배정 내역 ")]), _c('v-divider'), _c('v-row', {
          staticClass: "ma-0 pa-1"
        }, [_c('v-col', {
          staticClass: "pa-2",
          attrs: {
            "cols": "12",
            "md": "6",
            "xl": "3"
          }
        }, [_c('v-select', _vm._b({
          attrs: {
            "label": "배정여부",
            "items": _vm.assignments
          },
          model: {
            value: _vm.filter.assignment,
            callback: function ($$v) {
              _vm.$set(_vm.filter, "assignment", $$v);
            },
            expression: "filter.assignment"
          }
        }, 'v-select', _vm.inputAttrs, false))], 1), _c('v-col', {
          staticClass: "pa-2",
          attrs: {
            "cols": "12",
            "md": "6",
            "xl": "3"
          }
        }, [_c('v-select', _vm._b({
          attrs: {
            "label": "유형",
            "items": _vm.zoneTypes
          },
          model: {
            value: _vm.filter.type,
            callback: function ($$v) {
              _vm.$set(_vm.filter, "type", $$v);
            },
            expression: "filter.type"
          }
        }, 'v-select', _vm.inputAttrs, false))], 1), _c('v-col', {
          staticClass: "pa-2",
          attrs: {
            "cols": "12",
            "md": "6",
            "xl": "3"
          }
        }, [_c('v-select', _vm._b({
          attrs: {
            "label": "대륙",
            "items": _vm.continents,
            "item-text": "name",
            "item-value": "_id"
          },
          on: {
            "change": function ($event) {
              _vm.filter._country = null;
            }
          },
          model: {
            value: _vm.filter._continent,
            callback: function ($$v) {
              _vm.$set(_vm.filter, "_continent", $$v);
            },
            expression: "filter._continent"
          }
        }, 'v-select', _vm.inputAttrs, false))], 1), _c('v-col', {
          staticClass: "pa-2",
          attrs: {
            "cols": "12",
            "md": "6",
            "xl": "3"
          }
        }, [_c('v-select', _vm._b({
          attrs: {
            "label": "국가",
            "items": _vm.countries,
            "item-text": "name",
            "item-value": "_id",
            "disabled": _vm.countries.length == 0
          },
          model: {
            value: _vm.filter._country,
            callback: function ($$v) {
              _vm.$set(_vm.filter, "_country", $$v);
            },
            expression: "filter._country"
          }
        }, 'v-select', _vm.inputAttrs, false))], 1)], 1), _c('v-divider')];
      },
      proxy: true
    }, _vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    })], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-layout justify-center>
        <v-responsive max-width="720" class="px-3">
            <list-heading title="상품목록" class="mt-8" />

            <v-data-table v-bind="{ headers, items }" hide-default-footer disable-pagination disable-sort class="my-4">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>

                <template #[`item.thumbnail.url`]="{ value }">
                    <image-popup size="40" :src="value" />
                </template>
                <template #[`item.actions`]="{ item }">
                    <v-btn small text icon @click="edit(item)" title="수정">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn small text icon @click="remove(item)" title="삭제">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </template>
                <template #footer>
                    <v-divider />
                    <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="py-2" @input="search"></v-pagination>
                </template>
            </v-data-table>

            <v-btn bottom color="accent" fab fixed right @click="create">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import ImagePopup from "@/components/console/dumb/image-popup.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import CategorySelect from "@/components/console/shop/categories/category-select.vue";
import { PRODUCT_TYPES } from "@/assets/variables";
const headers = [
    { width: +80, value: "type", text: "유형", cellClass: "text-truncate", formatter: (value) => PRODUCT_TYPES[value]?.text || value || "-", divider: true },
    { width: +80, value: "thumbnail.url", text: "썸네일", cellClass: "text-truncate", divider: true },
    { width: 180, value: "name", text: "제품명", cellClass: "text-truncate", divider: true },
    { width: 180, value: "category.name", text: "카테고리", cellClass: "text-truncate", divider: true },
    { width: +90, value: "actions", align: "end" },
];

export default {
    components: {
        ImagePopup,
        ListHeading,
        CategorySelect,
    },
    data() {
        return {
            dialog: false,
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////

            category: null,
            filter: {
                _category: this.$route.query._category || null,
                name: this.$route.query.name || null,
                searchKey: this.$route.query.searchKey || null,
                searchValue: this.$route.query.searchValue || null,
            },

            searchKeys: [
                { text: ":: 전체 ::", value: null },
                { text: "제품명", value: "name.ko" },
                { text: "기업명", value: "seller.companyName.ko" },
            ],

            categories: [],

            page: +this.$route.query.page || 1,
            excelLimit: 5000,
            limit: 10,

            summary: { totalCount: 0 },
            products: [],

            excelHeaders: [
                { text: "skip", align: "center", width: 250, value: "goods" },
                { text: "limit", align: "center", width: 250, value: "goods" },
                { text: "다운로드", align: "center", width: 250, value: "goods" },
            ],

            headers,
        };
    },
    computed: {
        items() {
            return this.products.map((item, index) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - index }));
        },

        excelCount() {
            return Math.ceil(this.summary.totalCount / this.excelLimit);
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            var { categories } = await api.console.shop.categories.gets({ params: { depth: 1 } });
            this.categories = categories;

            if (this.filter._category) {
                var { category } = await api.console.shop.categories.get({ _id: this.filter._category });
                this.category = category;
            }

            this.search();
        },
        async search(routable = false) {
            if (this.routable) {
                this.$router.push({
                    query: Object.assignDefined({}, this.filter, { page: this.page }),
                });
            }

            var { summary, products } = await api.console.shop.products.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit,
                },
                params: this.filter,
            });

            this.products = products;
            this.summary = summary;
        },
        create() {
            this.$router.push(`${this.$route.path}/create`);
        },
        copy(product) {
            this.$router.push(`${this.$route.path}/copy?_product=${product._id}`);
        },
        edit(product) {
            this.$router.push(`${this.$route.path}/${product._id}`);
        },

        async save(product) {
            await api.console.shop.products.put(product);
            await this.search();
        },

        async remove(product) {
            if (confirm("해당 상품을 삭제하시겠습니까?")) {
                await api.console.shop.products.delete(product);
                await this.search();

                alert("삭제되었습니다");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep td.text-truncate {
    max-width: 0;
}
</style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._b({
    on: {
      "click": _vm.openFileInput
    }
  }, 'v-card', _vm.$attrs, false), [_c('v-img', _vm._b({}, 'v-img', Object.assign({}, _vm.$attrs, {
    src: _vm.src
  }), false), [_c('v-fade-transition', [_c('v-overlay', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.src,
      expression: "!src"
    }],
    attrs: {
      "light": "",
      "color": "transparent",
      "absolute": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey"
    }
  }, [_vm._v("mdi-image")])], 1)], 1)], 1), _c('v-file-input', _vm._b({
    ref: "fileInput",
    staticClass: "d-none",
    on: {
      "change": function ($event) {
        return _vm.$emit('input', _vm.file);
      }
    },
    model: {
      value: _vm.file,
      callback: function ($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  }, 'v-file-input', _vm.$attrs, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-dialog v-model="shows" width="320">
        <template #activator="{attrs,on}">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card>
            <v-card-title>
                지역 {{ isCreate ? "생성" : "수정" }}
                <v-spacer />
                <v-btn text icon @click="shows = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text class="pt-5 pb-0">
                <template v-if="isCreate">
                    <v-select label="유형" v-model="item.type" :items="zoneTypes" v-bind="inputAttrs" @change="resetItem" />
                </template>
                <template v-else>
                    <v-text-field label="유형" :value="item.typeText" :readonly="!isCreate" v-bind="inputAttrs" />
                </template>

                <!-- 대륙:S -->
                <v-fade-transition hide-on-leave>
                    <div v-show="isType(CONTINENT)">
                        <v-text-field label="대륙코드" v-model="item.code" :readonly="!isCreate" v-bind="inputAttrs" />
                        <v-text-field label="대륙명" v-model="item.name" v-bind="inputAttrs" />
                    </div>
                </v-fade-transition>
                <v-fade-transition hide-on-leave>
                    <div v-show="!isType(CONTINENT)">
                        <v-select label="대륙" v-model="item._continent" :items="continents" item-text="name" item-value="_id" v-bind="inputAttrs" @change="item._country = null" placeholder="대륙을 선택하세요" />
                    </div>
                </v-fade-transition>
                <!-- 대륙:E -->

                <!-- 국가:S -->
                <v-fade-transition hide-on-leave>
                    <div v-show="isType(COUNTRY)">
                        <v-text-field label="국가코드" v-model="item.code" :readonly="!isCreate" v-bind="inputAttrs" />
                        <v-text-field label="국가명" v-model="item.name" v-bind="inputAttrs" />
                    </div>
                </v-fade-transition>
                <v-fade-transition hide-on-leave>
                    <v-select v-show="isType(REGION)" label="국가" v-model="item._country" :items="countries" item-text="name" item-value="_id" v-bind="inputAttrs" no-data-text="대륙을 먼저 선택하세요" placeholder="국가를 선택하세요" />
                </v-fade-transition>
                <!-- 국가:E -->

                <!-- 지역:S -->
                <v-fade-transition hide-on-leave>
                    <div v-show="isType(REGION)">
                        <v-text-field label="지역코드" v-model="item.code" :readonly="!isCreate" v-bind="inputAttrs" />
                        <v-text-field label="지역명" v-model="item.name" v-bind="inputAttrs" />
                    </div>
                </v-fade-transition>
                <!-- 지역:E -->
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer />
                <v-btn color="primary" @click="save">저장하기</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { inputAttrs as attrs, ZONE_TYPES } from "@/assets/variables";
const { CONTINENT, COUNTRY, REGION } = ZONE_TYPES;
const zoneTypes = Object.values(ZONE_TYPES);

const initItem = () => ({
    type: REGION.value,
    _continent: null,
    _country: null,
    code: null,
    name: null,
});

let inputAttrs = JSON.parse(JSON.stringify(attrs));
delete inputAttrs.hideDetails;

export default {
    props: {
        value: { type: Object, default: () => ({}) },
        zones: { type: Array, defatul: () => [] },
    },
    data: () => ({
        shows: false,

        item: initItem(),

        REGION,
        COUNTRY,
        CONTINENT,
        zoneTypes,

        inputAttrs,
    }),
    computed: {
        isCreate() {
            return !this.value?._id;
        },
        continents() {
            return (this.zones || []).filter(({ type }) => type == ZONE_TYPES.CONTINENT.value).sort((a, b) => a.name?.localeCompare?.(b.name));
        },
        countries() {
            if (!this.item?._continent) return [];
            return (this.zones || []).filter(({ type, _continent }) => type == ZONE_TYPES.COUNTRY.value && _continent == this.item?._continent).sort((a, b) => a.name?.localeCompare?.(b.name));
        },
    },
    watch: {
        shows() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.item = this.isCreate ? initItem() : JSON.parse(JSON.stringify(this.value));
        },
        save() {
            this.$emit("input", this.item);
            alert("처리되었습니다");
            this.shows = false;
        },
        isType({ value }) {
            return this.item.type == value;
        },
        resetItem(type = this.value?.type || REGION.value) {
            const { _id } = this.value || {};
            this.item = { ...initItem(), _id, type };
        },
    },
};
</script>

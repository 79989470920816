var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._b({
    attrs: {
      "flat": ""
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("카테고리 " + _vm._s(_vm.isCreate ? "생성하기" : "수정하기"))]), _c('v-card-text', [_vm.categories ? _c('category-select', _vm._b({
    attrs: {
      "dense": "",
      "hideDetails": ""
    },
    model: {
      value: _vm.form._parent,
      callback: function ($$v) {
        _vm.$set(_vm.form, "_parent", $$v);
      },
      expression: "form._parent"
    }
  }, 'category-select', {
    categories: _vm.categories,
    _disableds: [_vm.form._id]
  }, false)) : _vm._e(), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "카테고리 이름",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "카테고리 코드",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.code,
      callback: function ($$v) {
        _vm.$set(_vm.form, "code", $$v);
      },
      expression: "form.code"
    }
  }), _c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "카테고리 색상",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('v-avatar', {
          staticClass: "elevation-4",
          attrs: {
            "color": _vm.form.color,
            "size": "24"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.color,
      callback: function ($$v) {
        _vm.$set(_vm.form, "color", $$v);
      },
      expression: "form.color"
    }
  }), _c('v-expand-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isCreate,
      expression: "!isCreate"
    }]
  }, [_c('category-products', _vm._b({
    staticClass: "mt-3",
    attrs: {
      "label": "노출상품 설정",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.products__nrml,
      callback: function ($$v) {
        _vm.$set(_vm.form, "products__nrml", $$v);
      },
      expression: "form.products__nrml"
    }
  }, 'category-products', {
    _category: _vm._category
  }, false)), _c('category-products', _vm._b({
    staticClass: "mt-3",
    attrs: {
      "label": "노출키트 설정",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.products__kits,
      callback: function ($$v) {
        _vm.$set(_vm.form, "products__kits", $$v);
      },
      expression: "form.products__kits"
    }
  }, 'category-products', {
    _category: _vm._category
  }, false))], 1)])], 1), _c('v-card-actions', [_c('v-fade-transition', [_c('v-btn', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isCreate,
      expression: "!isCreate"
    }],
    staticClass: "white--text",
    attrs: {
      "disabled": !_vm.form._id || _vm.form.root ? true : false,
      "color": "grey darken-1"
    },
    on: {
      "click": _vm.remove
    }
  }, [_vm._v("삭제")])], 1), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
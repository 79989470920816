var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "1080",
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.title) + " "), _c('v-btn', {
    attrs: {
      "text": "",
      "icon": "",
      "absolute": "",
      "right": ""
    },
    on: {
      "click": function ($event) {
        _vm.show = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "pa-0 pb-1 mb-n1",
    staticStyle: {
      "max-height": "calc(90vh - 58px - 1px - 1px - 52px )",
      "overflow-y": "auto"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-row', {
    staticClass: "ma-2",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "ma-2",
    attrs: {
      "label": "강연자",
      "placeholder": "강연자를 선택하세요",
      "items": _vm.lecturers,
      "item-text": "name",
      "item-value": "_id"
    },
    model: {
      value: _vm.item._lecturer,
      callback: function ($$v) {
        _vm.$set(_vm.item, "_lecturer", $$v);
      },
      expression: "item._lecturer"
    }
  }, 'v-select', _vm.inputAttrs, false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-select', _vm._b({
    staticClass: "ma-2",
    attrs: {
      "label": "카테고리",
      "items": _vm.categories
    },
    model: {
      value: _vm.item.category,
      callback: function ($$v) {
        _vm.$set(_vm.item, "category", $$v);
      },
      expression: "item.category"
    }
  }, 'v-select', _vm.inputAttrs, false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-currency-field', _vm._b({
    staticClass: "ma-2 text-align-right",
    attrs: {
      "label": "바우처 기간",
      "type": "number",
      "suffix": "일"
    },
    model: {
      value: _vm.item.voucherDays,
      callback: function ($$v) {
        _vm.$set(_vm.item, "voucherDays", $$v);
      },
      expression: "item.voucherDays"
    }
  }, 'v-currency-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "lg": "3"
    }
  }, [_c('v-currency-field', _vm._b({
    staticClass: "ma-2",
    attrs: {
      "label": "바우처 금액",
      "type": "number",
      "prefix": "USD"
    },
    model: {
      value: _vm.item.voucherCost,
      callback: function ($$v) {
        _vm.$set(_vm.item, "voucherCost", $$v);
      },
      expression: "item.voucherCost"
    }
  }, 'v-currency-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "ma-2",
    attrs: {
      "label": "제목"
    },
    model: {
      value: _vm.item.name,
      callback: function ($$v) {
        _vm.$set(_vm.item, "name", $$v);
      },
      expression: "item.name"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1)], 1), _c('naver-smarteditor', {
    staticClass: "ma-4 mt-0",
    model: {
      value: _vm.item.desc,
      callback: function ($$v) {
        _vm.$set(_vm.item, "desc", $$v);
      },
      expression: "item.desc"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', [_c('v-card-subtitle', {
    staticClass: "caption font-weight-bold"
  }, [_vm._v("썸네일"), _c('span', {
    staticClass: "pl-1 font-weight-medium grey--text"
  }, [_vm._v("(486*274px)")])]), _c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var hover = _ref2.hover;
        return [_c('v-img', _vm._b({
          staticClass: "ma-4 mt-0",
          attrs: {
            "aspect-ratio": 16 / 9
          }
        }, 'v-img', {
          src: _vm.src
        }, false), [_c('v-fade-transition', [_c('v-overlay', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: hover && !!_vm.src,
            expression: "hover && !!src"
          }],
          attrs: {
            "absolute": "",
            "z-index": "0"
          }
        }, [_c('v-btn', {
          attrs: {
            "fab": "",
            "color": "primary"
          },
          on: {
            "click": _vm.showThumbInput
          }
        }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1), _c('v-btn', {
          staticClass: "ml-2",
          attrs: {
            "fab": "",
            "color": "red"
          },
          on: {
            "click": function ($event) {
              _vm.item.thumb = null;
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)], 1)], 1), _c('v-fade-transition', [_c('v-btn', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.src,
            expression: "!src"
          }],
          attrs: {
            "tile": "",
            "height": "100%",
            "width": "100%"
          },
          on: {
            "click": _vm.showThumbInput
          }
        }, [_c('v-icon', {
          attrs: {
            "x-large": ""
          }
        }, [_vm._v("mdi-image-broken")])], 1)], 1), _c('v-file-input', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false"
          }],
          ref: "thumb-input",
          staticClass: "ma-0",
          attrs: {
            "accept": "image/*"
          },
          model: {
            value: _vm.item.thumb,
            callback: function ($$v) {
              _vm.$set(_vm.item, "thumb", $$v);
            },
            expression: "item.thumb"
          }
        })], 1)];
      }
    }])
  }), _c('v-divider'), _c('lecture-unit-list', {
    ref: "unit-list",
    attrs: {
      "lecture": _vm.item
    },
    model: {
      value: _vm.item.units,
      callback: function ($$v) {
        _vm.$set(_vm.item, "units", $$v);
      },
      expression: "item.units"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "grey"
    },
    on: {
      "click": _vm.init
    }
  }, [_vm._v("초기화")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "720"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "align-center"
  }, [_vm._v(" " + _vm._s(_vm.title) + " - 담당지사 변경 "), _c('v-btn', {
    attrs: {
      "absolute": "",
      "right": "",
      "text": "",
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 담당지사 ")]), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto",
      "align-self": "stretch"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-chip-group', {
    attrs: {
      "column": ""
    },
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, _vm._l(_vm.selected, function (branch) {
    var _branch$company;
    return _c('v-chip', {
      key: branch === null || branch === void 0 ? void 0 : branch._id,
      attrs: {
        "value": branch,
        "close": ""
      },
      on: {
        "click:close": function ($event) {
          return _vm.remove(branch);
        }
      }
    }, [_vm._v(" " + _vm._s(branch === null || branch === void 0 ? void 0 : (_branch$company = branch.company) === null || _branch$company === void 0 ? void 0 : _branch$company.name) + " ")]);
  }), 1)], 1)], 1)], 1), _c('v-divider'), _c('v-data-table', _vm._b({
    attrs: {
      "show-select": "",
      "item-key": "_id"
    },
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items
  }, false)), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    on: {
      "click": _vm.reset
    }
  }, [_vm._v("초기화")]), _c('v-spacer'), _c('v-btn', {
    on: {
      "click": _vm.save
    }
  }, [_vm._v("변경")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-dialog v-model="show" max-width="1080" persistent>
        <template #activator="{attrs, on}">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card>
            <v-card-title>
                {{ title }}
                <v-btn text icon absolute right @click="show = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0 pb-1 mb-n1" style="max-height: calc(90vh - 58px - 1px - 1px - 52px ); overflow-y: auto;">
                <v-row no-gutters>
                    <v-col cols="8">
                        <v-row class="ma-2" no-gutters>
                            <v-col cols="6" lg="3"> <v-select label="강연자" placeholder="강연자를 선택하세요" v-model="item._lecturer" :items="lecturers" item-text="name" item-value="_id" class="ma-2" v-bind="inputAttrs" /> </v-col>
                            <v-col cols="6" lg="3"> <v-select label="카테고리" v-model="item.category" :items="categories" class="ma-2" v-bind="inputAttrs" /> </v-col>
                            <v-col cols="6" lg="3"> <v-currency-field label="바우처 기간" v-model="item.voucherDays" type="number" suffix="일" class="ma-2 text-align-right" v-bind="inputAttrs" /> </v-col>
                            <v-col cols="6" lg="3"> <v-currency-field label="바우처 금액" v-model="item.voucherCost" type="number" prefix="USD" class="ma-2" v-bind="inputAttrs" /> </v-col>
                            <v-col cols="12"> <v-text-field label="제목" v-model="item.name" v-bind="inputAttrs" class="ma-2" /> </v-col>
                        </v-row>
                        <naver-smarteditor v-model="item.desc" class="ma-4 mt-0" />
                    </v-col>
                    <v-col cols="auto"> <v-divider vertical /> </v-col>
                    <v-col>
                        <!-- S:썸네일 -->
                        <v-card-subtitle class="caption font-weight-bold">썸네일<span class="pl-1 font-weight-medium grey--text">(486*274px)</span></v-card-subtitle>
                        <v-hover v-slot="{ hover }">
                            <v-img :aspect-ratio="16 / 9" v-bind="{ src }" class="ma-4 mt-0">
                                <v-fade-transition>
                                    <v-overlay v-show="hover && !!src" absolute z-index="0">
                                        <v-btn fab color="primary" @click="showThumbInput">
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>
                                        <v-btn fab color="red" @click="item.thumb = null" class="ml-2">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </v-overlay>
                                </v-fade-transition>
                                <v-fade-transition>
                                    <v-btn v-show="!src" tile height="100%" width="100%" @click="showThumbInput">
                                        <v-icon x-large>mdi-image-broken</v-icon>
                                    </v-btn>
                                </v-fade-transition>
                                <v-file-input v-model="item.thumb" accept="image/*" v-show="false" ref="thumb-input" class="ma-0" />
                            </v-img>
                        </v-hover>
                        <v-divider />
                        <!-- E:썸네일 -->
                        <lecture-unit-list v-model="item.units" :lecture="item" ref="unit-list" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-btn outlined color="grey" @click="init">초기화</v-btn>
                <v-spacer />
                <v-btn color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { inputAttrs, ACADEMY_LECTURE_CATEGORIES } from "@/assets/variables";
import LectureUnitList from "./lecture-unit-list.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
const initItem = () => ({
    _lecturer: null,
    category: null,
    name: null,
    desc: null,
    thumb: null,
    units: [],
    voucherDays: 0,
    voucherCost: 0,
});
const categories = Object.values(ACADEMY_LECTURE_CATEGORIES);
export default {
    components: {
        LectureUnitList,
        NaverSmarteditor,
    },
    props: {
        value: { type: Object, default: () => ({}) },
        lecturers: { type: Array, default: () => [] },
    },
    data: () => ({
        show: false,
        item: initItem(),
        inputAttrs,
        categories,
    }),
    computed: {
        isCreate() {
            return !this.value?._id;
        },
        title() {
            return this.isCreate ? "온디맨드 생성" : "온디맨드 상세";
        },
        src() {
            return this.item?.thumb instanceof File ? URL.createObjectURL(this.item?.thumb) : this.item?.thumb?.url || null;
        },
    },
    watch: {
        show(show) {
            if (show) this.init();
        },
    },
    methods: {
        async init() {
            this.item = initItem();
            if (!this.isCreate) {
                const { lecture } = await api.console.academy.lectures.get({ _id: this.value?._id });
                if (lecture?.thumb?.url) lecture.thumb = await api.getResource(lecture.thumb.url);
                else lecture.thumb = null;

                this.item = lecture;
            }
        },

        async save() {
            const { post, put } = api.console.academy.lectures;

            let { _thumb, thumb, _units, units, ...item } = this.item;

            if (_thumb) {
                await api.console.commonFiles.delete({ _id: _thumb });
                item._thumb = null;
            }
            let { lecture } = await (this.isCreate ? post(item) : put(item));
            if (thumb) {
                const { file } = await api.console.commonFiles.post({ directory: lecture?._id, index: "thumb" }, thumb);
                if (file) [{ lecture }] = [await put({ _id: lecture?._id, _thumb: file?._id })];
            }
            this.item = { ...lecture, thumb, units };

            // 개별 강의 데이터 처리
            this.$nextTick(async () => {
                await this.$refs["unit-list"]?.save?.();
                const { _id: _lecture } = this.item;
                [{ _units, units }] = [this.item];
                const _unitsDeleted = _units.filter((_unit) => !units.some(({ _id }) => _id == _unit));
                await Promise.all(_unitsDeleted.map(async (_id) => await api.console.academy.lectures.units.delete({ _id, _lecture })));
                [{ lecture }] = [await put({ _id: _lecture, _units: units.map(({ _id }) => _id) })];

                this.$emit("input", lecture);
                alert("저장되었습니다");

                this.show = false;
            });
        },

        showThumbInput() {
            this.$refs["thumb-input"]?.$el.getElementsByTagName("input")?.[0]?.click?.();
        },
    },
};
</script>

<style lang="scss" scoped>
.v-text-field.text-align-right ::v-deep input {
    text-align: right;
}
</style>

<template>
    <v-sheet>
        <v-card-subtitle class="caption font-weight-bold">목록 이미지<span class="pl-1 font-weight-medium grey--text"></span></v-card-subtitle>
        <v-card-text>
            <v-hover v-slot="{ hover }">
                <v-img :aspect-ratio="1 / 1" v-bind="{ src }">
                    <v-fade-transition>
                        <v-overlay v-show="hover && !!src" absolute z-index="0">
                            <v-btn fab color="primary" @click="showFileDialog">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn fab color="red" @click="remove" class="ml-2">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-overlay>
                    </v-fade-transition>
                    <v-fade-transition>
                        <v-btn v-show="!src" tile height="100%" width="100%" @click="showFileDialog">
                            <v-icon x-large>mdi-image-broken</v-icon>
                        </v-btn>
                    </v-fade-transition>
                </v-img>
            </v-hover>
            <v-file-input v-model="file" v-show="false" ref="file-input" />
        </v-card-text>
        <v-divider />
    </v-sheet>
</template>

<script>
import api from "@/api";
export default {
    props: {
        value: { type: Object, default: () => ({}) }, // product
    },
    data: () => ({
        file: null,
    }),
    computed: {
        src() {
            if (!!this.file) return URL.createObjectURL(this.file);
            else return this.value?.thumbnail?.url;
        },
    },
    methods: {
        async remove() {
            const { thumbnail, _thumbnail, ...product } = this.value;
            if (_thumbnail) {
                await api.console.commonFiles.delete({ _id: _thumbnail });
                this.$emit("input", product);
            }
        },

        async save() {
            if (!this.file) return;
            await this.remove();
            const { file } = await api.console.commonFiles.post({ directory: this.value?._id, index: "thumbnail" }, this.file);
            const { product } = await api.console.shop.products.put({ _id: this.value?._id, _thumbnail: file._id });
            this.$emit("input", product);
        },

        showFileDialog() {
            this.$refs["file-input"]?.$el.getElementsByTagName("input")?.[0]?.click?.();
        },
    },
};
</script>

<style></style>

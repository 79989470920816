var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "480",
      "width": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": "SMTP"
    }
  }), _c('v-card', _vm._b({
    attrs: {
      "outlined": ""
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-card-subtitle', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" Sender Account (Daou Office) ")]), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "username"
    },
    model: {
      value: _vm.setting.smtp.username,
      callback: function ($$v) {
        _vm.$set(_vm.setting.smtp, "username", $$v);
      },
      expression: "setting.smtp.username"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "type": "password",
      "label": "password"
    },
    model: {
      value: _vm.setting.smtp.password,
      callback: function ($$v) {
        _vm.$set(_vm.setting.smtp, "password", $$v);
      },
      expression: "setting.smtp.password"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1)], 1)], 1), _c('v-divider'), _c('v-card-subtitle', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" Receivers (E-Mail Address) ")]), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-0"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-combobox', _vm._b({
    attrs: {
      "multiple": "",
      "chips": "",
      "append-icon": "",
      "dense": false
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var item = _ref.item,
          parent = _ref.parent;
        return [_c('v-chip', {
          attrs: {
            "outlined": "",
            "label": "",
            "close": ""
          },
          on: {
            "click:close": function ($event) {
              return parent.selectItem(item);
            }
          }
        }, [_vm._v(_vm._s(item))])];
      }
    }]),
    model: {
      value: _vm.setting.smtp.receivers,
      callback: function ($$v) {
        _vm.$set(_vm.setting.smtp, "receivers", $$v);
      },
      expression: "setting.smtp.receivers"
    }
  }, 'v-combobox', _vm.inputAttrs, false))], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _vm._v(" "), _c('v-btn', _vm._b({
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v(" Save ")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
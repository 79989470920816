<template>
    <v-layout justify-center>
        <v-responsive max-width="720" class="px-3">
            <list-heading title="메인 비주얼 슬라이드 설정" class="mt-8" />
            <v-card class="my-4" style="overflow: hidden">
                <v-row no-gutters style="min-height: calc(100vh - 48px - 12px - 32px - 56px - 16px - 16px - 12px)">
                    <v-col cols="6" style="border-right: 1px solid rgba(0, 0, 0, 0.12)"> <banner-input label="Desktop" sublabel="권장사이즈:1680*760px" name="메인 비주얼 슬라이드 Desktop" code="main-visual-banners-desktop" /> </v-col>
                    <v-col cols="6"> <banner-input label="Mobile" sublabel="권장사이즈:720*720px" name="메인 비주얼 슬라이드 Mobile" code="main-visual-banners-mobile" /> </v-col>
                </v-row>
            </v-card>
        </v-responsive>
    </v-layout>
</template>

<script>
import ListHeading from "@/components/console/dumb/list-heading.vue";
import BannerInput from "@/components/console/banners/banner-input.vue";

export default {
    components: {
        ListHeading,
        BannerInput,
    },
};
</script>

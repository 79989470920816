var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('v-card-subtitle', {
    staticClass: "caption font-weight-bold"
  }, [_vm._v("상세 이미지 (Mobile)"), _c('span', {
    staticClass: "pl-1 font-weight-medium grey--text"
  })]), _c('v-card-text', [_c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var hover = _ref.hover;
        return [_c('v-img', _vm._b({
          attrs: {
            "aspect-ratio": 1 / 1
          }
        }, 'v-img', {
          src: _vm.src
        }, false), [_c('v-fade-transition', [_c('v-overlay', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: hover && !!_vm.src,
            expression: "hover && !!src"
          }],
          attrs: {
            "absolute": "",
            "z-index": "0"
          }
        }, [_c('v-btn', {
          attrs: {
            "fab": "",
            "color": "primary"
          },
          on: {
            "click": _vm.showFileDialog
          }
        }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1), _c('v-btn', {
          staticClass: "ml-2",
          attrs: {
            "fab": "",
            "color": "red"
          },
          on: {
            "click": _vm.remove
          }
        }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)], 1)], 1), _c('v-fade-transition', [_c('v-btn', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.src,
            expression: "!src"
          }],
          attrs: {
            "tile": "",
            "height": "100%",
            "width": "100%"
          },
          on: {
            "click": _vm.showFileDialog
          }
        }, [_c('v-icon', {
          attrs: {
            "x-large": ""
          }
        }, [_vm._v("mdi-image-broken")])], 1)], 1)], 1)];
      }
    }])
  }), _c('v-file-input', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "file-input",
    model: {
      value: _vm.file,
      callback: function ($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  })], 1), _c('v-divider')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
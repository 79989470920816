<template>
    <v-card-text>
        <v-hover v-slot="{ hover }">
            <v-img :aspect-ratio="1 / 1" v-bind="{ src }">
                <v-fade-transition>
                    <v-overlay v-show="hover && !!src" absolute z-index="0">
                        <v-btn fab color="primary" @click="showFileDialog">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn fab color="red" @click="remove" class="ml-2">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-overlay>
                </v-fade-transition>
                <v-fade-transition>
                    <v-btn v-show="!src" tile height="100%" width="100%" @click="showFileDialog">
                        <v-icon x-large>mdi-image-broken</v-icon>
                    </v-btn>
                </v-fade-transition>
            </v-img>
        </v-hover>
        <v-file-input v-model="file" v-show="false" accept="image/*" ref="file-input" />
    </v-card-text>
</template>

<script>
import api from "@/api";
export default {
    props: {
        value: { type: Object, default: () => ({}) }, // software
    },
    data: () => ({
        file: null,
    }),
    computed: {
        src() {
            if (!!this.file) return URL.createObjectURL(this.file);
            else return this.value?.thumb?.url;
        },
    },
    methods: {
        async remove() {
            const { thumb, _thumb, ...software } = this.value;
            if (_thumb) {
                await api.console.commonFiles.delete({ _id: _thumb });
                this.$emit("input", software);
            }
        },

        async save() {
            if (!this.file) return;
            await this.remove();
            const { file } = await api.console.commonFiles.post({ directory: this.value?._id, index: "thumb" }, this.file);
            const { software } = await api.console.membership.softwares.put({ _id: this.value?._id, _thumb: file._id });
            this.$emit("input", software);
        },

        showFileDialog() {
            this.$refs["file-input"]?.$el.getElementsByTagName("input")?.[0]?.click?.();
        },
    },
};
</script>

<style></style>

<template>
    <v-dialog v-model="shows" width="720" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card min-height="480" class="d-flex flex-column">
            <!-- loading -->
            <v-fade-transition>
                <v-overlay v-show="!branch" absolute light color="white">
                    <v-progress-circular indeterminate size="100" width="5" />
                </v-overlay>
            </v-fade-transition>

            <!-- content -->
            <v-card-title>
                {{ title }} <v-spacer /> <v-btn text icon @click="shows = false"> <v-icon>mdi-close</v-icon> </v-btn>
            </v-card-title>
            <v-card-text v-if="!!branch" class="pa-0" style="max-height: calc(90vh - 120px); overflow-y: auto">
                <v-divider />
                <v-card-subtitle class="grey lighten-4">계정</v-card-subtitle>
                <v-divider />
                <v-row class="ma-0 px-2">
                    <v-col class="pa-2">
                        <v-text-field label="ID" v-model="branch.username" v-bind="inputAttrs" />
                    </v-col>
                    <v-col class="pa-2">
                        <v-text-field label="PW" v-model="branch.password" v-bind="inputAttrs" type="password" autocomplete="new-password" />
                    </v-col>
                </v-row>

                <v-divider />
                <v-card-subtitle class="grey lighten-4">지사</v-card-subtitle>
                <v-divider />

                <v-row class="ma-0 px-2">
                    <v-col class="pa-2">
                        <v-select label="그룹" v-model="branch.company.group" :items="branchGroups" v-bind="inputAttrs" placeholder="그룹을 선택하세요" />
                    </v-col>
                </v-row>
                <v-row class="ma-0 px-2">
                    <v-col class="pa-2">
                        <v-text-field label="Company Name" v-model="branch.company.name" v-bind="inputAttrs" />
                    </v-col>
                    <v-col class="pa-2">
                        <v-text-field label="Contact Person" v-model="branch.contactPerson.name" v-bind="inputAttrs" />
                    </v-col>
                </v-row>
                <v-row class="ma-0 px-2">
                    <v-col class="pa-2">
                        <v-text-field label="TEL" v-model="branch.company.phone" v-bind="inputAttrs" />
                    </v-col>
                    <v-col class="pa-2">
                        <v-text-field label="FAX" v-model="branch.company.fax" v-bind="inputAttrs" />
                    </v-col>
                </v-row>
                <v-row class="ma-0 px-2">
                    <v-col class="pa-2">
                        <v-text-field label="EMAIL" v-model="branch.company.email" v-bind="inputAttrs" />
                    </v-col>
                </v-row>
                <v-row class="ma-0 px-2">
                    <v-col class="pa-2">
                        <v-text-field label="ADDRESS" v-model="branch.company.address" v-bind="inputAttrs" />
                    </v-col>
                </v-row>
                <v-divider />
                <v-card-subtitle class="grey lighten-4">웹사이트</v-card-subtitle>
                <v-divider />
                <branch-view-websites v-model="branch.company.websites" />
                <v-divider />
                <v-card-subtitle class="grey lighten-4">상세설명</v-card-subtitle>
                <v-divider />
                <naver-smarteditor v-model="branch.company.desc" />
                <v-divider />
                <v-card-subtitle class="grey lighten-4">지역</v-card-subtitle>
                <v-divider />
                <branch-view-zones v-model="branch._zone" v-bind="{ zones }" />
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer />
                <v-btn color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { BRANCH_GROUPS, inputAttrs } from "@/assets/variables";

import BranchViewZones from "./branch-view-zones.vue";
import BranchViewWebsites from "./branch-view-websites.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

const branchGroups = Object.values(BRANCH_GROUPS);
const initBranch = () => ({
    _id: null,
    _zone: null,

    username: null,
    password: null,

    company: {
        group: null,
        name: null,
        desc: null,
        fax: null,
        phone: null,
        email: null,
        address: null,
        postcode: null,
        websites: [],
    },

    contactPerson: {
        name: null,
    },
});

export default {
    components: {
        BranchViewZones,
        BranchViewWebsites,
        NaverSmarteditor,
    },
    props: {
        value: { type: Object, default: () => ({}) },
        zones: { type: Array, default: () => [] },
    },
    data: () => ({
        shows: false,
        branch: undefined,
        inputAttrs,
        branchGroups,
    }),
    computed: {
        isCreate() {
            return !this.value?._id;
        },
        title() {
            return this.isCreate ? "지사 생성" : "지사 상세";
        },
        items() {
            return [this.branch];
        },
    },
    watch: {
        shows() {
            if (this.shows) this.init();
            else this.reset();
        },
    },
    methods: {
        async init() {
            if (this.isCreate) this.branch = initBranch();
            else {
                const { _id } = this.value || {};
                const { branch } = await api.console.branches.get({ _id });
                this.branch = branch;
            }
        },
        reset() {
            this.branch = undefined;
        },
        save() {
            this.$emit("input", this.branch, () => (this.shows = false));
        },
    },
};
</script>

<template>
    <v-row :class="$attrs?.className || 'ma-0 px-2'" v-bind="$attrs">
        <v-col v-bind="{ cols, sm }">
            <v-select label="Continent" v-model="$data._continent" :items="continents" item-text="name" item-value="_id" v-bind="inputAttrs" @change="($data._country = null) && ($data._region = null)" placeholder="Select Continent" />
        </v-col>
        <v-col v-bind="{ cols, sm }">
            <v-select label="Country" v-model="$data._country" :items="countries" item-text="name" item-value="_id" v-bind="inputAttrs" @change="$data._region = null" placeholder="Select Country" />
        </v-col>
        <v-fade-transition>
            <v-col v-show="!!regions.length" v-bind="{ cols, sm }">
                <v-select label="Region" v-model="$data._region" :items="regions" item-text="name" item-value="_id" v-bind="inputAttrs" placeholder="Select Region" />
            </v-col>
        </v-fade-transition>
        <slot />
    </v-row>
</template>

<script>
import { inputAttrs, ZONE_TYPES } from "@/assets/variables";

export default {
    props: {
        value: { type: String, default: null }, // branch._zone
        zones: { type: Array, default: () => [] },

        sm: { type: String, default: undefined },
        cols: { type: String, default: "12" },
        inputAttrs: { type: Object, default: () => inputAttrs },
    },
    data: () => ({
        _continent: null,
        _country: null,
        _region: null,
    }),
    computed: {
        continents() {
            return (this.zones || []).filter(({ type }) => type == ZONE_TYPES.CONTINENT.value).sort((a, b) => a.name?.localeCompare?.(b.name));
        },
        countries() {
            if (!this.$data._continent) return [];
            return (this.zones || []).filter(({ type, _continent }) => type == ZONE_TYPES.COUNTRY.value && _continent == this.$data._continent).sort((a, b) => a.name?.localeCompare?.(b.name));
        },
        regions() {
            if (!this.$data._country) return [];
            return (this.zones || []).filter(({ type, _country }) => type == ZONE_TYPES.REGION.value && _country == this.$data._country).sort((a, b) => a.name?.localeCompare?.(b.name));
        },
        _zone() {
            const hasRegions = this.regions.length > 0;
            return hasRegions ? this.$data._region : this.$data._country;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        _zone(_zone) {
            this.$emit("input", _zone);
        },
    },
    methods: {
        init() {
            const zone = this.zones?.find?.(({ _id }) => _id == this.value);
            let { type, _id, _continent = null, _country = null, _region = null } = zone || {};

            if (type == ZONE_TYPES.COUNTRY.value) _country = _id;
            if (type == ZONE_TYPES.REGION.value) _region = _id;

            this.$data._continent = _continent;
            this.$data._country = _country;
            this.$data._region = _region;
        },
    },
};
</script>

<style></style>

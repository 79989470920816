<template></template>
<script>
export default {
    computed: {
        scope() {
            return this.$store.state.scope;
        },
    },
    mounted() {
        if (this.scope.includes("console")) this.$router.replace("/console/users");
        if (this.scope.includes("branch")) this.$router.replace("/console/forms?code=product-inquiry");
    },
};
</script>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vue-phone-number-input', _vm._g({
    attrs: {
      "default-country-code": _vm.phone_country_code,
      "border-radius": 0,
      "show-code-on-list": "",
      "size": "lg"
    },
    model: {
      value: _vm.phone_number,
      callback: function ($$v) {
        _vm.phone_number = $$v;
      },
      expression: "phone_number"
    }
  }, {
    update: _vm.update
  }));

}
var staticRenderFns = []

export { render, staticRenderFns }
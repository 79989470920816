<template>
    <v-dialog v-model="show" max-width="880" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card>
            <v-card-title>
                {{ title }}
                <v-btn text icon absolute right @click="show = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0" style="max-height: calc(90vh - 58px - 1px - 1px - 52px); overflow-y: auto">
                <v-row no-gutters>
                    <v-col cols="auto">
                        <div class="subtitle-2 font-weight-bold ma-4"><span>썸네일</span><span class="pl-1 font-weight-medium grey--text">(484*484px)</span></div>
                        <v-hover v-slot="{ hover }">
                            <v-img contain width="280" height="280" v-bind="{ src }" class="ma-4">
                                <v-fade-transition>
                                    <v-overlay v-show="hover && !!src" absolute z-index="0">
                                        <v-btn fab color="primary" @click="showFileDialog">
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>
                                        <v-btn fab color="red" @click="item.thumb = null" class="ml-2">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </v-overlay>
                                </v-fade-transition>
                                <v-fade-transition>
                                    <v-btn v-show="!src" tile height="100%" width="100%" @click="showFileDialog">
                                        <v-icon x-large>mdi-image-broken</v-icon>
                                    </v-btn>
                                </v-fade-transition>
                                <v-file-input v-model="item.thumb" v-show="false" ref="file-input" />
                            </v-img>
                        </v-hover>
                    </v-col>
                    <v-col cols="auto">
                        <v-divider vertical />
                    </v-col>
                    <v-col>
                        <v-text-field label="제목" v-model="item.subject" v-bind="inputAttrs" class="ma-4" />
                        <v-text-field label="요약" v-model="item.summary" v-bind="inputAttrs" class="ma-4" />
                        <v-row no-gutters class="my-n4">
                            <v-col> <v-select label="카테고리" v-model="item.category" :items="categories" v-bind="inputAttrs" class="ma-4 mr-2" /> </v-col>
                            <v-col> <v-datetime-field label="일시" v-model="item.datetime" v-bind="inputAttrs" className="ma-4 ml-2" /> </v-col>
                        </v-row>
                        <v-text-field label="국가" v-model="item.country" v-bind="inputAttrs" class="ma-4" />
                        <v-text-field label="장소" v-model="item.location" v-bind="inputAttrs" class="ma-4" />
                        <v-text-field label="웹사이트" v-model="item.website" v-bind="inputAttrs" class="ma-4" />
                    </v-col>
                </v-row>
                <v-divider />
                <naver-smarteditor v-model="item.content" class="ma-4" />
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-btn outlined color="grey" @click="init">초기화</v-btn>
                <v-spacer />
                <v-btn color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { STORY_NEWS_CATEGORIES, inputAttrs } from "@/assets/variables";
import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
const categories = Object.values(STORY_NEWS_CATEGORIES);
const initItem = () => ({
    thumb: null,
    name: null,
    desc: null,
    speaker: null,
    datetime: null,
    location: null,
    capacity: null,
    category: null,
    charge: {
        amount: null,
        currency: "USD",
    },
});
export default {
    components: {
        VDatetimeField,
        NaverSmarteditor,
    },
    props: {
        value: { type: Object, default: () => ({}) },
        lecturers: { type: Array, default: () => [] },
    },
    data: () => ({
        show: false,
        item: initItem(),
        categories,
        inputAttrs,
    }),
    computed: {
        isCreate() {
            return !this.value?._id;
        },
        title() {
            return this.isCreate ? "Dentim News 항목 생성" : "Dentim News 항목 상세";
        },
        src() {
            if (this.item?.thumb) return URL.createObjectURL(this.item?.thumb);
            else return null;
        },
    },
    watch: {
        show(show) {
            if (show) this.init();
        },
    },
    methods: {
        async init() {
            this.item = initItem();
            if (!this.isCreate) {
                const { seminar } = await api.console.academy.seminars.get({ _id: this.value?._id });
                if (seminar?.thumb?.url) seminar.thumb = await api.getResource(seminar.thumb.url);
                else seminar.thumb = null;

                this.item = seminar;
            }
        },
        async save() {
            const { post, put } = api.console.academy.seminars;

            const { _thumb, thumb, ...item } = this.item;

            if (_thumb) {
                await api.console.commonFiles.delete({ _id: _thumb });
                item._thumb = null;
            }
            let { seminar } = this.isCreate ? await post(item) : await put(item);
            if (thumb) {
                const { file } = await api.console.commonFiles.post({ directory: seminar?._id, index: "thumb" }, thumb);
                if (file) [{ seminar }] = [await put({ _id: seminar?._id, _thumb: file?._id })];
            }

            this.$emit("input", seminar);
            alert("저장되었습니다");
            this.show = false;
        },
        showFileDialog() {
            this.$refs["file-input"]?.$el.getElementsByTagName("input")?.[0]?.click?.();
        },
    },
};
</script>

<style></style>

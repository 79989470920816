<template>
    <v-layout justify-center>
        <v-responsive max-width="680" width="100%">
            <list-heading title="도큐먼트 관리" class="mt-8" />
            <v-data-table v-bind="{ headers, items }" hide-default-footer disable-filtering disable-pagination class="my-4">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.file`]="{ value }">
                    <v-btn small text icon download :href="value.url">
                        <v-icon>mdi-file</v-icon>
                    </v-btn>
                </template>
                <template #[`item.actions`]="{ item }">
                    <document-view v-bind="{ value: item }" @input="updateItem">
                        <template #activator="{ attrs, on }">
                            <v-btn small text icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                    </document-view>
                    <v-btn small text icon @click="remove(item)">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </template>
                <template #footer>
                    <v-divider />
                    <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="py-3" @input="search" />
                </template>
            </v-data-table>
            <document-view @input="updateItem">
                <template #activator="{ attrs, on }">
                    <v-btn x-large fab color="primary" fixed right bottom v-bind="attrs" v-on="on">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </template>
            </document-view>
        </v-responsive>
    </v-layout>
</template>

<script>
import ListHeading from "@/components/console/dumb/list-heading.vue";
import DocumentView from "@/components/console/shop/documents/document-view.vue";
import api from "@/api";

const headers = [
    { width: 40, value: "index", align: "right" },
    { text: "이름", value: "name", cellClass: "text-truncate" },
    { text: "상세", value: "desc", cellClass: "text-truncate" },
    { width: 60, text: "파일", value: "file", align: "center", sortable: false },
    { width: 90, value: "actions", align: "right", sortable: false },
];

export default {
    components: {
        ListHeading,
        DocumentView,
    },
    data: () => ({
        page: 1,
        limit: 10,
        filter: {},
        summary: { totalCount: 0 },

        headers,
        documents: [],
    }),
    computed: {
        items() {
            return this.documents.map((item, i) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - i }));
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
    },
    mounted() {
        this.init().then(this.search);
    },
    methods: {
        async init() {
            this.page = +this.$route.query.page || 1;
            this.documents = [];
            this.summary = { totalCount: 0 };
        },
        async search() {
            try {
                const { page, skip, limit } = this;
                this.$router.push({ query: Object.assignDefined({}, this.$route.query, this.filter, { page }) });

                var { summary, documents } = await api.console.shop.documents.gets({
                    headers: { skip, limit },
                    params: this.filter,
                });

                this.documents = documents;
                this.summary = summary;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.email);
                else alert(error.email.toString());
            }
        },
        async updateItem(item) {
            const index = this.documents.findIndex(({ _id }) => _id == item._id);
            if (index > -1) this.documents.splice(index, 1, item);
            else {
                this.documents = [item, ...this.documents];
                this.summary.totalCount += 1;
            }
        },
        async remove({ _file, _id, name }) {
            const go = confirm(`${name} - 도큐먼트를 삭제하시겠습니까?`);
            if (go) {
                if (_file) await api.console.commonFiles.delete({ _id: _file });
                await api.console.shop.documents.delete({ _id });
                this.documents = this.documents.filter((document) => document?._id != _id);
                this.summary.totalCount -= 1;
            }
        },
        async save(item) {
            const { document } = await api.console.shop.documents.put(item);
            this.updateItem(document);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep td.text-truncate {
    max-width: 0;
}
</style>

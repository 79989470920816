<template>
    <v-chip small label outlined close v-bind="{ href, download }" @click:close="$emit('remove')">
        {{ download }}
        <v-file-input v-model="file" v-show="false" ref="file-input" />
    </v-chip>
</template>

<script>
import api from "@/api";
export default {
    props: {
        index: { type: Number, default: 0 },
        value: { type: [Object, File], default: null }, // slide or file
        software: { type: Object, default: () => ({}) },
    },
    data: () => ({
        file: null,
    }),
    mounted() {
        this.init();
    },
    watch: {
        value() {
            this.init();
        },
    },
    computed: {
        href() {
            if (!!this.file) return URL.createObjectURL(this.file);
            else return this.value?.url;
        },
        download() {
            return this.file?.name || this.value?.name;
        },
    },
    methods: {
        init() {
            if (this.value instanceof File) this.file = this.value;
        },

        async save() {
            if (!this.file) return;
            const { file } = await api.console.membership.files.post({ directory: this.software?._id, index: `files/${this.index}` }, this.file);
            this.$emit("input", file);
        },

        showFileDialog() {
            this.$refs["file-input"]?.$el.getElementsByTagName("input")?.[0]?.click?.();
        },
    },
};
</script>

<style></style>

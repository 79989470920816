<template>
    <v-dialog v-model="shows" width="900" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card min-height="480" class="d-flex flex-column">
            <!-- loading -->
            <v-fade-transition>
                <v-overlay v-show="!branch" absolute light color="white">
                    <v-progress-circular indeterminate size="100" width="5" />
                </v-overlay>
            </v-fade-transition>

            <!-- content -->
            <v-card-title>
                지역배정 - {{ branch?.company?.name }} <v-spacer /> <v-btn text icon @click="shows = false"> <v-icon>mdi-close</v-icon> </v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text v-if="!!branch" class="pa-0" style="flex-grow: 1">
                <v-row no-gutters>
                    <v-col>
                        <v-data-table v-model="zonesToRemove" v-bind="{ headers }" :items="branch?.assignedZones" dense show-select>
                            <template #top>
                                <v-card-subtitle class="d-flex align-center grey lighten-4" style="position: relative">
                                    배정된 지역
                                    <v-fade-transition>
                                        <v-btn v-show="zonesToRemove.length > 0" absolute right small outlined color="red" @click="removeZones"> 선택한 항목 모두 제거 </v-btn>
                                    </v-fade-transition>
                                </v-card-subtitle>
                                <v-divider />
                            </template>
                            <template #[`item.actions`]="{ item }">
                                <v-btn small text icon color="red" @click="remove(item)">
                                    <v-icon>mdi-minus</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-col>
                    <v-col cols="auto"> <v-divider vertical /> </v-col>
                    <v-col>
                        <v-data-table v-model="zonesToAdd" v-bind="{ headers }" :items="unassignedZones" dense show-select>
                            <template #top>
                                <v-card-subtitle class="d-flex align-center grey lighten-4" style="position: relative">
                                    미배정 지역
                                    <v-fade-transition>
                                        <v-btn v-show="zonesToAdd.length > 0" absolute right small outlined color="primary" @click="addZones"> 선택한 항목 모두 추가 </v-btn>
                                    </v-fade-transition>
                                </v-card-subtitle>
                                <v-divider />
                            </template>
                            <template #[`item.actions`]="{ item }">
                                <v-btn small text icon color="primary" @click="add(item)">
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer />
                <v-btn color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { inputAttrs } from "@/assets/variables";

const headers = [
    { value: "fullName", text: "지역명" },
    { value: "actions", align: "end" },
];

const initBranch = () => ({
    _id: null,
    _zone: null,

    username: null,
    password: null,

    company: {
        name: null,
        fax: null,
        phone: null,
        email: null,
        website: null,
        address: null,
        postcode: null,
    },

    contactPerson: {
        name: null,
    },
});

export default {
    props: {
        value: { type: Object, default: () => ({}) },
        zones: { type: Array, default: () => [] },
    },
    data: () => ({
        shows: false,
        branch: undefined,
        zonesToAdd: [],
        zonesToRemove: [],
        headers,
        inputAttrs,
    }),
    computed: {
        isCreate() {
            return !this.value?._id;
        },
        unassignedZones() {
            return [...(this.zones || []), ...(this.value.assignedZones || [])].sort((a, b) => a.fullName?.localeCompare?.(b.fullName)).filter(({ _id }) => !this.branch.assignedZones.some((zone) => zone._id == _id));
        },
    },
    watch: {
        shows() {
            if (this.shows) this.init();
            else this.reset();
        },
    },
    methods: {
        async init() {
            if (this.isCreate) this.branch = initBranch();
            else {
                const { _id } = this.value || {};
                const { branch } = await api.console.branches.get({ _id });
                this.branch = branch;
            }
        },
        reset() {
            this.branch = undefined;
        },
        async save() {
            const { _id } = this.value || {};
            const _assignedZones = (this.branch?.assignedZones || []).map(({ _id }) => _id);
            const { branch } = await api.console.branches.put({ _id, _assignedZones });

            this.$emit("input", branch, () => (this.shows = false));
        },
        add(item) {
            this.branch.assignedZones.push(item);
        },
        addZones() {
            this.branch.assignedZones.push(...this.zonesToAdd);
            this.branch.assignedZones = this.branch.assignedZones.sort((a, b) => a.fullName?.localeCompare?.(b.fullName));
            this.zonesToAdd = [];
        },
        remove(item) {
            const index = this.branch.assignedZones.findIndex(({ _id }) => _id == item._id);
            this.branch.assignedZones.splice(index, 1);
        },
        removeZones() {
            this.branch.assignedZones = this.branch.assignedZones.filter(({ _id }) => !this.zonesToRemove.some((zone) => zone._id == _id));
            this.zonesToRemove = [];
        },
    },
};
</script>

<template>
    <v-layout justify-center>
        <v-responsive max-width="1080" width="100%">
            <list-heading title="온디맨드 관리" class="mt-8" />
            <v-data-table v-bind="{ headers, items }" hide-default-footer disable-sort disable-filtering disable-pagination class="my-4">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.thumb`]="{ value }">
                    <image-popup size="40" :src="value?.url" />
                </template>
                <template #[`item.isOn`]="{ item, value }">
                    <v-switch v-model="item.isOn" inset dense hide-details class="mt-0 ml-1 mr-n4 pa-0" @change="(isOn) => save({ _id: item._id, isOn })" />
                    <span class="caption">{{ value ? "진행중" : "종료" }}</span>
                </template>
                <template #[`item.actions`]="{ item }">
                    <lecture-view :value="item" v-bind="{ lecturers }" @input="update">
                        <template #activator="{attrs,on}">
                            <v-btn small text icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                    </lecture-view>
                    <v-btn small text icon @click="remove(item)">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </template>
                <template #footer>
                    <v-divider />
                    <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="py-3" @input="search" />
                </template>
            </v-data-table>
            <lecture-view v-bind="{ lecturers }" @input="update">
                <template #activator="{attrs,on}">
                    <v-btn x-large fab color="primary" fixed right bottom v-bind="attrs" v-on="on">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </template>
            </lecture-view>
        </v-responsive>
    </v-layout>
</template>

<script>
import ListHeading from "@/components/console/dumb/list-heading.vue";
import LectureView from "@/components/console/academy/lecture-view.vue";
import api from "@/api";
import ImagePopup from "@/components/console/dumb/image-popup.vue";
import { ACADEMY_LECTURE_CATEGORIES } from "@/assets/variables";

const headers = [
    { width: 40, value: "index", align: "right", divider: true },
    { width: 180, value: "category", text: "카테고리", class: "text-truncate", formatter: (value) => ACADEMY_LECTURE_CATEGORIES[value]?.text || null, divider: true },
    { value: "name", text: "제목", class: "text-truncate" },
    { width: 80, value: "thumb", text: "썸네일", divider: true },
    { width: 130, value: "lecturer", text: "강연자", class: "text-truncate", formatter: ({ name, title } = {}) => `${name}\n(${title})`, cellClass: "white-space-pre-line caption", divider: true },
    { width: 70, value: "_units.length", text: "강의수", formatter: (value) => `${value?.format?.()} 개`, align: "right" },
    { width: 100, value: "duration", text: "총강의시간", formatter: (value = 0) => new Date(null, null, null, null, null, null, value).toTimeString().split(" ")[0], align: "center", divider: true },
    { width: 110, text: "생성일", value: "createdAt", formatter: (value) => value?.toDateTime(), divider: true },
    { width: 90, value: "actions", align: "right" },
];

export default {
    components: {
        ListHeading,
        LectureView,
        ImagePopup,
    },
    data: () => ({
        page: 1,
        limit: 10,
        filter: {},
        summary: { totalCount: 0 },

        headers,
        lectures: [],
        lecturers: [],
    }),
    computed: {
        items() {
            return this.lectures.map((item, i) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - i }));
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
    },
    mounted() {
        this.init().then(this.search);
    },
    methods: {
        async init() {
            this.page = +this.$route.query.page || 1;
            this.lectures = [];
            this.summary = { totalCount: 0 };

            const { lecturers } = await api.console.academy.lecturers.gets();
            this.lecturers = lecturers;
        },
        async search() {
            try {
                const { page, skip, limit } = this;
                this.$router.push({ query: Object.assignDefined({}, this.$route.query, this.filter, { page }) });

                var { summary, lectures } = await api.console.academy.lectures.gets({
                    headers: { skip, limit },
                    params: this.filter,
                });

                this.lectures = lectures;
                this.summary = summary;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.email);
                else alert(error.email.toString());
            }
        },
        async update(item) {
            const index = this.lectures.findIndex(({ _id }) => _id == item._id);
            if (index > -1) this.lectures.splice(index, 1, item);
            else {
                this.lectures = [item, ...this.lectures];
                this.summary.totalCount += 1;
            }
        },
        async remove({ _thumb, _id, name }) {
            const go = confirm(`${name} - 데이터를 삭제하시겠습니까?`);
            if (go) {
                if (_thumb) await api.console.commonFiles.delete({ _id: _thumb });
                await api.console.academy.lectures.delete({ _id });
                this.lectures = this.lectures.filter((lecture) => lecture?._id != _id);
                this.summary.totalCount -= 1;
            }
        },
        async save(item) {
            const { lecture } = await api.console.academy.lectures.put(item);
            this.update(lecture);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    td.white-space-pre-line {
        white-space: pre-line;
    }
    td.text-truncate {
        max-width: 0;
    }
}
</style>

<template>
    <vue-phone-number-input v-model="phone_number" :default-country-code="phone_country_code" :border-radius="0" show-code-on-list size="lg" v-on="{ update }" />
</template>

<script>
export default {
    props: {
        value: { type: Object, default: () => ({}) }, // person
    },
    data: () => ({
        phone_country_code: null,
        phone_number: null,
        phone: null,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.phone_country_code = this.value?.phone_country_code || null;
            this.phone_number = this.value?.phone_number || null;
            this.phone = this.value?.phone || null;
        },
        emit() {
            let { phone_country_code, phone_number, phone } = this;

            this.$emit("input", {
                ...this.value,
                phone_country_code,
                phone_number,
                phone,
            });
        },
        update({ countryCode, phoneNumber, formatInternational }) {
            this.phone_country_code = countryCode || null;
            this.phone_number = phoneNumber || null;
            this.phone = formatInternational?.replaceAll?.(" ", "-") || null;
            this.emit();
        },
    },
};
</script>


<style lang="scss" scoped>
::v-deep {
    .country-selector,
    .country-selector__input,
    .input-tel,
    .input-tel__input {
        height: 52px !important;
        min-height: 52px !important;
    }
}
</style>
<template>
    <v-data-table v-bind="{ headers, items }" dense>
        <template #top>
            <v-card-subtitle class="grey lighten-4 caption">
                지역별 지사 배정 내역
            </v-card-subtitle>
            <v-divider />
            <v-row class="ma-0 pa-1">
                <v-col cols="12" md="6" xl="3" class="pa-2">
                    <v-select v-model="filter.assignment" label="배정여부" :items="assignments" v-bind="inputAttrs" />
                </v-col>
                <v-col cols="12" md="6" xl="3" class="pa-2">
                    <v-select v-model="filter.type" label="유형" :items="zoneTypes" v-bind="inputAttrs" />
                </v-col>
                <v-col cols="12" md="6" xl="3" class="pa-2">
                    <v-select v-model="filter._continent" label="대륙" :items="continents" item-text="name" item-value="_id" v-bind="inputAttrs" @change="filter._country = null" />
                </v-col>
                <v-col cols="12" md="6" xl="3" class="pa-2">
                    <v-select v-model="filter._country" label="국가" :items="countries" item-text="name" item-value="_id" v-bind="inputAttrs" :disabled="countries.length == 0" />
                </v-col>
            </v-row>
            <v-divider />
        </template>
        <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
    </v-data-table>
</template>

<script>
import ListSearch from "../dumb/list-search.vue";
import { inputAttrs, ZONE_TYPES } from "@/assets/variables";
const zoneTypes = [{ text: "전체", value: null }].concat(Object.values(ZONE_TYPES));
const assignments = [{ text: "전체", value: null }, "배정", "미배정"];
const headers = [
    { width: "50%", text: "지역", value: "zone.fullName", cellClass: "text-truncate" },
    { width: "50%", text: "지사", value: "branches", formatter: (values) => values.map(({ company }) => company?.name)?.join(", "), cellClass: "text-truncate", sortable: false },
];
export default {
    components: {
        ListSearch,
    },
    props: {
        value: { type: Array, default: () => [] },
        zones: { type: Array, default: () => [] },
        mappedZones: { type: Array, default: () => [] },
    },
    data: () => ({
        filter: {
            assignment: null,
            type: null,
            _continent: null,
            _country: null,
        },
        headers,
        zoneTypes,
        inputAttrs,
        assignments,
    }),
    computed: {
        items() {
            const { assignment, type, _country, _continent } = this.filter || {};

            return this.mappedZones.filter(({ zone, branches }) => {
                const isAssigned = (() => {
                    switch (assignment) {
                        case "배정":
                            return branches.length >= 1;
                        case "미배정":
                            return branches.length <= 0;
                        default:
                            return true;
                    }
                })();
                const isSameType = type ? zone.type == type : true;
                const isSameContinent = _continent ? zone._continent == _continent || zone._id == _continent : true;
                const isSameCountry = _country ? zone._country == _country || zone._id == _country : true;
                return isAssigned && isSameType && isSameContinent && isSameCountry;
            });
        },
        continents() {
            return [{ _id: null, name: "전체" }].concat((this.zones || []).filter(({ type }) => type == ZONE_TYPES.CONTINENT.value).sort((a, b) => a.name?.localeCompare?.(b.name)));
        },
        countries() {
            if (!this.filter?._continent) return [];
            return [{ _id: null, name: "전체" }].concat((this.zones || []).filter(({ type, _continent }) => type == ZONE_TYPES.COUNTRY.value && _continent == this.filter?._continent).sort((a, b) => a.name?.localeCompare?.(b.name)));
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep td.text-truncate {
    max-width: 0;
}
</style>

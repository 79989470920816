<template>
    <v-layout justify-center>
        <v-responsive max-width="680" width="100%">
            <list-heading v-bind="{ title }" class="mt-8" />
            <v-data-table v-bind="{ headers, items }" hide-default-footer disable-filtering disable-pagination class="my-4">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.file`]="{ value }">
                    <v-btn small text icon download :href="value?.url">
                        <v-icon>mdi-file</v-icon>
                    </v-btn>
                </template>
                <template #[`item.actions`]="{ item }">
                    <catalogue-view v-bind="{ code, value: item, categories }" @input="update">
                        <template #activator="{ attrs, on }">
                            <v-btn small text icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                    </catalogue-view>
                    <v-btn small text icon @click="remove(item)">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </template>
                <template #footer>
                    <v-divider />
                    <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="py-3" @input="search" />
                </template>
            </v-data-table>
            <catalogue-view v-bind="{ code, categories }" @input="update">
                <template #activator="{ attrs, on }">
                    <v-btn x-large fab color="primary" fixed right bottom v-bind="attrs" v-on="on">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </template>
            </catalogue-view>
        </v-responsive>
    </v-layout>
</template>

<script>
import ListHeading from "@/components/console/dumb/list-heading.vue";
import CatalogueView from "@/components/console/shop/catalogue-view.vue";
import api from "@/api";

export default {
    components: {
        ListHeading,
        CatalogueView,
    },
    props: {
        code: { type: String, default: null },
    },
    data: () => ({
        page: 1,
        limit: 10,
        filter: {},
        summary: { totalCount: 0 },

        catalogues: [],
        categories: [],
    }),
    computed: {
        title() {
            switch (this.code) {
                case "abstracts": {
                    return "Abstract 관리";
                }

                default: {
                    return "Catalog 관리";
                }
            }
        },
        items() {
            return this.catalogues.map((item, i) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - i }));
        },
        headers() {
            return [
                { width: 40, value: "index", align: "right" },
                { text: "카테고리", value: "category.name", test: ({ code }) => code == null },
                { text: "이름", value: "name", cellClass: "text-truncate" },
                { width: 60, text: "파일", value: "file", align: "center", sortable: false },
                { width: 90, value: "actions", align: "right", sortable: false },
            ]
                .map((header) => ({ ...header, test: header.test || (() => true) }))
                .filter(({ test }) => test?.({ code: this.code }));
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
    },
    mounted() {
        this.init().then(this.search);
    },
    watch: {
        code() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.page = +this.$route.query.page || 1;
            this.catalogues = [];
            this.summary = { totalCount: 0 };
            [{ categories: this.categories }] = [await api.console.shop.categories.gets({ params: { depth: 1 } })];
        },
        async search() {
            try {
                const { page, skip, limit, code } = this;
                this.$router.push({ query: Object.assignDefined({}, this.$route.query, this.filter, { page }) });

                var { summary, catalogues } = await api.console.shop.catalogues.gets({
                    headers: { skip, limit },
                    params: { ...this.filter, code },
                });

                this.catalogues = catalogues;
                this.summary = summary;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.email);
                else alert(error.email.toString());
            }
        },
        async update(item) {
            const index = this.catalogues.findIndex(({ _id }) => _id == item._id);
            if (index > -1) this.catalogues.splice(index, 1, item);
            else {
                this.catalogues = [item, ...this.catalogues];
                this.summary.totalCount += 1;
            }
        },
        async remove({ _file, _id, name }) {
            const go = confirm(`${name} - 카탈로그를 삭제하시겠습니까?`);
            if (go) {
                if (_file) await api.console.commonFiles.delete({ _id: _file });
                await api.console.shop.catalogues.delete({ _id });
                this.catalogues = this.catalogues.filter((catalogue) => catalogue?._id != _id);
                this.summary.totalCount -= 1;
            }
        },
        async save(item) {
            const { catalogue } = await api.console.shop.catalogues.put(item);
            this.update(catalogue);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep td.text-truncate {
    max-width: 0;
}
</style>

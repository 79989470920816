var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "720",
      "width": "100%"
    }
  }, [_c('list-heading', {
    staticClass: "mt-8",
    attrs: {
      "title": "강연자 관리"
    }
  }), _c('v-data-table', _vm._b({
    staticClass: "my-4",
    attrs: {
      "hide-default-footer": "",
      "disable-sort": "",
      "disable-filtering": "",
      "disable-pagination": "",
      "show-expand": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.src`,
      fn: function (_ref2) {
        var value = _ref2.value;
        return [_c('image-popup', {
          attrs: {
            "size": "40",
            "src": value
          }
        })];
      }
    }, {
      key: `item.isOn`,
      fn: function (_ref3) {
        var item = _ref3.item,
          value = _ref3.value;
        return [_c('v-switch', {
          staticClass: "mt-0 ml-1 mr-n4 pa-0",
          attrs: {
            "inset": "",
            "dense": "",
            "hide-details": ""
          },
          on: {
            "change": function (isOn) {
              return _vm.save({
                _id: item._id,
                isOn
              });
            }
          },
          model: {
            value: item.isOn,
            callback: function ($$v) {
              _vm.$set(item, "isOn", $$v);
            },
            expression: "item.isOn"
          }
        }), _c('span', {
          staticClass: "caption"
        }, [_vm._v(_vm._s(value ? "진행중" : "종료"))])];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('lecturer-view', {
          attrs: {
            "value": item
          },
          on: {
            "input": _vm.update
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref5) {
              var attrs = _ref5.attrs,
                on = _ref5.on;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "small": "",
                  "text": "",
                  "icon": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-pencil")])], 1)];
            }
          }], null, true)
        }), _c('v-btn', {
          attrs: {
            "small": "",
            "text": "",
            "icon": ""
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)];
      }
    }, {
      key: "expanded-item",
      fn: function (_ref6) {
        var item = _ref6.item,
          headers = _ref6.headers;
        return [_c('td', {
          staticStyle: {
            "white-space": "pre-line"
          },
          attrs: {
            "colspan": headers.length
          }
        }, [_vm._v(_vm._s(item.desc))])];
      }
    }, {
      key: "footer",
      fn: function () {
        return [_c('v-divider'), _c('v-pagination', {
          staticClass: "py-3",
          attrs: {
            "length": _vm.pageCount,
            "total-visible": 11
          },
          on: {
            "input": _vm.search
          },
          model: {
            value: _vm.page,
            callback: function ($$v) {
              _vm.page = $$v;
            },
            expression: "page"
          }
        })];
      },
      proxy: true
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items
  }, false)), _c('lecturer-view', {
    on: {
      "input": _vm.update
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref7) {
        var attrs = _ref7.attrs,
          on = _ref7.on;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "x-large": "",
            "fab": "",
            "color": "primary",
            "fixed": "",
            "right": "",
            "bottom": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-pencil")])], 1)];
      }
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
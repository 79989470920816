<template>
    <v-dialog v-model="shows" width="720">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card>
            <v-card-title class="align-center">
                {{ title }} - 담당지사 변경 <v-btn absolute right text icon @click="shows = false"> <v-icon>mdi-close</v-icon> </v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text class="py-0">
                <v-row align="center">
                    <v-col cols="2"> 담당지사 </v-col>
                    <v-col cols="auto" align-self="stretch" class="pa-0"> <v-divider vertical /> </v-col>
                    <v-col class="py-0">
                        <v-chip-group v-model="selected" column>
                            <v-chip v-for="branch in selected" :key="branch?._id" :value="branch" close @click:close="remove(branch)"> {{ branch?.company?.name }} </v-chip>
                        </v-chip-group>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-data-table v-model="selected" v-bind="{ headers, items }" show-select item-key="_id" />
            <v-divider />
            <v-card-actions>
                <v-btn @click="reset">초기화</v-btn>
                <v-spacer />
                <v-btn @click="save">변경</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { BRANCH_GROUPS } from "@/assets/variables";
import api from "@/api";
const headers = [
    { value: "company.group", text: "그룹", formatter: (key) => BRANCH_GROUPS[key]?.text || "-" },
    { value: "company.name", text: "지사" },
];
export default {
    props: {
        value: { type: Object, default: () => ({}) }, // forms
        title: { type: String, default: "프로덕트 문의" },
    },
    data: () => ({
        shows: false,
        headers,

        form: {},
        branches: [],
        selected: [],
    }),
    computed: {
        items() {
            return this.branches;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        shows(shows) {
            if (shows) this.init().then(this.reset);
        },
    },
    methods: {
        async init() {
            if (!this.shows) return;
            this.form = (await api.console.forms.get({ _id: this.value?._id }))?.form || {};
            this.branches = (await api.console.branches.gets())?.branches || [];
            this.reset();
        },
        reset() {
            this.selected = JSON.parse(JSON.stringify(this.form?.meta?.assignedBranches));
        },
        remove(branch) {
            const index = this.selected.findIndex(({ _id }) => _id == branch._id);
            this.selected.splice(index, 1);
        },
        async save() {
            const { _id, meta } = this.form;
            const { form } = await api.console.forms.put({ _id, meta: { ...meta, _assignedBranches: this.selected.map(({ _id }) => _id) } });

            alert("변경되었습니다");
            this.$emit("input", form);
            this.shows = false;
        },
    },
};
</script>

<style></style>

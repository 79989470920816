<template>
    <v-card tile flat>
        <zone-order-filter v-model="group" v-bind="{ loading }" />
        <v-divider />
        <v-expand-transition>
            <v-data-table v-bind="{ items, headers, loading }" disable-filtering disable-sort disable-pagination :items-per-page="-1" hide-default-footer>
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.index`]="{ item, value }">
                    <zone-order-index-edit :value="item" v-on="{ update }">
                        {{ value || "-" }}
                    </zone-order-index-edit>
                </template>
            </v-data-table>
        </v-expand-transition>
        <v-expand-transition>
            <v-sheet v-show="loading" height="50vh">
                <v-overlay light color="white" absolute>
                    <v-progress-circular color="black" size="100" width="5" indeterminate />
                </v-overlay>
            </v-sheet>
        </v-expand-transition>
    </v-card>
</template>

<script>
import api from "@/api";
import { BRANCH_GROUPS, inputAttrs } from "@/assets/variables";

import ListSearch from "@/components/console/dumb/list-search.vue";

import ZoneView from "./zone-view.vue";
import ZoneOrderFilter from "./zone-order-filter.vue";
import ZoneOrderIndexEdit from "./zone-order-index-edit.vue";

const headers = [
    { width: 100, text: "노출순서", value: "index", sortable: false },
    { text: "대륙명", value: "group" },
    { text: "국가명", value: "countryName", cellClass: "text-truncate" },
].map((item, index, array) => ({ ...item, divider: index != array.length - 1, formatter: item.formatter || ((value) => value || "-") }));

export default {
    components: {
        ListSearch,

        ZoneView,
        ZoneOrderFilter,
        ZoneOrderIndexEdit,
    },
    props: {
        showsSearch: { type: Boolean, default: false },
    },
    data: () => ({
        setting: undefined,

        group: Object.keys(BRANCH_GROUPS)[0],
        loading: false,

        headers,
        inputAttrs,
    }),
    computed: {
        tab() {
            return this.$route.path.match(/\/console\/([a-zA-Z\-]*)/)?.[1] || "zones";
        },
        items() {
            return this.setting?.branchGroup?.[this.group]?.countries.map((item) => ({ ...item, group: this.group })) || Object.keys(this.setting?.branchGroup || {}).flatMap((key) => (this.setting?.branchGroup?.[key]?.countries || []).map((item) => ({ ...item, group: key }))) || [];
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        "$route.path"() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.search();
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { setting } = await api.console.setting.get({
                    headers: { pre: "update-branch-group" },
                });
                this.setting = setting;
            } finally {
                this.loading = false;
            }
        },
        async update(form) {
            if (this.loading) return;
            else this.loading = true;
            try {
                await api.console.shop.zones.put(form);
                this.loading = false;
                this.search();
            } finally {
                if (this.loading) this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep td.text-truncate {
    max-width: 0px;
}
</style>

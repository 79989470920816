var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card-text', [_c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var hover = _ref.hover;
        return [_c('v-responsive', {
          attrs: {
            "aspect-ratio": 16 / 9
          }
        }, [_c('v-card', {
          attrs: {
            "tile": "",
            "flat": "",
            "height": "100%"
          }
        }, [_vm.src ? _c('v-overlay', {
          staticStyle: {
            "pointer-events": "none"
          },
          attrs: {
            "absolute": "",
            "z-index": "0"
          }
        }, [_c('video', _vm._b({
          attrs: {
            "height": "200"
          }
        }, 'video', {
          src: _vm.src
        }, false))]) : _vm._e(), _c('v-fade-transition', [_c('v-overlay', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: hover && !!_vm.src,
            expression: "hover && !!src"
          }],
          attrs: {
            "absolute": "",
            "z-index": "0",
            "color": "black"
          }
        }, [_c('v-btn', {
          attrs: {
            "x-large": "",
            "text": "",
            "icon": "",
            "color": "primary"
          },
          on: {
            "click": _vm.showFileDialog
          }
        }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1), _c('v-btn', {
          attrs: {
            "x-large": "",
            "text": "",
            "icon": "",
            "color": "red"
          },
          on: {
            "click": function ($event) {
              return _vm.remove({
                withFile: true
              });
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)], 1)], 1), _c('v-fade-transition', [_c('v-btn', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.src,
            expression: "!src"
          }],
          attrs: {
            "tile": "",
            "height": "100%",
            "width": "100%"
          },
          on: {
            "click": _vm.showFileDialog
          }
        }, [_c('v-icon', {
          attrs: {
            "x-large": ""
          }
        }, [_vm._v("mdi-video-off")])], 1)], 1)], 1)], 1)];
      }
    }])
  }), _c('v-file-input', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "file-input",
    attrs: {
      "accept": "video/mp4,video/x-m4v,video/*"
    },
    model: {
      value: _vm.file,
      callback: function ($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1280",
      "width": "100%"
    }
  }, [_c('list-heading', {
    attrs: {
      "title": "General Inquiries",
      "showsFilterButton": ""
    },
    model: {
      value: _vm.showsSearch,
      callback: function ($$v) {
        _vm.showsSearch = $$v;
      },
      expression: "showsSearch"
    }
  }), _c('form-list-search', _vm._b({}, 'form-list-search', {
    showsSearch: _vm.showsSearch
  }, false)), _c('v-data-table', _vm._b({
    staticClass: "v-sheet--outlined",
    attrs: {
      "items-per-page": -1,
      "disable-pagination": "",
      "disable-sort": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.memo`,
      fn: function (_ref2) {
        var item = _ref2.item,
          value = _ref2.value;
        return [_c('form-list-item-memo', _vm._g({
          attrs: {
            "value": item
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref3) {
              var attrs = _ref3.attrs,
                on = _ref3.on;
              return [_c('v-card', _vm._g(_vm._b({
                staticClass: "fill-height py-4 white-space-pre-line",
                attrs: {
                  "flat": "",
                  "tile": "",
                  "color": "transparent"
                }
              }, 'v-card', attrs, false), on), [_vm._v(" " + _vm._s(value || "-") + " ")])];
            }
          }], null, true)
        }, {
          update: _vm.update
        }))];
      }
    }, {
      key: `item.state`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('form-list-item-state', _vm._g(_vm._b({
          attrs: {
            "value": item
          }
        }, 'form-list-item-state', {
          loading: _vm.loading
        }, false), {
          update: _vm.update
        }))];
      }
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers,
    loading: _vm.loading
  }, false)), _c('v-pagination', {
    staticClass: "my-2",
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11,
      "color": "primary"
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-dialog v-model="show" max-width="400">
        <template #activator="{attrs, on}">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card>
            <v-card-title>
                {{ title }}
                <v-btn text icon absolute right @click="show = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0" style="max-height: calc(90vh - 112px); overflow-y: auto;">
                <div class="subtitle-2 font-weight-bold ma-3"><span>이미지</span><span class="pl-1 font-weight-medium grey--text">(255*296px)</span></div>
                <v-hover v-slot="{ hover }">
                    <v-img :aspect-ratio="1 / 1" v-bind="{ src }" class="ma-3">
                        <v-fade-transition>
                            <v-overlay v-show="hover && !!src" absolute z-index="0">
                                <v-btn fab color="primary" @click="showFileDialog">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn fab color="red" @click="item.thumb = null" class="ml-2">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </v-overlay>
                        </v-fade-transition>
                        <v-fade-transition>
                            <v-btn v-show="!src" tile height="100%" width="100%" @click="showFileDialog">
                                <v-icon x-large>mdi-image-broken</v-icon>
                            </v-btn>
                        </v-fade-transition>
                    </v-img>
                </v-hover>
                <v-divider />
                <v-text-field label="직함" v-model="item.title" v-bind="inputAttrs" class="ma-3" />
                <v-text-field label="이름" v-model="item.name" v-bind="inputAttrs" class="ma-3" />
                <v-textarea label="이력" v-model="item.desc" v-bind="inputAttrs" class="ma-3" />
                <v-file-input v-model="item.thumb" v-show="false" ref="file-input" />
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-btn outlined color="grey" @click="init">초기화</v-btn>
                <v-spacer />
                <v-btn color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { inputAttrs } from "@/assets/variables";
const initItem = () => ({
    thumb: null,
    title: null,
    name: null,
    desc: null,
});
export default {
    props: {
        value: { type: Object, default: () => ({}) },
    },
    data: () => ({
        show: false,
        item: initItem(),
        inputAttrs,
    }),
    computed: {
        isCreate() {
            return !this.value?._id;
        },
        title() {
            return this.isCreate ? "강연자 항목 생성" : "강연자 항목 상세";
        },
        src() {
            if (this.item?.thumb) return URL.createObjectURL(this.item?.thumb);
            else return null;
        },
    },
    watch: {
        show(show) {
            if (show) this.init();
        },
    },
    methods: {
        async init() {
            this.item = initItem();
            if (!this.isCreate) {
                const { lecturer } = await api.console.academy.lecturers.get({ _id: this.value?._id });
                if (lecturer?.thumb?.url) lecturer.thumb = await api.getResource(lecturer.thumb.url);
                else lecturer.thumb = null;

                this.item = lecturer;
            }
        },
        async save() {
            const { post, put } = api.console.academy.lecturers;

            const { _thumb, thumb, ...item } = this.item;

            if (_thumb) {
                await api.console.commonFiles.delete({ _id: _thumb });
                item._thumb = null;
            }
            let { lecturer } = this.isCreate ? await post(item) : await put(item);
            if (thumb) {
                const { file } = await api.console.commonFiles.post({ directory: lecturer?._id, index: "thumb" }, thumb);
                if (file) [{ lecturer }] = [await put({ _id: lecturer?._id, _thumb: file?._id })];
            }

            this.$emit("input", lecturer);
            alert("저장되었습니다");
            this.show = false;
        },
        showFileDialog() {
            this.$refs["file-input"]?.$el.getElementsByTagName("input")?.[0]?.click?.();
        },
    },
};
</script>

<style></style>

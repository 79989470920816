var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "tile": "",
      "flat": ""
    }
  }, [_c('zone-order-filter', _vm._b({
    model: {
      value: _vm.group,
      callback: function ($$v) {
        _vm.group = $$v;
      },
      expression: "group"
    }
  }, 'zone-order-filter', {
    loading: _vm.loading
  }, false)), _c('v-divider'), _c('v-expand-transition', [_c('v-data-table', _vm._b({
    attrs: {
      "disable-filtering": "",
      "disable-sort": "",
      "disable-pagination": "",
      "items-per-page": -1,
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.index`,
      fn: function (_ref2) {
        var item = _ref2.item,
          value = _ref2.value;
        return [_c('zone-order-index-edit', _vm._g({
          attrs: {
            "value": item
          }
        }, {
          update: _vm.update
        }), [_vm._v(" " + _vm._s(value || "-") + " ")])];
      }
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers,
    loading: _vm.loading
  }, false))], 1), _c('v-expand-transition', [_c('v-sheet', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "height": "50vh"
    }
  }, [_c('v-overlay', {
    attrs: {
      "light": "",
      "color": "white",
      "absolute": ""
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "color": "black",
      "size": "100",
      "width": "5",
      "indeterminate": ""
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
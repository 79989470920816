var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "d-flex flex-column fill-height",
    staticStyle: {
      "position": "relative"
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold",
    staticStyle: {
      "position": "relative"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.label))]), _c('span', {
    staticClass: "pl-1 font-weight-medium grey--text"
  }, [_vm._v(_vm._s(_vm.sublabel))]), _c('v-file-btn', {
    attrs: {
      "absolute": "",
      "right": "",
      "text": "",
      "icon": "",
      "color": "primary",
      "multiple": "",
      "accept": "image/*"
    },
    on: {
      "change": _vm.upload
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "pt-0",
    staticStyle: {
      "flex-grow": "1",
      "overflow-x": "auto"
    }
  }, [_vm.banner.slides < 1 ? _c('div', {
    staticClass: "mt-4 text-center"
  }, [_vm._v("슬라이드가 없습니다.")]) : _vm._e(), _vm.mode == 'horizontal' ? _c('draggable', {
    staticClass: "d-flex flex-row ml-n3 mt-4",
    model: {
      value: _vm.banner.slides,
      callback: function ($$v) {
        _vm.$set(_vm.banner, "slides", $$v);
      },
      expression: "banner.slides"
    }
  }, _vm._l(_vm.banner.slides, function (slide, index) {
    return _c('v-hover', {
      key: `slide-${index}`,
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref) {
          var hover = _ref.hover;
          return [_c('v-card', {
            staticClass: "ml-3",
            staticStyle: {
              "position": "relative"
            }
          }, [_c('v-preview-input', {
            attrs: {
              "contain": "",
              "width": "288",
              "height": "512"
            },
            model: {
              value: slide.image,
              callback: function ($$v) {
                _vm.$set(slide, "image", $$v);
              },
              expression: "slide.image"
            }
          }), _c('v-fade-transition', [_c('v-overlay', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: hover,
              expression: "hover"
            }],
            attrs: {
              "absolute": "",
              "z-index": "0"
            }
          }, [_c('v-row', {
            attrs: {
              "justify": "center"
            }
          }, [_c('v-col', {
            attrs: {
              "cols": "auto"
            }
          }, [_c('v-menu', {
            attrs: {
              "offset-y": "",
              "nudge-left": "222",
              "close-on-content-click": false
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function (_ref2) {
                var attrs = _ref2.attrs,
                  on = _ref2.on;
                return _c('v-icon', _vm._g(_vm._b({
                  attrs: {
                    "large": ""
                  }
                }, 'v-icon', attrs, false), on), [_vm._v(" mdi-form-textbox ")]);
              }
            }], null, true),
            model: {
              value: slide.showsModal_title,
              callback: function ($$v) {
                _vm.$set(slide, "showsModal_title", $$v);
              },
              expression: "slide.showsModal_title"
            }
          }, [_c('v-card', {
            staticClass: "pa-3",
            attrs: {
              "width": "480"
            }
          }, [_c('v-text-field', {
            attrs: {
              "label": "Title",
              "autofocus": "",
              "persistent-placeholder": "",
              "hide-details": "",
              "dense": "",
              "outlined": ""
            },
            on: {
              "keydown": function ($event) {
                if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
                slide.showsModal_title = false;
              }
            },
            model: {
              value: slide.title,
              callback: function ($$v) {
                _vm.$set(slide, "title", $$v);
              },
              expression: "slide.title"
            }
          })], 1)], 1)], 1), _c('v-col', {
            attrs: {
              "cols": "auto"
            }
          }, [_c('v-menu', {
            attrs: {
              "offset-y": "",
              "nudge-left": "222",
              "close-on-content-click": false
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function (_ref3) {
                var attrs = _ref3.attrs,
                  on = _ref3.on;
                return _c('v-icon', _vm._g(_vm._b({
                  attrs: {
                    "large": ""
                  }
                }, 'v-icon', attrs, false), on), [_vm._v(" mdi-link-variant ")]);
              }
            }], null, true),
            model: {
              value: slide.showsModal_url,
              callback: function ($$v) {
                _vm.$set(slide, "showsModal_url", $$v);
              },
              expression: "slide.showsModal_url"
            }
          }, [_c('v-card', {
            staticClass: "pa-3",
            attrs: {
              "width": "480"
            }
          }, [_c('v-text-field', {
            attrs: {
              "label": "URL",
              "placeholder": "/products/621f2299052d6a062e7400bc",
              "autofocus": "",
              "persistent-placeholder": "",
              "hide-details": "",
              "dense": "",
              "outlined": ""
            },
            on: {
              "keydown": function ($event) {
                if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
                slide.showsModal_url = false;
              }
            },
            model: {
              value: slide.url,
              callback: function ($$v) {
                _vm.$set(slide, "url", $$v);
              },
              expression: "slide.url"
            }
          })], 1)], 1)], 1), _c('v-col', {
            attrs: {
              "cols": "auto"
            }
          }, [_c('v-icon', {
            attrs: {
              "large": ""
            },
            on: {
              "click": function ($event) {
                return _vm.banner.slides.splice(index, 1);
              }
            }
          }, [_vm._v(" mdi-delete ")])], 1)], 1)], 1)], 1)], 1)];
        }
      }], null, true)
    });
  }), 1) : _vm._e(), _vm.mode == 'vertical' ? _c('draggable', {
    model: {
      value: _vm.banner.slides,
      callback: function ($$v) {
        _vm.$set(_vm.banner, "slides", $$v);
      },
      expression: "banner.slides"
    }
  }, _vm._l(_vm.banner.slides, function (slide, index) {
    return _c('v-hover', {
      key: `slide-${index}`,
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref4) {
          var hover = _ref4.hover;
          return [_c('v-card', {
            staticClass: "mt-4",
            staticStyle: {
              "position": "relative"
            }
          }, [_c('v-preview-input', {
            attrs: {
              "contain": ""
            },
            model: {
              value: slide.image,
              callback: function ($$v) {
                _vm.$set(slide, "image", $$v);
              },
              expression: "slide.image"
            }
          }), _c('v-fade-transition', [_c('v-overlay', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: hover,
              expression: "hover"
            }],
            attrs: {
              "absolute": "",
              "z-index": "0"
            }
          }, [_c('v-menu', {
            attrs: {
              "offset-y": "",
              "nudge-left": "222",
              "close-on-content-click": false
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function (_ref5) {
                var attrs = _ref5.attrs,
                  on = _ref5.on;
                return _c('v-icon', _vm._g(_vm._b({
                  attrs: {
                    "large": ""
                  }
                }, 'v-icon', attrs, false), on), [_vm._v(" mdi-link-variant ")]);
              }
            }], null, true),
            model: {
              value: slide.showsModal_url,
              callback: function ($$v) {
                _vm.$set(slide, "showsModal_url", $$v);
              },
              expression: "slide.showsModal_url"
            }
          }, [_c('v-card', {
            staticClass: "pa-3",
            attrs: {
              "width": "480"
            }
          }, [_c('v-text-field', {
            attrs: {
              "label": "URL",
              "placeholder": "/products/621f2299052d6a062e7400bc",
              "autofocus": "",
              "persistent-placeholder": "",
              "hide-details": "",
              "dense": "",
              "outlined": ""
            },
            on: {
              "keydown": function ($event) {
                if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
                slide.showsModal_url = false;
              }
            },
            model: {
              value: slide.url,
              callback: function ($$v) {
                _vm.$set(slide, "url", $$v);
              },
              expression: "slide.url"
            }
          })], 1)], 1), _c('v-icon', {
            staticClass: "ml-3",
            attrs: {
              "large": ""
            },
            on: {
              "click": function ($event) {
                return _vm.banner.slides.splice(index, 1);
              }
            }
          }, [_vm._v(" mdi-delete ")])], 1)], 1)], 1)];
        }
      }], null, true)
    });
  }), 1) : _vm._e()], 1), _c('v-divider'), _c('v-card-actions', {
    staticClass: "justify-center"
  }, [_c('v-btn', {
    attrs: {
      "plain": "",
      "text": "",
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("mdi-content-save")]), _c('span', [_vm._v("저장")])], 1)], 1), _c('v-fade-transition', [_c('v-overlay', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    attrs: {
      "absolute": ""
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": "200",
      "width": "10"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
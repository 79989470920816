<template>
    <v-tab-item :value="value?._id || 'create'" eager style="height: 590px">
        <v-card flat tile v-bind="{ loading }">
            <v-fade-transition>
                <v-overlay light color="white" v-show="loading" absolute>
                    <v-progress-circular color="primary" indeterminate width="5" size="100" />
                </v-overlay>
            </v-fade-transition>
            <v-row no-gutters>
                <v-col cols="12" sm="4" md="3">
                    <v-card-subtitle class="caption font-weight-bold">썸네일<span class="pl-1 font-weight-medium grey--text"></span></v-card-subtitle>
                    <software-thumb v-model="form" ref="thumb" />
                    <v-divider />
                    <v-card-subtitle class="caption font-weight-bold">동영상<span class="pl-1 font-weight-medium grey--text"></span></v-card-subtitle>
                    <software-video v-model="form" ref="video" />
                </v-col>
                <v-col cols="auto" class="d-none d-sm-block"> <v-divider vertical /> </v-col>
                <v-col cols="12" class="d-block d-sm-none"> <v-divider /> </v-col>
                <v-col style="height: 537px">
                    <v-row class="ma-2">
                        <v-col class="pa-2"> <v-text-field label="제목" v-model="form.name" v-bind="inputAttrs" /> </v-col>
                        <v-col class="pa-2">
                            <v-text-field label="파일" v-bind="inputAttrs" readonly class="files-text-field">
                                <template #append>
                                    <software-file-list v-model="form" ref="file-list" />
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" class="pa-2">
                            <naver-smarteditor v-if="isOn" v-model="form.desc" />
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12">
                    <v-divider />
                    <v-card-actions>
                        <v-btn v-if="!isCreate" outlined color="red" @click="remove">삭제</v-btn>
                        <v-spacer />
                        <v-btn color="primary" @click="save">저장</v-btn>
                    </v-card-actions>
                </v-col>
            </v-row>
        </v-card>
    </v-tab-item>
</template>

<script>
import { inputAttrs } from "@/assets/variables";

import SoftwareThumb from "./software-thumb.vue";
import SoftwareVideo from "./software-video.vue";
import SoftwareFileList from "./software-file-list.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import api from "@/api";

const initForm = (form = {}) => ({
    ...form,

    _id: form._id ?? null,
    _thumb: form._thumb ?? null,
    _video: form._video ?? null,
    _files: [...(form._files ?? [])],

    thumb: form.thumb ?? null,
    video: form.video ?? null,
    files: [...(form.files ?? [])],

    index: form.index ?? 0,
    name: form.name ?? null,
    desc: form.desc ?? null,
});

export default {
    components: {
        SoftwareThumb,
        SoftwareVideo,
        SoftwareFileList,
        NaverSmarteditor,
    },
    props: {
        value: { type: Object, default: initForm },
    },
    data: () => ({
        form: initForm(),

        inputAttrs,

        loading: false,
    }),
    computed: {
        isCreate() {
            return !this.form?._id;
        },
        isOn() {
            if (this.isCreate) return this.$route.path == "/console/membership-softwares";
            return this.$route.path.includes(this.value?._id);
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        value() {
            this.init();
        },
    },
    methods: {
        init() {
            this.form = initForm(this.value);
        },
        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                ////////////////////////////////////////////////////////////////
                // 1. 기본정보 및 상세정보
                ////////////////////////////////////////////////////////////////
                const { post, put } = api.console.membership.softwares;

                let { _thumb, thumb, _video, video, _files = [], files = [], ...software } = this.form;
                software = (await (this.isCreate ? post : put)(software))?.software;
                console.log(software);
                this.form = { ...software, _thumb, thumb, _video, video, _files, files };

                ////////////////////////////////////////////////////////////////
                // 2. 자료
                ////////////////////////////////////////////////////////////////
                await this.$refs["thumb"]?.save?.();
                await this.$refs["video"]?.save?.();

                this.$nextTick(async () => {
                    try {
                        await this.$refs["file-list"]?.save?.();
                        const { _id: _software } = this.form;
                        [{ _files, files }] = [this.form];
                        const _filesDeleted = _files.filter((_file) => !files.some(({ _id } = {}) => _id == _file));
                        await Promise.all(_filesDeleted.map(async (_id) => await api.console.commonFiles.delete({ _id, _software })));

                        software = (await put({ _id: _software, _files: files.map(({ _id }) => _id) }))?.software;
                        alert("저장되었습니다");
                        this.$emit("input", software);
                        this.init();

                        const path = `/console/membership-softwares/${_software}`;
                        if (this.$route.path != path) this.$router.replace(path);
                    } finally {
                        this.loading = false;
                    }
                });
            } finally {
                this.loading = false;
            }
        },
        async remove() {
            this.$emit("remove", this.form);
        },
    },
};
</script>

<style lang="scss" scoped>
.files-text-field ::v-deep {
    .v-text-field__slot > input {
        display: none !important;
    }
    .v-input__append-inner {
        position: absolute;

        margin-top: 2px !important;
        padding: 0 !important;
    }
}
</style>

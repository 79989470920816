<template>
    <v-card flat v-bind="{ loading }">
        <v-card-title class="subtitle-1">카테고리 {{ isCreate ? "생성하기" : "수정하기" }}</v-card-title>
        <v-card-text>
            <category-select v-if="categories" v-model="form._parent" v-bind="{ categories, _disableds: [form._id] }" dense hideDetails />
            <v-text-field v-model="form.name" label="카테고리 이름" persistent-placeholder hide-details class="mt-3" />
            <v-text-field v-model="form.code" label="카테고리 코드" persistent-placeholder hide-details class="mt-3" />
            <v-text-field v-model="form.color" label="카테고리 색상" persistent-placeholder hide-details class="mt-3">
                <template #append> <v-avatar :color="form.color" size="24" class="elevation-4" /> </template>
            </v-text-field>
            <v-expand-transition>
                <div v-show="!isCreate">
                    <category-products v-model="form.products__nrml" v-bind="{ _category }" label="노출상품 설정" persistent-placeholder hide-details class="mt-3" />
                    <category-products v-model="form.products__kits" v-bind="{ _category }" label="노출키트 설정" persistent-placeholder hide-details class="mt-3" />
                </div>
            </v-expand-transition>
        </v-card-text>
        <v-card-actions>
            <v-fade-transition>
                <v-btn v-show="!isCreate" :disabled="!form._id || form.root ? true : false" color="grey darken-1" class="white--text" @click="remove">삭제</v-btn>
            </v-fade-transition>
            <v-spacer />
            <v-btn color="primary" @click="save">저장</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import api from "@/api";
import { initCategory } from "@/assets/variables";

import CategorySelect from "./category-select.vue";
import CategoryProducts from "./category-products.vue";

export default {
    components: {
        CategorySelect,
        CategoryProducts,
    },
    props: {
        value: { type: Object, default: initCategory }, // category
        categories: { type: Array, default: null },

        loading: { type: Boolean, default: false },
    },
    data: () => ({
        form: initCategory(),
    }),

    computed: {
        _category() {
            return this.form._id;
        },
        isCreate() {
            return !this._category;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initCategory(this.value);
        },
        async save() {
            if (this.loading) return;
            else this.$emit("loading", true);

            const { post, put } = api.console.shop.categories;
            try {
                const { _id, _parent, code, name, color, products__nrml = [], products__kits = [] } = this.form;
                const { category } = await (this.isCreate ? post : put)({ _id, _parent, code, name, color });
                category.products__nrml = await Promise.all(products__nrml.map(async ({ _id, onCategoryIndex, onCategoryShows }) => (await api.console.shop.products.put({ _id, onCategoryIndex, onCategoryShows }))?.product));
                category.products__kits = await Promise.all(products__kits.map(async ({ _id, onCategoryIndex, onCategoryShows }) => (await api.console.shop.products.put({ _id, onCategoryIndex, onCategoryShows }))?.product));
                this.loading = false;
                this.$emit("loading", false);
                this.$emit("search");
            } finally {
                if (this.loading) this.$emit("loading", false);
            }
        },
        async remove() {
            if (this.loading) return;
            else this.$emit("loading", true);

            try {
                await api.console.shop.categories.delete(this.form);
                this.$emit("loading", false);
                this.$emit("search");
            } finally {
                if (this.loading) this.$emit("loading", false);
            }
        },
    },
};
</script>

<style>
</style>
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1440",
      "width": "100%"
    }
  }, [_c('list-heading', {
    staticClass: "mt-8",
    attrs: {
      "title": "Events"
    }
  }), _c('v-data-table', _vm._b({
    staticClass: "my-4",
    attrs: {
      "hide-default-footer": "",
      "disable-sort": "",
      "disable-filtering": "",
      "disable-pagination": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.src`,
      fn: function (_ref2) {
        var value = _ref2.value;
        return [_c('image-popup', {
          attrs: {
            "size": "40",
            "src": value
          }
        })];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('seminar-view', _vm._b({
          attrs: {
            "value": item
          },
          on: {
            "input": _vm.update
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref4) {
              var attrs = _ref4.attrs,
                on = _ref4.on;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "small": "",
                  "text": "",
                  "icon": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-pencil")])], 1)];
            }
          }], null, true)
        }, 'seminar-view', {
          lecturers: _vm.lecturers
        }, false)), _c('v-btn', {
          attrs: {
            "small": "",
            "text": "",
            "icon": ""
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)];
      }
    }, {
      key: "footer",
      fn: function () {
        return [_c('v-divider'), _c('v-pagination', {
          staticClass: "py-3",
          attrs: {
            "length": _vm.pageCount,
            "total-visible": 11
          },
          on: {
            "input": _vm.search
          },
          model: {
            value: _vm.page,
            callback: function ($$v) {
              _vm.page = $$v;
            },
            expression: "page"
          }
        })];
      },
      proxy: true
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items
  }, false)), _c('seminar-view', _vm._b({
    on: {
      "input": _vm.update
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref5) {
        var attrs = _ref5.attrs,
          on = _ref5.on;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "x-large": "",
            "fab": "",
            "color": "primary",
            "fixed": "",
            "right": "",
            "bottom": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-pencil")])], 1)];
      }
    }])
  }, 'seminar-view', {
    lecturers: _vm.lecturers
  }, false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
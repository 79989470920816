<template>
    <v-layout justify-center>
        <v-responsive max-width="1080" width="100%">
            <list-heading title="온디맨드 Coupon 관리" class="mt-8" />
            <v-tabs class="mt-4">
                <v-tab to="/console/academy-coupons">목록</v-tab>
                <v-tab to="/console/academy-coupons/issue">발급</v-tab>
            </v-tabs>
            <v-divider />
            <v-tabs-items v-model="tab" class="mb-4">
                <v-tab-item value="academy-coupons">
                    <v-data-table v-bind="{ headers, items }" hide-default-footer disable-sort disable-filtering disable-pagination show-expand>
                        <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                        <template #[`item.disabled`]="{ item }">
                            <v-switch v-model="item.disabled" inset dense hide-details class="mt-0 ml-1 mr-n4 pa-0" @change="(disabled) => save({ _id: item._id, disabled })" />
                        </template>
                        <template #[`item.actions`]="{ item }">
                            <v-btn small text icon @click="revoke(item)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </template>
                        <template #expanded-item="{item,headers}">
                            <td :colspan="headers.length">
                                <v-row>
                                    <v-col cols="auto"> 쿠폰설명 </v-col>
                                    <v-col cols="auto" class="pa-0"> <v-divider vertical /> </v-col>
                                    <v-col style="white-space: pre;">{{ item.desc }}</v-col>

                                    <template v-if="item.hasSpecificTarget">
                                        <v-col cols="auto" class="pa-0"> <v-divider vertical /> </v-col>
                                        <v-col cols="auto"> 발급대상 </v-col>
                                        <v-col cols="auto" class="pa-0"> <v-divider vertical /> </v-col>
                                        <v-col>{{ item.user.name }}</v-col>
                                    </template>
                                </v-row>
                            </td>
                        </template>
                        <template #footer>
                            <v-divider />
                            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="py-3" @input="search" />
                        </template>
                    </v-data-table>
                </v-tab-item>
                <v-tab-item value="issue">
                    <coupon-issue />
                </v-tab-item>
            </v-tabs-items>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { ACADEMY_COUPON_STATUSES, inputAttrs } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import CouponIssue from "@/components/console/academy/coupon-issue.vue";

const headers = [
    { width: 110, text: "생성일", value: "createdAt", formatter: (value) => value?.toDateTime(), divider: true },
    { width: +90, value: "hasSpecificTarget", text: "발급유형", formatter: (hasSpecificTarget) => (hasSpecificTarget ? "타겟쿠폰" : "전체쿠폰"), divider: true },
    { value: "name", text: "쿠폰명", class: "text-truncate", divider: true },
    { width: 100, value: "amount", text: "쿠폰금액", formatter: (amount) => "USD " + (+amount || 0).format(), divider: true },
    { width: 190, value: "applicationPeriod", text: "쿠폰적용기간", divider: true },
    { width: +90, value: "status", text: "상태", divider: true, formatter: (value) => ACADEMY_COUPON_STATUSES[value]?.text },
    { width: +80, value: "disabled", text: "사용정지", divider: true, align: "center", class: "px-0" },
    { width: +60, value: "actions", align: "right" },
];

export default {
    components: {
        ListHeading,
        CouponIssue,
    },
    data: () => ({
        page: 1,
        limit: 10,
        filter: {},
        summary: { totalCount: 0 },

        headers,
        coupons: [],
        lecturers: [],

        inputAttrs,
    }),
    computed: {
        tab() {
            return this.$route.path.split("/").pop();
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        items() {
            return this.coupons.map((item, i) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - i }));
        },
    },
    mounted() {
        this.init().then(this.search);
    },
    methods: {
        async init() {
            this.page = +this.$route.query.page || 1;
            this.coupons = [];
            this.summary = { totalCount: 0 };

            const { lecturers } = await api.console.academy.lecturers.gets();
            this.lecturers = lecturers;
        },
        async search() {
            try {
                const { page, skip, limit } = this;
                if (+this.page !== (+this.$route.query.page || 1)) this.$router.push({ query: Object.assignDefined({}, this.$route.query, this.filter, { page }) });

                var { summary, coupons } = await api.console.academy.coupons.gets({
                    headers: { skip, limit },
                    params: this.filter,
                });

                this.coupons = coupons;
                this.summary = summary;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.email);
                else alert(error.email.toString());
            }
        },
        async update(item) {
            const index = this.coupons.findIndex(({ _id }) => _id == item._id);
            if (index > -1) this.coupons.splice(index, 1, item);
            else {
                this.coupons = [item, ...this.coupons];
                this.summary.totalCount += 1;
            }
        },
        async revoke({ _thumb, _id, name }) {
            const go = confirm(`${name} - 데이터를 삭제하시겠습니까?`);
            if (go) {
                if (_thumb) await api.console.commonFiles.delete({ _id: _thumb });
                await api.console.academy.coupons.delete({ _id });
                this.coupons = this.coupons.filter((coupon) => coupon?._id != _id);
                this.summary.totalCount -= 1;
            }
        },
        async save(item) {
            const { coupon } = await api.console.academy.coupons.put(item);
            this.update(coupon);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    td.white-space-pre-line {
        white-space: pre-line;
    }
    td.text-truncate {
        max-width: 0;
    }
}
</style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "880",
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.title) + " "), _c('v-btn', {
    attrs: {
      "text": "",
      "icon": "",
      "absolute": "",
      "right": ""
    },
    on: {
      "click": function ($event) {
        _vm.show = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "pa-0",
    staticStyle: {
      "max-height": "calc(90vh - 58px - 1px - 1px - 52px)",
      "overflow-y": "auto"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "subtitle-2 font-weight-bold ma-4"
  }, [_c('span', [_vm._v("썸네일")]), _c('span', {
    staticClass: "pl-1 font-weight-medium grey--text"
  }, [_vm._v("(484*484px)")])]), _c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var hover = _ref2.hover;
        return [_c('v-img', _vm._b({
          staticClass: "ma-4",
          attrs: {
            "contain": "",
            "width": "280",
            "height": "280"
          }
        }, 'v-img', {
          src: _vm.src
        }, false), [_c('v-fade-transition', [_c('v-overlay', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: hover && !!_vm.src,
            expression: "hover && !!src"
          }],
          attrs: {
            "absolute": "",
            "z-index": "0"
          }
        }, [_c('v-btn', {
          attrs: {
            "fab": "",
            "color": "primary"
          },
          on: {
            "click": _vm.showFileDialog
          }
        }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1), _c('v-btn', {
          staticClass: "ml-2",
          attrs: {
            "fab": "",
            "color": "red"
          },
          on: {
            "click": function ($event) {
              _vm.item.thumb = null;
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)], 1)], 1), _c('v-fade-transition', [_c('v-btn', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.src,
            expression: "!src"
          }],
          attrs: {
            "tile": "",
            "height": "100%",
            "width": "100%"
          },
          on: {
            "click": _vm.showFileDialog
          }
        }, [_c('v-icon', {
          attrs: {
            "x-large": ""
          }
        }, [_vm._v("mdi-image-broken")])], 1)], 1), _c('v-file-input', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false"
          }],
          ref: "file-input",
          model: {
            value: _vm.item.thumb,
            callback: function ($$v) {
              _vm.$set(_vm.item, "thumb", $$v);
            },
            expression: "item.thumb"
          }
        })], 1)];
      }
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', [_c('v-text-field', _vm._b({
    staticClass: "ma-4",
    attrs: {
      "label": "제목"
    },
    model: {
      value: _vm.item.subject,
      callback: function ($$v) {
        _vm.$set(_vm.item, "subject", $$v);
      },
      expression: "item.subject"
    }
  }, 'v-text-field', _vm.inputAttrs, false)), _c('v-text-field', _vm._b({
    staticClass: "ma-4",
    attrs: {
      "label": "요약"
    },
    model: {
      value: _vm.item.summary,
      callback: function ($$v) {
        _vm.$set(_vm.item, "summary", $$v);
      },
      expression: "item.summary"
    }
  }, 'v-text-field', _vm.inputAttrs, false)), _c('v-row', {
    staticClass: "my-n4",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', [_c('v-select', _vm._b({
    staticClass: "ma-4 mr-2",
    attrs: {
      "label": "카테고리",
      "items": _vm.categories
    },
    model: {
      value: _vm.item.category,
      callback: function ($$v) {
        _vm.$set(_vm.item, "category", $$v);
      },
      expression: "item.category"
    }
  }, 'v-select', _vm.inputAttrs, false))], 1), _c('v-col', [_c('v-datetime-field', _vm._b({
    attrs: {
      "label": "일시",
      "className": "ma-4 ml-2"
    },
    model: {
      value: _vm.item.datetime,
      callback: function ($$v) {
        _vm.$set(_vm.item, "datetime", $$v);
      },
      expression: "item.datetime"
    }
  }, 'v-datetime-field', _vm.inputAttrs, false))], 1)], 1), _c('v-text-field', _vm._b({
    staticClass: "ma-4",
    attrs: {
      "label": "국가"
    },
    model: {
      value: _vm.item.country,
      callback: function ($$v) {
        _vm.$set(_vm.item, "country", $$v);
      },
      expression: "item.country"
    }
  }, 'v-text-field', _vm.inputAttrs, false)), _c('v-text-field', _vm._b({
    staticClass: "ma-4",
    attrs: {
      "label": "장소"
    },
    model: {
      value: _vm.item.location,
      callback: function ($$v) {
        _vm.$set(_vm.item, "location", $$v);
      },
      expression: "item.location"
    }
  }, 'v-text-field', _vm.inputAttrs, false)), _c('v-text-field', _vm._b({
    staticClass: "ma-4",
    attrs: {
      "label": "웹사이트"
    },
    model: {
      value: _vm.item.website,
      callback: function ($$v) {
        _vm.$set(_vm.item, "website", $$v);
      },
      expression: "item.website"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1)], 1), _c('v-divider'), _c('naver-smarteditor', {
    staticClass: "ma-4",
    model: {
      value: _vm.item.content,
      callback: function ($$v) {
        _vm.$set(_vm.item, "content", $$v);
      },
      expression: "item.content"
    }
  })], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "grey"
    },
    on: {
      "click": _vm.init
    }
  }, [_vm._v("초기화")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-btn-toggle v-model="group" mandatory borderless class="d-flex" @change="emit">
        <template v-for="(item, index) in items">
            <v-col :key="item.value" class="pa-0">
                <v-btn v-bind="{ loading }" :value="item.value" text tile block active-class="primary--text">
                    {{ item.text }}
                </v-btn>
            </v-col>
            <v-divider vertical v-if="index != items.length - 1" :key="`${index}-divider`" />
        </template>
    </v-btn-toggle>
</template>

<script>
import { BRANCH_GROUPS } from "@/assets/variables";

const items = Object.values(BRANCH_GROUPS);

export default {
    props: {
        value: { type: String, default: items[0].value },
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        group: null,
        items,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.group = this.value;
        },
        emit() {
            this.$emit("input", this.group);
        },
    },
};
</script>

<style>
</style>
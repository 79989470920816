<template>
    <v-layout justify-center>
        <v-responsive max-width="1440" width="100%">
            <list-heading title="Events" class="mt-8" />
            <v-data-table v-bind="{ headers, items }" hide-default-footer disable-sort disable-filtering disable-pagination class="my-4">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.src`]="{ value }">
                    <image-popup size="40" :src="value" />
                </template>
                <template #[`item.actions`]="{ item }">
                    <seminar-view :value="item" v-bind="{ lecturers }" @input="update">
                        <template #activator="{ attrs, on }">
                            <v-btn small text icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                    </seminar-view>
                    <v-btn small text icon @click="remove(item)">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </template>
                <template #footer>
                    <v-divider />
                    <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="py-3" @input="search" />
                </template>
            </v-data-table>
            <seminar-view v-bind="{ lecturers }" @input="update">
                <template #activator="{ attrs, on }">
                    <v-btn x-large fab color="primary" fixed right bottom v-bind="attrs" v-on="on">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </template>
            </seminar-view>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { STORY_NEWS_CATEGORIES, inputAttrs } from "@/assets/variables";
import ImagePopup from "@/components/console/dumb/image-popup.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import SeminarView from "@/components/console/academy/seminar-view.vue";

const headers = [
    { width: 110, text: "카테고리", value: "category", formatter: (key) => STORY_NEWS_CATEGORIES[key]?.text || key, divider: true },
    { width: 80, text: "썸네일", value: "src", divider: true },
    { text: "제목", value: "subject", class: "text-truncate", divider: true },
    { text: "국가", value: "country", class: "text-truncate", divider: true },
    { text: "장소", value: "location", class: "text-truncate", divider: true },
    { text: "웹사이트", value: "website", class: "text-truncate", divider: true },
    { width: 170, text: "일시", value: "datetime", formatter: (value) => value?.toDateTime?.() || "미정", divider: true },
    { width: 90, value: "actions", align: "right" },
];
export default {
    components: {
        ImagePopup,
        ListHeading,
        SeminarView,
    },
    data: () => ({
        headers,
        inputAttrs,

        page: 1,
        limit: 10,
        filter: {},

        summary: { totalCount: 0 },
        seminars: [],
        lecturers: [],
    }),
    computed: {
        items() {
            return this.seminars.map((item, i) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - i }));
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
    },
    mounted() {
        this.init().then(this.search);
    },
    methods: {
        async init() {
            this.page = +this.$route.query.page || 1;
            this.seminars = [];
            this.summary = { totalCount: 0 };
            [{ lecturers: this.lecturers }] = [await api.console.academy.lecturers.gets()];
        },
        async search() {
            try {
                const { page, skip, limit } = this;
                this.$router.push({ query: Object.assignDefined({}, this.$route.query, this.filter, { page }) });

                var { summary, seminars } = await api.console.academy.seminars.gets({
                    headers: { skip, limit },
                    params: this.filter,
                });

                this.seminars = seminars;
                this.summary = summary;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.email);
                else alert(error.email.toString());
            }
        },
        async update(item) {
            const index = this.seminars.findIndex(({ _id }) => _id == item._id);
            if (index > -1) this.seminars.splice(index, 1, item);
            else {
                this.seminars = [item, ...this.seminars];
                this.summary.totalCount += 1;
            }
        },
        async remove({ _thumb, _id, name }) {
            const go = confirm(`${name} - 데이터를 삭제하시겠습니까?`);
            if (go) {
                if (_thumb) await api.console.commonFiles.delete({ _id: _thumb });
                await api.console.academy.seminars.delete({ _id });
                this.seminars = this.seminars.filter((seminar) => seminar?._id != _id);
                this.summary.totalCount -= 1;
            }
        },
        async save(item, props) {
            const { seminar } = await api.console.academy.seminars.put(item);
            this.update(seminar);
        },
        getStateText(key) {
            return ACADEMY_LIVE_STATES[key]?.text || "-";
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    td.text-truncate {
        max-width: 0;
    }
    td.text-align-right {
        text-align: right !important;
    }
    td.white-space-pre-line {
        white-space: pre-line;
    }
}
</style>

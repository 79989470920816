<template>
    <v-data-table v-bind="{ loading, headers, items }" hide-default-footer disable-sort disable-filtering disable-pagination :items-per-page="-1" class="my-4 v-sheet--outlined rounded-0" ref="data-table">
        <template #body="props">
            <draggable v-if="$refs['data-table']" tag="tbody" :list="props.items" @change="setNewIndex" :sort="!loading">
                <v-nodes :vnodes="$refs['data-table'].genItems(props.items, props)" />
            </draggable>
        </template>
        <template #top>
            <v-subheader>{{ label || "노출상품 설정" }}</v-subheader>
            <v-divider />
        </template>
        <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
        <template #[`item.thumbnail`]="{ value }">
            <image-popup size="40" :src="value?.url" />
        </template>
        <template v-slot:[`item.onCategoryShows`]="{ item }">
            <v-row justify="center" align="center">
                <v-col cols="auto" class="pa-0">
                    <v-switch v-model="item.onCategoryShows" v-bind="switchAttrs" class="ml-1 mr-n4" color="red lighten-1" @change="(onCategoryShows) => setNewShows({ _id: item._id, onCategoryShows })" />
                </v-col>
            </v-row>
        </template>
    </v-data-table>
</template>

<script>
import { switchAttrs } from "@/assets/variables";

import Draggable from "vuedraggable";
import ImagePopup from "@/components/console/dumb/image-popup.vue";

const headers = [
    { width: +60, text: "#", value: "onCategoryIndex", divider: true, align: "end" },
    { width: +80, text: "썸네일", value: "thumbnail", divider: true, align:"center" },
    { text: "상품명", value: "name", divider: true },
    { width: +80, text: "노출", value: "onCategoryShows", align: "center" },
].map((item) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-") }));

export default {
    components: {
        VNodes: { functional: true, render: (h, ctx) => ctx.props.vnodes },

        Draggable,
        ImagePopup,
    },
    props: {
        _category: { type: String },
        label: { type: String },
        value: { type: Array, default: () => [] }, // products
    },
    data: () => ({
        products: [],

        loading: false,
        headers,
        switchAttrs,
    }),
    computed: {
        items() {
            return [...this.products].map((item, index) => ({ ...item, onCategoryIndex: index + 1 }));
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.products = [...this.value].sort((a, b) => a?.onCategoryIndex - b?.onCategoryIndex);
        },
        emit() {
            this.$emit("input", this.items);
        },
        setNewIndex({ moved = {} }) {
            let { oldIndex, newIndex } = moved;
            this.products.splice(newIndex, 0, this.products.splice(oldIndex, 1)[0]);
            this.emit();
        },
        setNewShows({ _id, onCategoryShows }) {
            const product = this.products.find((item) => item._id == _id);
            product.onCategoryShows = onCategoryShows;
        },
    },
};
</script>


var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "320"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" 지역 " + _vm._s(_vm.isCreate ? "생성" : "수정") + " "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "pt-5 pb-0"
  }, [_vm.isCreate ? [_c('v-select', _vm._b({
    attrs: {
      "label": "유형",
      "items": _vm.zoneTypes
    },
    on: {
      "change": _vm.resetItem
    },
    model: {
      value: _vm.item.type,
      callback: function ($$v) {
        _vm.$set(_vm.item, "type", $$v);
      },
      expression: "item.type"
    }
  }, 'v-select', _vm.inputAttrs, false))] : [_c('v-text-field', _vm._b({
    attrs: {
      "label": "유형",
      "value": _vm.item.typeText,
      "readonly": !_vm.isCreate
    }
  }, 'v-text-field', _vm.inputAttrs, false))], _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isType(_vm.CONTINENT),
      expression: "isType(CONTINENT)"
    }]
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "대륙코드",
      "readonly": !_vm.isCreate
    },
    model: {
      value: _vm.item.code,
      callback: function ($$v) {
        _vm.$set(_vm.item, "code", $$v);
      },
      expression: "item.code"
    }
  }, 'v-text-field', _vm.inputAttrs, false)), _c('v-text-field', _vm._b({
    attrs: {
      "label": "대륙명"
    },
    model: {
      value: _vm.item.name,
      callback: function ($$v) {
        _vm.$set(_vm.item, "name", $$v);
      },
      expression: "item.name"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1)]), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isType(_vm.CONTINENT),
      expression: "!isType(CONTINENT)"
    }]
  }, [_c('v-select', _vm._b({
    attrs: {
      "label": "대륙",
      "items": _vm.continents,
      "item-text": "name",
      "item-value": "_id",
      "placeholder": "대륙을 선택하세요"
    },
    on: {
      "change": function ($event) {
        _vm.item._country = null;
      }
    },
    model: {
      value: _vm.item._continent,
      callback: function ($$v) {
        _vm.$set(_vm.item, "_continent", $$v);
      },
      expression: "item._continent"
    }
  }, 'v-select', _vm.inputAttrs, false))], 1)]), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isType(_vm.COUNTRY),
      expression: "isType(COUNTRY)"
    }]
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "국가코드",
      "readonly": !_vm.isCreate
    },
    model: {
      value: _vm.item.code,
      callback: function ($$v) {
        _vm.$set(_vm.item, "code", $$v);
      },
      expression: "item.code"
    }
  }, 'v-text-field', _vm.inputAttrs, false)), _c('v-text-field', _vm._b({
    attrs: {
      "label": "국가명"
    },
    model: {
      value: _vm.item.name,
      callback: function ($$v) {
        _vm.$set(_vm.item, "name", $$v);
      },
      expression: "item.name"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1)]), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_c('v-select', _vm._b({
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isType(_vm.REGION),
      expression: "isType(REGION)"
    }],
    attrs: {
      "label": "국가",
      "items": _vm.countries,
      "item-text": "name",
      "item-value": "_id",
      "no-data-text": "대륙을 먼저 선택하세요",
      "placeholder": "국가를 선택하세요"
    },
    model: {
      value: _vm.item._country,
      callback: function ($$v) {
        _vm.$set(_vm.item, "_country", $$v);
      },
      expression: "item._country"
    }
  }, 'v-select', _vm.inputAttrs, false))], 1), _c('v-fade-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isType(_vm.REGION),
      expression: "isType(REGION)"
    }]
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "지역코드",
      "readonly": !_vm.isCreate
    },
    model: {
      value: _vm.item.code,
      callback: function ($$v) {
        _vm.$set(_vm.item, "code", $$v);
      },
      expression: "item.code"
    }
  }, 'v-text-field', _vm.inputAttrs, false)), _c('v-text-field', _vm._b({
    attrs: {
      "label": "지역명"
    },
    model: {
      value: _vm.item.name,
      callback: function ($$v) {
        _vm.$set(_vm.item, "name", $$v);
      },
      expression: "item.name"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1)])], 2), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장하기")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', _vm._b({
    attrs: {
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c('v-card-subtitle', {
          staticClass: "grey lighten-4 caption"
        }, [_vm._v(" 지사별 지역 배정 ")]), _c('v-divider'), _c('v-row', {
          staticClass: "ma-0 pa-1"
        }, [_c('v-col', {
          staticClass: "pa-2",
          attrs: {
            "cols": "12",
            "md": "6",
            "lg": "4"
          }
        }, [_c('v-select', _vm._b({
          attrs: {
            "label": "배정여부",
            "items": _vm.assignments
          },
          model: {
            value: _vm.filter.assignment,
            callback: function ($$v) {
              _vm.$set(_vm.filter, "assignment", $$v);
            },
            expression: "filter.assignment"
          }
        }, 'v-select', _vm.inputAttrs, false))], 1), _c('v-col', {
          staticClass: "pa-2",
          attrs: {
            "cols": "12",
            "md": "6",
            "lg": "4"
          }
        }, [_c('v-select', _vm._b({
          attrs: {
            "label": "대륙",
            "items": _vm.continents,
            "item-text": "name",
            "item-value": "_id"
          },
          on: {
            "change": function ($event) {
              _vm.filter._country = null;
            }
          },
          model: {
            value: _vm.filter._continent,
            callback: function ($$v) {
              _vm.$set(_vm.filter, "_continent", $$v);
            },
            expression: "filter._continent"
          }
        }, 'v-select', _vm.inputAttrs, false))], 1), _c('v-col', {
          staticClass: "pa-2",
          attrs: {
            "cols": "12",
            "md": "12",
            "lg": "4"
          }
        }, [_c('v-select', _vm._b({
          attrs: {
            "label": "국가",
            "items": _vm.countries,
            "item-text": "name",
            "item-value": "_id",
            "disabled": _vm.countries.length == 0
          },
          model: {
            value: _vm.filter._country,
            callback: function ($$v) {
              _vm.$set(_vm.filter, "_country", $$v);
            },
            expression: "filter._country"
          }
        }, 'v-select', _vm.inputAttrs, false))], 1)], 1), _c('v-divider')];
      },
      proxy: true
    }, _vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.assignedZones`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('branch-assignment-dialog', {
          attrs: {
            "value": item,
            "zones": _vm.unassignedZones
          },
          on: {
            "input": _vm.update
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref3) {
              var _item$assignedZones, _item$assignedZones$m, _item$assignedZones$m2;
              var attrs = _ref3.attrs,
                on = _ref3.on;
              return [_c('v-card', _vm._g(_vm._b({
                staticClass: "d-flex align-center px-4",
                attrs: {
                  "flat": "",
                  "tile": "",
                  "min-height": "32",
                  "color": "transparent"
                }
              }, 'v-card', attrs, false), on), [_c('span', {
                staticClass: "text-truncate",
                staticStyle: {
                  "width": "100%",
                  "flex-grow": "1"
                }
              }, [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : (_item$assignedZones = item.assignedZones) === null || _item$assignedZones === void 0 ? void 0 : (_item$assignedZones$m = _item$assignedZones.map(function (_ref4) {
                var fullName = _ref4.fullName;
                return fullName;
              })) === null || _item$assignedZones$m === void 0 ? void 0 : (_item$assignedZones$m2 = _item$assignedZones$m.join) === null || _item$assignedZones$m2 === void 0 ? void 0 : _item$assignedZones$m2.call(_item$assignedZones$m, ", ")) || "-") + " ")])])];
            }
          }], null, true)
        })];
      }
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }
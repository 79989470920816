var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-file-input', _vm._b({
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.file,
      callback: function ($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  }, 'v-file-input', _vm.$attrs, false));

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm$board, _vm$board2, _vm$board2$files;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-board-view",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [!_vm.$route.params._board ? _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("게시글 등록")]) : _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("게시글 상세")]), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 pb-0"
  }, [_vm._v("기본정보")]), _c('v-card-text', [_vm.$route.params._board ? _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm.board.writer || {}).name,
      "label": "작성자",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": "",
      "disabled": ""
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-datetime-field', {
    attrs: {
      "value": _vm.board.createdAt ? _vm.board.createdAt.toDateTime() : undefined,
      "label": "작성일",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    on: {
      "input": function (value) {
        return _vm.board.createdAt = value;
      }
    }
  })], 1)], 1) : _vm._e(), _c('v-row', {
    staticClass: "mt-3"
  }, [['youtube'].includes(_vm.board.code) ? _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('youtube-categories-view', {
    attrs: {
      "label": "카테고리",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.category,
      callback: function ($$v) {
        _vm.$set(_vm.board, "category", $$v);
      },
      expression: "board.category"
    }
  })], 1) : _vm._e(), ['report'].includes(_vm.board.code) ? _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('report-view-categories', {
    attrs: {
      "label": "카테고리",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.category,
      callback: function ($$v) {
        _vm.$set(_vm.board, "category", $$v);
      },
      expression: "board.category"
    }
  })], 1) : _vm._e(), ['forms'].includes(_vm.board.code) ? _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('forms-view-categories', {
    attrs: {
      "label": "카테고리",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.category,
      callback: function ($$v) {
        _vm.$set(_vm.board, "category", $$v);
      },
      expression: "board.category"
    }
  })], 1) : _vm._e(), _vm.categories.filter(function (category) {
    return category.scope.includes(_vm.board.code);
  }).length ? _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.categories.filter(function (category) {
        return category.scope.includes(_vm.board.code);
      }),
      "item-text": "name",
      "item-value": "name",
      "label": "카테고리",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.category,
      callback: function ($$v) {
        _vm.$set(_vm.board, "category", $$v);
      },
      expression: "board.category"
    }
  })], 1) : _vm._e(), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "제목",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.subject,
      callback: function ($$v) {
        _vm.$set(_vm.board, "subject", $$v);
      },
      expression: "board.subject"
    }
  })], 1), ['press', 'main-gallery'].includes(_vm.board.code) ? _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "링크주소",
      "placeholder": "https://youtube.com",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.meta.href,
      callback: function ($$v) {
        _vm.$set(_vm.board.meta, "href", $$v);
      },
      expression: "board.meta.href"
    }
  })], 1) : _vm._e()], 1), !['digital-center-library', 'story-dentium-notice', 'story-ir-notice', 'story-ir-data', 'story-ir-analysis', 'lecture-qna', 'lecture-review', 'main-gallery'].includes(_vm.board.code) ? _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "한줄설명",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.summary,
      callback: function ($$v) {
        _vm.$set(_vm.board, "summary", $$v);
      },
      expression: "board.summary"
    }
  })], 1)], 1) : _vm._e(), ['event', 'store'].includes(_vm.$route.query.code || _vm.board.code) ? _c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "기간",
      "placeholder": "ex) 2020-01-01 ~ 2020.12.31",
      "hide-details": ""
    },
    model: {
      value: _vm.board.period,
      callback: function ($$v) {
        _vm.$set(_vm.board, "period", $$v);
      },
      expression: "board.period"
    }
  })], 1)], 1) : _vm._e()], 1)], 1), !['digital-center-library', 'story-dentium-notice', 'story-ir-notice', 'story-ir-data', 'story-ir-analysis', 'lecture-qna', 'lecture-review'].includes(_vm.board.code) ? _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("썸네일")]), _c('v-card-text', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.board.thumb ? _c('v-img', {
    attrs: {
      "max-width": "96",
      "max-height": "96",
      "src": _vm.createObjectURL(_vm.board.thumb),
      "contain": ""
    }
  }) : _c('v-responsive', {
    staticClass: "grey lighten-2",
    attrs: {
      "width": "96",
      "height": "96"
    }
  }, [_c('v-row', {
    staticClass: "fill-height",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v("mdi-pencil")])], 1)], 1)], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-file-input', {
    staticClass: "mt-2 mx-3",
    attrs: {
      "label": "썸네일 이미지",
      "persistent-placeholder": "",
      "show-size": "",
      "accept": "image/*"
    },
    model: {
      value: _vm.board.thumb,
      callback: function ($$v) {
        _vm.$set(_vm.board, "thumb", $$v);
      },
      expression: "board.thumb"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), ['youtube'].includes(_vm.board.code) ? _c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("유튜브")]), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "링크주소",
      "placeholder": "https://youtube.com/",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.meta.youtube,
      callback: function ($$v) {
        _vm.$set(_vm.board.meta, "youtube", $$v);
      },
      expression: "board.meta.youtube"
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "secondary"
    },
    on: {
      "click": _vm.setVideoId
    }
  }, [_vm._v("미리보기")])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-card', {
    attrs: {
      "height": "176"
    }
  }, [_c('youtube', {
    staticStyle: {
      "height": "176px"
    },
    attrs: {
      "video-id": _vm.videoId,
      "fitParent": ""
    }
  })], 1)], 1)], 1) : _vm._e(), ['digital-center-library'].includes(_vm.board.code) ? _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("파일")]), _c('v-card-text', [_c('v-chip-group', [_vm._l((_vm$board = _vm.board) === null || _vm$board === void 0 ? void 0 : _vm$board.files, function (file) {
    return _c('v-chip', {
      key: file === null || file === void 0 ? void 0 : file.name,
      attrs: {
        "label": "",
        "outlined": "",
        "close": "",
        "download": file.name,
        "href": _vm.createObjectURL(file)
      },
      on: {
        "click:close": function ($event) {
          _vm.board.files = _vm.board.files.filter(function (item) {
            return item != file;
          });
        }
      }
    }, [_vm._v(" " + _vm._s(file === null || file === void 0 ? void 0 : file.name) + " ")]);
  }), (((_vm$board2 = _vm.board) === null || _vm$board2 === void 0 ? void 0 : (_vm$board2$files = _vm$board2.files) === null || _vm$board2$files === void 0 ? void 0 : _vm$board2$files.length) || 0) < 2 ? _c('v-chip', {
    attrs: {
      "label": "",
      "tile": ""
    },
    on: {
      "click": function ($event) {
        var _vm$$refs$fileInput, _vm$$refs$fileInput$$, _vm$$refs$fileInput$$2, _vm$$refs$fileInput$$3;
        (_vm$$refs$fileInput = _vm.$refs['file-input']) === null || _vm$$refs$fileInput === void 0 ? void 0 : (_vm$$refs$fileInput$$ = _vm$$refs$fileInput.$el.getElementsByTagName('input')) === null || _vm$$refs$fileInput$$ === void 0 ? void 0 : (_vm$$refs$fileInput$$2 = _vm$$refs$fileInput$$[0]) === null || _vm$$refs$fileInput$$2 === void 0 ? void 0 : (_vm$$refs$fileInput$$3 = _vm$$refs$fileInput$$2.click) === null || _vm$$refs$fileInput$$3 === void 0 ? void 0 : _vm$$refs$fileInput$$3.call(_vm$$refs$fileInput$$2);
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1) : _vm._e()], 2), _c('v-file-input', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "file-input",
    model: {
      value: _vm.file,
      callback: function ($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  })], 1)], 1) : _vm._e(), ['forms'].includes(_vm.board.code) ? _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("파일")]), _c('v-card-text', [_c('v-file-input', {
    attrs: {
      "show-size": ""
    },
    model: {
      value: _vm.board.files[0],
      callback: function ($$v) {
        _vm.$set(_vm.board.files, 0, $$v);
      },
      expression: "board.files[0]"
    }
  })], 1)], 1) : _vm._e(), ['lecture-qna', 'lecture-review'].includes(_vm.board.code) ? _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("내용")]), _c('v-card-text', [_c('v-textarea', {
    attrs: {
      "id": "content",
      "rows": "10",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.content,
      callback: function ($$v) {
        _vm.$set(_vm.board, "content", $$v);
      },
      expression: "board.content"
    }
  })], 1)], 1) : !['main-gallery'].includes(_vm.board.code) ? _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("내용")]), _c('v-card-text', [_c('naver-smarteditor', {
    attrs: {
      "id": "content",
      "rows": "10"
    },
    model: {
      value: _vm.board.content,
      callback: function ($$v) {
        _vm.$set(_vm.board, "content", $$v);
      },
      expression: "board.content"
    }
  })], 1)], 1) : _vm._e(), ['lecture-qna'].includes(_vm.board.code) ? _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("답변")]), _c('v-card-text', [_c('naver-smarteditor', {
    attrs: {
      "id": "reply",
      "rows": "10"
    },
    model: {
      value: _vm.board.reply,
      callback: function ($$v) {
        _vm.$set(_vm.board, "reply", $$v);
      },
      expression: "board.reply"
    }
  })], 1)], 1) : _vm._e(), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1), _c('daum-postcode', {
    ref: "daumPostcode",
    on: {
      "change": _vm.changePostcode
    }
  })], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }
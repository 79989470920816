var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "px-3"
  }, [_c('list-heading', {
    staticClass: "mt-8",
    attrs: {
      "title": "메인 미니멀리즘 설정"
    }
  }), _c('v-card', {
    staticClass: "my-4",
    staticStyle: {
      "overflow": "hidden"
    }
  }, [_c('banner-input', {
    staticStyle: {
      "height": "653px"
    },
    attrs: {
      "mode": "horizontal",
      "label": "미니멀리즘",
      "sublabel": "권장사이즈:330*500px",
      "name": "메인 미니멀리즘",
      "code": "main-product-banners"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
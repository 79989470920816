<template>
    <v-file-input v-model="file" v-bind="$attrs" @change="emit" />
</template>

<script>
import api from "@/api";
export default {
    props: {
        value: { type: Object, default: () => ({}) }, // product
    },
    data: () => ({
        file: null,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        "value.image__download"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.file = this.value.image__download;
            console.log("isCalled?");
        },
        emit() {
            this.$emit("input", { ...this.value, image__download: this.file });
        },

        async save() {
            let { _image__download } = this.value;

            if (this.file instanceof File) {
                this.file = (await api.console.commonFiles.post({ directory: this.value?._id, index: "image__download" }, this.file))?.file;
            }
            if (!!_image__download && _image__download != this.file?._id) {
                await api.console.commonFiles.delete({ _id: _image__download });
            }
            _image__download = this.file?._id || null;

            const { product } = await api.console.shop.products.put({ _id: this.value?._id, _image__download });
            this.$emit("input", product);
        },

        showFileDialog() {
            this.$refs["file-input"]?.$el.getElementsByTagName("input")?.[0]?.click?.();
        },
    },
};
</script>

<style></style>

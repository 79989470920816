var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1240",
      "width": "100%"
    }
  }, [_c('list-heading', {
    staticClass: "mt-8",
    attrs: {
      "title": "SNS Accounts"
    }
  }), _c('v-data-table', _vm._b({
    ref: "data-table",
    staticClass: "my-4",
    attrs: {
      "hide-default-footer": "",
      "disable-sort": "",
      "disable-filtering": "",
      "disable-pagination": ""
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (props) {
        return [_vm.$refs['data-table'] ? _c('draggable', {
          attrs: {
            "tag": "tbody",
            "list": props.items,
            "sort": !_vm.loading
          },
          on: {
            "change": _vm.setNewIndex
          }
        }, [_c('v-nodes', {
          attrs: {
            "vnodes": _vm.$refs['data-table'].genItems(props.items, props)
          }
        })], 1) : _vm._e()];
      }
    }, _vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.src`,
      fn: function (_ref2) {
        var value = _ref2.value;
        return [_c('image-popup', {
          attrs: {
            "size": "40",
            "src": value
          }
        })];
      }
    }, {
      key: `item.shows`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-row', {
          attrs: {
            "justify": "center",
            "align": "center"
          }
        }, [_c('v-col', {
          staticClass: "pa-0",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-switch', _vm._b({
          on: {
            "change": function (shows) {
              return _vm.update({
                _id: item._id,
                shows
              });
            }
          },
          model: {
            value: item.shows,
            callback: function ($$v) {
              _vm.$set(item, "shows", $$v);
            },
            expression: "item.shows"
          }
        }, 'v-switch', Object.assign({}, _vm.switchAttrs, {
          loading: _vm.loading
        }), false))], 1)], 1)];
      }
    }, {
      key: `item.isContentsUpdateScheduled`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-row', {
          attrs: {
            "justify": "center",
            "align": "center"
          }
        }, [_c('v-col', {
          staticClass: "pa-0",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-switch', _vm._b({
          on: {
            "change": function (isContentsUpdateScheduled) {
              return _vm.update({
                _id: item._id,
                isContentsUpdateScheduled
              });
            }
          },
          model: {
            value: item.isContentsUpdateScheduled,
            callback: function ($$v) {
              _vm.$set(item, "isContentsUpdateScheduled", $$v);
            },
            expression: "item.isContentsUpdateScheduled"
          }
        }, 'v-switch', Object.assign({}, _vm.switchAttrs, {
          loading: _vm.loading
        }), false))], 1)], 1)];
      }
    }, {
      key: `item.updateContents`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('v-btn', _vm._b({
          attrs: {
            "small": "",
            "text": "",
            "icon": ""
          },
          on: {
            "click": function ($event) {
              return _vm.updateContents(item);
            }
          }
        }, 'v-btn', {
          loading: _vm.loading
        }, false), [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-cloud-download")])], 1)];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('account-view', _vm._b({
          on: {
            "input": _vm.update
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref7) {
              var attrs = _ref7.attrs,
                on = _ref7.on;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "small": "",
                  "text": "",
                  "icon": ""
                }
              }, 'v-btn', Object.assign({}, attrs, {
                loading: _vm.loading
              }), false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("mdi-pencil")])], 1)];
            }
          }], null, true)
        }, 'account-view', item, false)), _c('v-btn', _vm._b({
          attrs: {
            "small": "",
            "text": "",
            "icon": ""
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, 'v-btn', {
          loading: _vm.loading
        }, false), [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-delete")])], 1)];
      }
    }, {
      key: "footer",
      fn: function () {
        return [_c('v-divider'), _c('v-pagination', {
          staticClass: "py-3",
          attrs: {
            "value": _vm.page,
            "length": _vm.pageCount,
            "total-visible": 11,
            "color": "primary"
          },
          on: {
            "input": function (page) {
              return _vm.$router.push({
                query: Object.assign({}, _vm.$route.query, {
                  page
                })
              });
            }
          }
        })];
      },
      proxy: true
    }], null, true)
  }, 'v-data-table', {
    loading: _vm.loading,
    headers: _vm.headers,
    items: _vm.items
  }, false)), _c('account-view', {
    on: {
      "input": _vm.update
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref8) {
        var attrs = _ref8.attrs,
          on = _ref8.on;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "x-large": "",
            "fab": "",
            "color": "primary",
            "fixed": "",
            "right": "",
            "bottom": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-pencil")])], 1)];
      }
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
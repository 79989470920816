<template>
    <v-card tile flat>
        <list-search title="필터" hideAction v-bind="{ showsSearch, loading }" class="mt-0 mb-0">
            <v-row class="ma-n2">
                <v-col cols="4" class="pa-2">
                    <v-select v-model="filter.type" label="유형" :items="zoneTypes" v-bind="inputAttrs" />
                </v-col>
                <v-col cols="4" class="pa-2">
                    <v-select v-model="filter._continent" label="대륙" :items="continents" item-text="name" item-value="_id" v-bind="inputAttrs" @change="filter._country = null" />
                </v-col>
                <v-col cols="4" class="pa-2">
                    <v-select v-model="filter._country" label="국가" :items="countries" item-text="name" item-value="_id" v-bind="inputAttrs" :disabled="countries.length == 0" />
                </v-col>
            </v-row>
        </list-search>
        <v-divider v-if="showsSearch" />
        <v-expand-transition>
            <v-data-table v-show="!loading" v-bind="{ items, headers, page }" multi-sort>
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.actions`]="{ item }">
                    <zone-view :value="item" v-bind="{ zones }" @input="save">
                        <template #activator="{ attrs, on }">
                            <v-btn x-small text icon v-bind="attrs" v-on="on" color="primary"><v-icon>mdi-pencil</v-icon></v-btn>
                        </template>
                    </zone-view>
                    <v-btn x-small text icon color="red" @click="remove(item)">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </template>
                <template #footer>
                    <v-divider />
                    <v-row class="mx-0">
                        <v-spacer />
                        <v-col cols="auto">
                            <zone-view v-bind="{ zones }" @input="save">
                                <template #activator="{ attrs, on }">
                                    <v-btn large text icon color="primary" v-bind="attrs" v-on="on">
                                        <v-icon large>mdi-plus</v-icon>
                                    </v-btn>
                                </template>
                            </zone-view>
                        </v-col>
                    </v-row>
                </template>
            </v-data-table>
        </v-expand-transition>
        <v-expand-transition>
            <v-sheet v-show="loading" height="50vh">
                <v-overlay light color="white" absolute>
                    <v-progress-circular color="black" size="100" width="5" indeterminate />
                </v-overlay>
            </v-sheet>
        </v-expand-transition>
    </v-card>
</template>

<script>
import ZoneView from "./zone-view.vue";
import ListSearch from "../dumb/list-search.vue";

import api from "@/api";
import { inputAttrs, ZONE_TYPES } from "@/assets/variables";
const zoneTypes = [{ value: null, text: "전체" }].concat(Object.values(ZONE_TYPES));

const formatter = (value) => value || "-";
const headers = [
    { width: 60, text: "#", value: "index", sortable: false },
    { width: 100, text: "유형", value: "typeText", formatter },
    { width: 130, text: "대륙명", value: "continentName", formatter },
    { width: 160, text: "국가명", value: "countryName", cellClass: "text-truncate", formatter },
    { width: 160, text: "지역명", value: "regionName", cellClass: "text-truncate", formatter },
    { width: 110, text: "", value: "actions", formatter, align: "end", sortable: false },
];

export default {
    components: {
        ZoneView,
        ListSearch,
    },
    props: {
        showsSearch: { type: Boolean, default: false },
    },
    data() {
        return {
            page: +this.$route.query.page || 1,
            filter: {
                type: this.$route.query.type || null,
                _country: this.$route.query._country || null,
                _continent: this.$route.query._continent || null,
            },

            zones: [],
            zoneTypes,
            inputAttrs,

            loading: false,
        };
    },
    computed: {
        tab() {
            return this.$route.path.match(/\/console\/([a-zA-Z\-]*)/)?.[1] || "zones";
        },
        items() {
            const { type, _country, _continent } = this.filter || {};

            return (this.zones || [])
                .filter((zone) => {
                    const isSameType = type ? zone.type == type : true;
                    const isSameContinent = _continent ? zone._continent == _continent || zone._id == _continent : true;
                    const isSameCountry = _country ? zone._country == _country || zone._id == _country : true;
                    return isSameType && isSameContinent && isSameCountry;
                })
                .map((item, index, items) => ({ ...item, index: items.length - index }));
        },
        continents() {
            return [{ _id: null, name: "전체" }].concat((this.zones || []).filter(({ type }) => type == ZONE_TYPES.CONTINENT.value).sort((a, b) => a.name?.localeCompare?.(b.name)));
        },
        countries() {
            if (!this.filter?._continent) return [];
            return [{ _id: null, name: "전체" }].concat((this.zones || []).filter(({ type, _continent }) => type == ZONE_TYPES.COUNTRY.value && _continent == this.filter?._continent).sort((a, b) => a.name?.localeCompare?.(b.name)));
        },
        headers() {
            return headers;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        $route() {
            this.search();
        },
    },
    methods: {
        async init() {
            const path = `/console/${this.tab}`;
            if (this.$route.path != path) this.$router.replace({ path });

            this.search();
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                var { zones } = await api.console.shop.zones.gets();
                this.zones = zones;
            } finally {
                this.loading = false;
            }
        },
        async remove({ _id, typeText = "", fullName = "" } = {}) {
            const go = confirm(`지역을 삭제하시겠습니까?\n\n유형:   ${typeText}\n이름:   ${fullName}`);
            if (!go) return;

            await api.console.shop.zones.delete({ _id });
            this.zones = this.zones.filter((zone) => zone._id !== _id);
            alert("지역이 삭제되었습니다.");
        },
        async save(item) {
            const { zone } = item?._id ? await api.console.shop.zones.put(item) : await api.console.shop.zones.post(item);
            this.update(zone);
        },
        async update(item) {
            const index = this.zones.findIndex(({ _id }) => _id == item._id);
            if (index > -1) this.zones.splice(index, 1, item);
            else {
                this.zones = [item, ...this.zones];
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep td.text-truncate {
    max-width: 0px;
}
</style>

<template>
    <v-sheet tile>
        <v-layout align-center class="grey lighten-4">
            <v-card-subtitle class="font-weight-bold"> 도큐먼트 </v-card-subtitle>
            <v-spacer />
            <document-view @input="pushItem">
                <template #activator="{ attrs, on }">
                    <v-btn text icon tile color="primary" title="신규 생성" class="ml-3" v-bind="attrs" v-on="on">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>
            </document-view>
        </v-layout>
        <v-divider />
        <v-card-text>
            <draggable v-model="selected" class="row" draggable=".draggable-item" @change="emit">
                <v-col cols="12" sm="6" v-for="document in selected" :key="document._id" class="draggable-item">
                    <v-card outlined tile>
                        <v-card-title>
                            {{ document.name }}
                            <v-spacer />
                            <v-btn text icon @click="pullItem(document)"> <v-icon>mdi-close</v-icon> </v-btn>
                        </v-card-title>
                        <v-card-text>{{ document.desc }}</v-card-text>
                        <v-card-actions> <v-btn color="primary" download :href="document.url">Download</v-btn> </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="12">
                    <v-dialog width="240">
                        <template #activator="{ attrs, on }">
                            <v-card outlined tile>
                                <v-btn text block v-bind="attrs" v-on="on"> 목록에서 추가하기 </v-btn>
                            </v-card>
                        </template>
                        <v-select v-model="selected" multiple :items="documents" item-text="name" item-value="_id" return-object solo hide-details @input="emit"></v-select>
                    </v-dialog>
                </v-col>
            </draggable>
        </v-card-text>
    </v-sheet>
</template>

<script>
import Draggable from "vuedraggable";
import DocumentView from "../documents/document-view.vue";

export default {
    components: {
        Draggable,
        DocumentView,
    },
    props: {
        value: { type: Array, default: () => [] }, // product.documents
        documents: { type: Array, default: () => [] },
    },
    data: () => ({
        selected: [],
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.selected = [...this.value];
        },
        emit() {
            this.$emit("input", this.selected);
        },
        pushItem(item) {
            this.selected.push(item);
            this.emit();
        },
        pullItem(item) {
            this.selected = this.selected.filter(({ _id }) => _id != item._id);
            this.emit();
        },
    },
};
</script>

<style>
</style>
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "720",
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', {
    staticClass: "d-flex flex-column",
    attrs: {
      "min-height": "480"
    }
  }, [_c('v-fade-transition', [_c('v-overlay', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.branch,
      expression: "!branch"
    }],
    attrs: {
      "absolute": "",
      "light": "",
      "color": "white"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": "100",
      "width": "5"
    }
  })], 1)], 1), _c('v-card-title', [_vm._v(" " + _vm._s(_vm.title) + " "), _c('v-spacer'), _vm._v(" "), _c('v-btn', {
    attrs: {
      "text": "",
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), !!_vm.branch ? _c('v-card-text', {
    staticClass: "pa-0",
    staticStyle: {
      "max-height": "calc(90vh - 120px)",
      "overflow-y": "auto"
    }
  }, [_c('v-divider'), _c('v-card-subtitle', {
    staticClass: "grey lighten-4"
  }, [_vm._v("계정")]), _c('v-divider'), _c('v-row', {
    staticClass: "ma-0 px-2"
  }, [_c('v-col', {
    staticClass: "pa-2"
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "ID"
    },
    model: {
      value: _vm.branch.username,
      callback: function ($$v) {
        _vm.$set(_vm.branch, "username", $$v);
      },
      expression: "branch.username"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2"
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "PW",
      "type": "password",
      "autocomplete": "new-password"
    },
    model: {
      value: _vm.branch.password,
      callback: function ($$v) {
        _vm.$set(_vm.branch, "password", $$v);
      },
      expression: "branch.password"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1)], 1), _c('v-divider'), _c('v-card-subtitle', {
    staticClass: "grey lighten-4"
  }, [_vm._v("지사")]), _c('v-divider'), _c('v-row', {
    staticClass: "ma-0 px-2"
  }, [_c('v-col', {
    staticClass: "pa-2"
  }, [_c('v-select', _vm._b({
    attrs: {
      "label": "그룹",
      "items": _vm.branchGroups,
      "placeholder": "그룹을 선택하세요"
    },
    model: {
      value: _vm.branch.company.group,
      callback: function ($$v) {
        _vm.$set(_vm.branch.company, "group", $$v);
      },
      expression: "branch.company.group"
    }
  }, 'v-select', _vm.inputAttrs, false))], 1)], 1), _c('v-row', {
    staticClass: "ma-0 px-2"
  }, [_c('v-col', {
    staticClass: "pa-2"
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "Company Name"
    },
    model: {
      value: _vm.branch.company.name,
      callback: function ($$v) {
        _vm.$set(_vm.branch.company, "name", $$v);
      },
      expression: "branch.company.name"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2"
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "Contact Person"
    },
    model: {
      value: _vm.branch.contactPerson.name,
      callback: function ($$v) {
        _vm.$set(_vm.branch.contactPerson, "name", $$v);
      },
      expression: "branch.contactPerson.name"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1)], 1), _c('v-row', {
    staticClass: "ma-0 px-2"
  }, [_c('v-col', {
    staticClass: "pa-2"
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "TEL"
    },
    model: {
      value: _vm.branch.company.phone,
      callback: function ($$v) {
        _vm.$set(_vm.branch.company, "phone", $$v);
      },
      expression: "branch.company.phone"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2"
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "FAX"
    },
    model: {
      value: _vm.branch.company.fax,
      callback: function ($$v) {
        _vm.$set(_vm.branch.company, "fax", $$v);
      },
      expression: "branch.company.fax"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1)], 1), _c('v-row', {
    staticClass: "ma-0 px-2"
  }, [_c('v-col', {
    staticClass: "pa-2"
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "EMAIL"
    },
    model: {
      value: _vm.branch.company.email,
      callback: function ($$v) {
        _vm.$set(_vm.branch.company, "email", $$v);
      },
      expression: "branch.company.email"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1)], 1), _c('v-row', {
    staticClass: "ma-0 px-2"
  }, [_c('v-col', {
    staticClass: "pa-2"
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "ADDRESS"
    },
    model: {
      value: _vm.branch.company.address,
      callback: function ($$v) {
        _vm.$set(_vm.branch.company, "address", $$v);
      },
      expression: "branch.company.address"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1)], 1), _c('v-divider'), _c('v-card-subtitle', {
    staticClass: "grey lighten-4"
  }, [_vm._v("웹사이트")]), _c('v-divider'), _c('branch-view-websites', {
    model: {
      value: _vm.branch.company.websites,
      callback: function ($$v) {
        _vm.$set(_vm.branch.company, "websites", $$v);
      },
      expression: "branch.company.websites"
    }
  }), _c('v-divider'), _c('v-card-subtitle', {
    staticClass: "grey lighten-4"
  }, [_vm._v("상세설명")]), _c('v-divider'), _c('naver-smarteditor', {
    model: {
      value: _vm.branch.company.desc,
      callback: function ($$v) {
        _vm.$set(_vm.branch.company, "desc", $$v);
      },
      expression: "branch.company.desc"
    }
  }), _c('v-divider'), _c('v-card-subtitle', {
    staticClass: "grey lighten-4"
  }, [_vm._v("지역")]), _c('v-divider'), _c('branch-view-zones', _vm._b({
    model: {
      value: _vm.branch._zone,
      callback: function ($$v) {
        _vm.$set(_vm.branch, "_zone", $$v);
      },
      expression: "branch._zone"
    }
  }, 'branch-view-zones', {
    zones: _vm.zones
  }, false))], 1) : _vm._e(), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-edit-dialog :return-value.sync="form.index" large cancel-text="취소" save-text="변경" v-on="{ save }">
        <slot />
        <template #input> <v-currency-field v-model="form.index" label="노출순서" placeholder="999" clearable class="mt-3" v-bind="inputAttrs" /> </template>
    </v-edit-dialog>
</template>

<script>
import { inputAttrs } from "@/assets/variables";

export default {
    props: {
        value: { type: Object, default: () => ({}) },
    },
    data: () => ({
        form: {},

        inputAttrs,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = JSON.parse(JSON.stringify(this.value));
        },
        save() {
            let { _id, index } = this.form;
            index = index || 999;
            let form = { _id, index };

            this.$emit("update", form);
        },
    },
};
</script>

<style>
</style>
<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="800" width="100%" height="100%" class="px-3">
            <div class="headline text-start mt-8">{{ headline }}</div>

            <v-card class="mt-6" v-if="isCode(['product-inquiry']) && isUserConsole">
                <v-row class="mx-0" align="baseline">
                    <v-col cols="2" class="subtitle-2"> 담당지사 </v-col>
                    <v-col cols="auto" align-self="stretch" class="pa-0"> <v-divider vertical /> </v-col>
                    <v-col class="subtitle-2"> {{ form?.meta?.assignedBranches?.map?.(({ company }) => company?.name)?.join?.(", ") }} </v-col>
                    <v-col cols="auto" class="pa-0">
                        <assigned-branches-view v-model="form">
                            <template #activator="{ attrs, on }"> <v-btn plain text color="primary" v-bind="attrs" v-on="on">변경하기</v-btn> </template>
                        </assigned-branches-view>
                    </v-col>
                </v-row>
            </v-card>
            <v-card class="mt-6">
                <v-card-title class="subtitle-1">기본정보</v-card-title>
                <v-divider />
                <v-card-text class="pa-0">
                    <v-row class="mx-n4 px-5">
                        <v-col cols="2"> 작성자 </v-col> <v-col cols="auto" class="pa-0"> <v-divider vertical /> </v-col> <v-col>{{ form.writer.name || "-" }} </v-col> <v-col cols="auto" class="pa-0"> <v-divider vertical /> </v-col> <v-col cols="2"> 작성일 </v-col> <v-col cols="auto" class="pa-0"> <v-divider vertical /> </v-col> <v-col>{{ form?.createdAt?.toDateTime?.() || "-" }} </v-col>
                    </v-row>
                    <v-divider />
                    <v-row class="mx-n4 px-5">
                        <v-col cols="2"> 연락처 </v-col> <v-col cols="auto" class="pa-0"> <v-divider vertical /> </v-col> <v-col>{{ form?.writer?.phone?.phoneNumberFormat?.() || form?.writer?.phone || "-" }} </v-col> <v-col cols="auto" class="pa-0"> <v-divider vertical /> </v-col> <v-col cols="2"> 이메일 </v-col> <v-col cols="auto" class="pa-0"> <v-divider vertical /> </v-col> <v-col>{{ form.writer.email || "-" }} </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-6" v-if="!isCode(['product-inquiry'])">
                <v-card-text>
                    <v-text-field :value="form.meta.type" label="유형" v-bind="inputAttrs" />
                </v-card-text>
            </v-card>

            <v-card class="mt-6" v-if="!isCode(['product-inquiry'])">
                <v-card-title class="subtitle-1">문의 상세</v-card-title>
                <v-divider />
                <v-card-text>
                    <v-text-field :value="form.subject" label="제목" v-bind="inputAttrs" readonly class="mb-6" />
                    <v-textarea :value="form.content" label="내용" v-bind="inputAttrs" readonly />
                </v-card-text>
            </v-card>

            <v-card class="mt-6" v-if="!isCode(['product-inquiry'])">
                <v-card-title class="subtitle-1">내용</v-card-title>
                <v-divider />
                <v-card-text>
                    <v-textarea :value="form.content" hide-details dense auto-grow />
                </v-card-text>
            </v-card>

            <v-card class="mt-6" v-if="isCode(['product-inquiry'])">
                <v-card-title class="subtitle-1">부가정보</v-card-title>
                <v-divider />
                <v-card-text class="pa-0">
                    <v-row class="mx-n4 px-5">
                        <v-col cols="2"> 구분 </v-col> <v-col cols="auto" class="pa-0"> <v-divider vertical /> </v-col> <v-col>{{ form.meta.isNewUser ? "New" : "Existing" }} </v-col> <v-col cols="auto" class="pa-0"> <v-divider vertical /> </v-col> <v-col cols="2"> 거래이력 </v-col> <v-col cols="auto" class="pa-0"> <v-divider vertical /> </v-col> <v-col>{{ form.meta.hasUsedDentium ? "Used or in use" : "Never Used" }} </v-col>
                    </v-row>
                    <v-divider />
                    <v-row class="mx-n4 px-5">
                        <v-col cols="2"> 관심제품 </v-col> <v-col cols="auto" class="pa-0"> <v-divider vertical /> </v-col> <v-col>{{ form?.meta?.categories?.map?.(({ name }) => name)?.join?.(", ") || "-" }} </v-col>
                    </v-row>
                    <v-divider />
                    <v-row class="mx-n4 px-5">
                        <v-col cols="2"> 제품명 </v-col> <v-col cols="auto" class="pa-0"> <v-divider vertical /> </v-col> <v-col>{{ form?.meta?.products?.map?.(({ name }) => name)?.join?.(", ") || "-" }} </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-6" v-if="isCode(['product-inquiry'])">
                <v-card-title class="subtitle-1">문의내용</v-card-title>
                <v-divider />
                <v-card-text class="pa-0">
                    <v-row class="mx-n4 px-5">
                        <v-col cols="2"> Inquiry </v-col> <v-col cols="auto" class="pa-0"> <v-divider vertical /> </v-col> <v-col class="white-space-pre-line">{{ form.content }}</v-col>
                    </v-row>
                    <v-divider />
                    <v-row class="mx-n4 px-5">
                        <v-col cols="2"> Other Message </v-col> <v-col cols="auto" class="pa-0"> <v-divider vertical /> </v-col> <v-col class="white-space-pre-line">{{ form.content }}</v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card v-if="isCode(['product-inquiry'])" class="mt-6">
                <v-card-title class="subtitle-1">답변</v-card-title>
                <v-divider />
                <v-card-text>
                    <naver-smarteditor v-model="form.reply" id="reply" rows="10"></naver-smarteditor>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-btn outlined color="grey" @click="form.reply = null">초기화</v-btn>
                    <v-spacer />
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-card-actions>
            </v-card>

            <v-row justify="center" v-if="!isCode(['product-inquiry'])">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import ImagePopup from "@/components/console/dumb/image-popup.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import AssignedBranchesView from "@/components/console/forms/assigned-branches-view.vue";
const inputAttrs = {
    dense: true,
    "hide-details": true,
    "persistent-placeholder": true,
};

export default {
    components: {
        ImagePopup,
        NaverSmarteditor,
        AssignedBranchesView,
    },
    data() {
        return {
            inputAttrs,
            ready: false,

            ///////////////////////////////////////////////
            // 게시글 정보
            ///////////////////////////////////////////////
            form: {
                code: this.$route.query.code,
                category: undefined,

                subject: undefined,
                content: undefined,
                summary: undefined,
                period: undefined,
                createdAt: undefined,

                sequence: 1,

                storeName: undefined,
                storePhone: undefined,
                storeEmail: undefined,

                sido: undefined,
                gugun: undefined,
                area: undefined,
                postcode: undefined,
                address1: undefined,
                address2: undefined,
                lat: undefined,
                lng: undefined,

                writer: {
                    name: undefined,
                    phone: undefined,
                    email: undefined,
                },
                meta: {},

                thumb: undefined,
            },
        };
    },
    computed: {
        headline() {
            if (this.form.code == "product-inquiry") return "프로덕트 문의 상세";
            return "폼메일 상세";
        },
        isUserConsole() {
            return (this.$store.state.payload?.scope || []).includes("console");
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                if (this.$route.params._form) {
                    var { form } = await api.console.forms.get({ _id: this.$route.params._form });
                    this.form = form;

                    if (form.thumb) this.form.thumb = await api.getResource(form.thumb);
                }

                this.ready = true;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        async save() {
            try {
                ////////////////////////////////////////////////////////////////
                // 1. 게시글 저장
                ////////////////////////////////////////////////////////////////
                var { form } = this.form._id ? await api.console.forms.put(this.form) : await api.console.forms.post(this.form);

                ////////////////////////////////////////////////////////////////
                // 2. 리소스 저장
                ////////////////////////////////////////////////////////////////
                if (this.form.thumb) await api.console.forms.postThumb(form, this.form.thumb);

                alert(this.form._id ? "수정되었습니다" : "저장되었습니다");

                this.$router.go(-1);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        createObjectURL(file) {
            try {
                return URL.createObjectURL(file);
            } catch (error) {
                return;
            }
        },

        isCode(codes = []) {
            return codes.includes(this.form.code);
        },
    },
};
</script>
<style lang="scss" scoped>
.white-space-pre-line {
    white-space: pre-line;
}
</style>

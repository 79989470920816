<template>
    <v-sheet>
        <v-card-subtitle class="d-flex caption font-weight-bold">
            강의
            <v-spacer />
            <lecture-unit-view @input="update">
                <template #activator="{ attrs, on }">
                    <v-btn x-small text icon color="primary" title="신규 생성" v-bind="attrs" v-on="on">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>
            </lecture-unit-view>
        </v-card-subtitle>
        <v-expand-transition>
            <v-card-text v-show="items.length">
                <v-list two-line class="unit-list" dense>
                    <draggable v-model="items">
                        <v-list-item v-for="(item, index) in items" :key="item.id" class="unit-item">
                            <v-list-item-avatar class="my-0"> {{ index + 1 }} </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                                <v-list-item-subtitle>{{ new Date(null, null, null, null, null, null, item.duration || 0).toTimeString().split(" ")[0] }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action class="my-0 mr-2">
                                <v-row no-gutters>
                                    <lecture-unit-view :value="item" v-bind="{ lecture }" @input="update" ref="unit-item">
                                        <template #activator="{ attrs, on }">
                                            <v-btn x-small text icon v-bind="attrs" v-on="on">
                                                <v-icon>mdi-pencil</v-icon>
                                            </v-btn>
                                        </template>
                                    </lecture-unit-view>
                                    <v-btn x-small text icon @click="remove(item)">
                                        <v-icon> mdi-delete</v-icon>
                                    </v-btn>
                                </v-row>
                            </v-list-item-action>
                        </v-list-item>
                    </draggable>
                </v-list>
            </v-card-text>
        </v-expand-transition>
    </v-sheet>
</template>

<script>
import Draggable from "vuedraggable";
import LectureUnitView from "./lecture-unit-view.vue";
export default {
    components: {
        Draggable,
        LectureUnitView,
    },
    props: {
        value: { type: Array, defatul: () => [] },
        lecture: { type: Object, defatul: () => ({}) },
    },
    data: () => ({
        items: [],
    }),
    mounted() {
        this.items = this.value;
    },
    watch: {
        value() {
            this.items = this.value;
        },
    },
    methods: {
        update(item) {
            const index = this.items.findIndex(({ id }) => id == item.id);
            if (index > -1) this.items.splice(index, 1, item);
            else this.items.push(item);
            this.$emit("input", this.items);
        },
        remove(item) {
            const index = this.items.findIndex(({ id }) => id == item.id);
            if (index > -1) this.items.splice(index, 1);
            this.$emit("input", this.items);
        },
        async save() {
            for (const ref of this.$refs["unit-item"] || []) {
                await ref?.save?.();
            }
            this.$emit("input", this.items);
        },
    },
};
</script>

<style lang="scss" scoped>
.unit-list {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0;
}
.unit-item {
    padding: 0;

    &:not(:last-of-type) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
}
</style>

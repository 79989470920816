var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "flat": "",
      "tile": ""
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', [_c('branch-assignment-zones', _vm._b({
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.branches,
      callback: function ($$v) {
        _vm.branches = $$v;
      },
      expression: "branches"
    }
  }, 'branch-assignment-zones', {
    zones: _vm.zones,
    mappedZones: _vm.mappedZones
  }, false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', [_c('branch-assignment-actions', _vm._b({
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.branches,
      callback: function ($$v) {
        _vm.branches = $$v;
      },
      expression: "branches"
    }
  }, 'branch-assignment-actions', {
    zones: _vm.zones,
    mappedZones: _vm.mappedZones
  }, false))], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-layout justify-center>
        <v-responsive max-width="480" width="100%">
            <list-heading title="SMTP" />

            <v-card outlined v-bind="{ loading }">
                <v-card-subtitle class="font-weight-bold"> Sender Account (Daou Office) </v-card-subtitle>
                <v-divider />
                <v-card-text class="py-0">
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="setting.smtp.username" label="username" v-bind="inputAttrs" />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="setting.smtp.password" type="password" label="password" v-bind="inputAttrs" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider />
                <v-card-subtitle class="font-weight-bold"> Receivers (E-Mail Address) </v-card-subtitle>
                <v-divider />
                <v-card-text class="py-0">
                    <v-row>
                        <v-col cols="12">
                            <v-combobox v-model="setting.smtp.receivers" multiple chips append-icon="" :dense="false" v-bind="inputAttrs">
                                <template #selection="{ item, parent }">
                                    <v-chip outlined label close @click:close="parent.selectItem(item)">{{ item }}</v-chip>
                                </template>
                            </v-combobox>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider />
                <v-card-actions> <v-spacer /> <v-btn text color="primary" v-bind="{ loading }" @click="save"> Save </v-btn> </v-card-actions>
            </v-card>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";

import CryptoAES from "@/plugins/crypto-aes";
import ListHeading from "@/components/console/dumb/list-heading.vue";

import { inputAttrs } from "@/assets/variables";

export default {
    components: {
        ListHeading,
    },
    created() {
        this.init();
    },
    data: () => ({
        setting: {
            smtp: {},
        },

        loading: false,

        inputAttrs,
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            await this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                var { setting } = await api.console.setting.get();
                if (setting?.smtp?.password) setting.smtp.password = CryptoAES.decrypt(setting.smtp.password);
                this.setting = setting;
            } finally {
                this.loading = false;
            }
        },

        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { _id, smtp: { ...smtp } = {} } = this.setting;

                if (smtp.password) smtp.password = CryptoAES.encrypt(smtp.password);
                const form = { _id, smtp };

                await api.console.setting.put(form);

                alert("저장되었습니다.");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

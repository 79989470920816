var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "480"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.title) + " "), _c('v-btn', {
    attrs: {
      "text": "",
      "icon": "",
      "absolute": "",
      "right": ""
    },
    on: {
      "click": function ($event) {
        _vm.show = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-divider'), _c('v-text-field', _vm._b({
    staticClass: "ma-3",
    attrs: {
      "label": "제목"
    },
    model: {
      value: _vm.item.name,
      callback: function ($$v) {
        _vm.$set(_vm.item, "name", $$v);
      },
      expression: "item.name"
    }
  }, 'v-text-field', _vm.inputAttrs, false)), _c('v-text-field', _vm._b({
    staticClass: "ma-3",
    attrs: {
      "label": "링크"
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('v-btn', {
          staticClass: "mt-n2",
          attrs: {
            "text": "",
            "icon": "",
            "height": "40",
            "href": _vm.item.href,
            "disabled": !_vm.item.href,
            "target": "_blank"
          }
        }, [_c('v-icon', [_vm._v("mdi-open-in-new")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.item.href,
      callback: function ($$v) {
        _vm.$set(_vm.item, "href", $$v);
      },
      expression: "item.href"
    }
  }, 'v-text-field', _vm.inputAttrs, false)), _c('v-select', _vm._b({
    staticClass: "ma-3",
    attrs: {
      "label": "상태",
      "items": _vm.liveStates
    },
    model: {
      value: _vm.item.state,
      callback: function ($$v) {
        _vm.$set(_vm.item, "state", $$v);
      },
      expression: "item.state"
    }
  }, 'v-select', _vm.inputAttrs, false)), _c('v-text-field', _vm._b({
    staticClass: "ma-3",
    attrs: {
      "label": "진행일",
      "type": "date"
    },
    model: {
      value: _vm.item.scheduledAt,
      callback: function ($$v) {
        _vm.$set(_vm.item, "scheduledAt", $$v);
      },
      expression: "item.scheduledAt"
    }
  }, 'v-text-field', _vm.inputAttrs, false)), _c('v-divider'), _c('div', {
    staticClass: "subtitle-2 font-weight-bold ma-3"
  }, [_c('span', [_vm._v("썸네일")]), _c('span', {
    staticClass: "pl-1 font-weight-medium grey--text"
  }, [_vm._v("(280*156px)")])]), _c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var hover = _ref2.hover;
        return [_c('v-img', _vm._b({
          staticClass: "ma-3",
          attrs: {
            "aspect-ratio": 16 / 9
          }
        }, 'v-img', {
          src: _vm.src
        }, false), [_c('v-fade-transition', [_c('v-overlay', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: hover && !!_vm.src,
            expression: "hover && !!src"
          }],
          attrs: {
            "absolute": "",
            "z-index": "0"
          }
        }, [_c('v-btn', {
          attrs: {
            "fab": "",
            "color": "primary"
          },
          on: {
            "click": _vm.showFileDialog
          }
        }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1), _c('v-btn', {
          staticClass: "ml-2",
          attrs: {
            "fab": "",
            "color": "red"
          },
          on: {
            "click": function ($event) {
              _vm.item.thumb = null;
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)], 1)], 1), _c('v-fade-transition', [_c('v-btn', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.src,
            expression: "!src"
          }],
          attrs: {
            "tile": "",
            "height": "100%",
            "width": "100%"
          },
          on: {
            "click": _vm.showFileDialog
          }
        }, [_c('v-icon', {
          attrs: {
            "x-large": ""
          }
        }, [_vm._v("mdi-image-broken")])], 1)], 1)], 1)];
      }
    }])
  }), _c('v-file-input', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "file-input",
    model: {
      value: _vm.item.thumb,
      callback: function ($$v) {
        _vm.$set(_vm.item, "thumb", $$v);
      },
      expression: "item.thumb"
    }
  }), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "grey"
    },
    on: {
      "click": _vm.init
    }
  }, [_vm._v("초기화")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
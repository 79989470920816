<template>
    <v-layout justify-center>
        <v-responsive max-width="1080" width="100%" height="100%" class="px-1">
            <div class="headline text-start mt-8">
                <span v-if="user._id">User Item</span>
                <span v-else>Creat User Item</span>
            </div>

            <v-card class="my-4">
                <v-row no-gutters>
                    <v-col cols="12" md="9">
                        <v-card-subtitle class="font-weight-bold grey lighten-4">Account Info.</v-card-subtitle>
                        <v-divider />
                        <v-row class="ma-1">
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="user.username" label="Username" persistent-placeholder hide-details readonly />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="user.password" label="Password" persistent-placeholder hide-details />
                            </v-col>
                        </v-row>

                        <v-divider />
                        <v-card-subtitle class="font-weight-bold grey lighten-4">Base Info.</v-card-subtitle>
                        <v-divider />

                        <template v-if="user.type == PERSON.value">
                            <form-name-input v-model="user" persistent-placeholder hide-details class="ma-1" />
                            <v-divider />
                            <v-row class="ma-1">
                                <v-col cols="12" sm="6">
                                    <v-text-field v-model="user.email" label="E-mail Address" persistent-placeholder hide-details />
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <form-phone-input v-model="user" />
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-select v-model="user.occupation" :items="occupations" label="Speciality" persistent-placeholder hide-details />
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field v-model="user.businessLicenseNumber" label="Business License Number" persistent-placeholder hide-details />
                                </v-col>
                            </v-row>
                        </template>

                        <v-divider />
                        <v-card-subtitle class="font-weight-bold grey lighten-4">Location Info.</v-card-subtitle>
                        <v-divider />

                        <template v-if="user.type == PERSON.value">
                            <branch-view-zones v-model="user._zone" v-bind="{ zones, inputAttrs: { outline: false, 'persistent-placeholder': true, 'hide-details': true } }" cols="6" sm="6" className="ma-1 px-0">
                                <v-col cols="12" sm="6">
                                    <v-text-field v-model="user.address.state" label="State/Province" persistent-placeholder hide-details />
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field v-model="user.address.city" label="City" persistent-placeholder hide-details />
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field v-model="user.address.street" label="Street Address" persistent-placeholder hide-details />
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field v-model="user.address.zip" label="Zip/Postal Code" persistent-placeholder hide-details />
                                </v-col>
                            </branch-view-zones>
                        </template>

                        <template v-if="user.type == BRANCH.value">
                            <v-divider />
                            <v-card-subtitle class="font-weight-bold grey lighten-4">Contact Person</v-card-subtitle>
                            <v-divider />

                            <v-row class="ma-2 my-4">
                                <v-col cols="12" sm="4">
                                    <v-text-field v-model="user.workerName" label="Mame" persistent-placeholder hide-details />
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-text-field v-model="user.workerPhone" label="TEL" persistent-placeholder hide-details />
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-text-field v-model="user.workerEmail" label="E-mail" persistent-placeholder hide-details />
                                </v-col>
                            </v-row>
                        </template>
                    </v-col>
                    <v-col cols="auto">
                        <v-divider class="d-none d-md-block" vertical />
                        <v-divider class="d-block d-md-none" />
                    </v-col>
                    <v-col cols="12" md="">
                        <v-card-subtitle class="font-weight-bold grey lighten-4">Memo</v-card-subtitle>
                        <v-divider />
                        <v-card-text>
                            <v-textarea v-if="user.type == PERSON.value" v-model="user.note" hide-details rows="7" auto-grow class="pa-0 ma-0" />
                        </v-card-text>
                    </v-col>
                </v-row>
                <v-divider />

                <v-card-actions class="align-center grey lighten-4">
                    <v-switch v-model="user.isWarning" color="red lighten-1" dense inset hide-details class="my-0 mx-4 pa-0">
                        <span slot="label" class="subtitle-2 font-weight-bold"> User to Watch out </span>
                    </v-switch>

                    <v-spacer />
                    <v-btn color="primary" @click="save">Save</v-btn>
                    <user-withdraw v-model="user" @input="$router.go(-1)">
                        <template #activator="{ attrs, on }">
                            <v-btn color="red darken-1" dark class="ml-2" v-bind="attrs" v-on="on">Withdraw</v-btn>
                        </template>
                    </user-withdraw>
                </v-card-actions>
            </v-card>

            <v-row justify="center" class="ma-2 my-4">
                <v-col md="auto" sm="auto"> </v-col>
            </v-row>
        </v-responsive>
        <daum-postcode ref="DaumPostcode" @input="setAddress"></daum-postcode>
    </v-layout>
</template>

<script>
import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";
import UserWithdraw from "@/components/console/user/user-withdraw.vue";

import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import { PERSON_OCCUPATIONS, USER_TYPES } from "@/assets/variables";
import BranchViewZones from "@/components/console/branches/branch-view-zones.vue";
import FormPhoneInput from "@/components/client/join/skin/PERSON/form-phone-input.vue";
import FormNameInput from "@/components/client/join/skin/PERSON/form-name-input.vue";

const { BRANCH, PERSON } = USER_TYPES;
const occupations = Object.values(PERSON_OCCUPATIONS);

export default {
    components: {
        DaumPostcode,
        UserWithdraw,
        BranchViewZones,
        FormPhoneInput,
        FormNameInput,
    },
    data() {
        return {
            levels: [],
            zones: [],
            user: {
                _id: this.$route.params._user,
            },

            window,
            PERSON,
            BRANCH,
            occupations,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            var { levels } = await api.console.levels.gets();
            this.levels = levels;

            const { zones } = await api.console.shop.zones.gets({ params: { sort: { fullName: 1 } } });
            this.zones = (zones || []).sort((a, b) => a?.fullName?.localeCompare?.(b?.fullName));

            if (this.user._id) {
                var { user } = await api.console.users.get({ _id: this.$route.params._user });
                if (user?.bornAt) user.bornAt = user.bornAt.toDate();
                if (user?.foundAt) user.foundAt = user.foundAt.toDate();
                this.user = user;
            }
        },

        validate() {
            try {
                // if(!this.user.name) throw new Error("담당자 이름을 입력해주세요");
                // if(!this.user.email) throw new Error("담당자 이메일을 입력해주세요");

                return true;
            } catch (error) {
                alert(error.message);
            }
            return false;
        },

        async save() {
            if (this.validate()) {
                if (this.user._id) {
                    await api.console.users.put({
                        ...this.user,
                        password: this.user.password ? CryptoAES.encrypt(this.user.password) : undefined,
                    });
                } else {
                    await api.console.users.post({
                        ...this.user,
                        username: this.user.email,
                        password: this.user.password ? CryptoAES.encrypt(this.user.password) : undefined,
                    });
                }

                alert("저장되었습니다");
                this.$router.push(`/console/users`);
            }
        },

        setAddress({ postcode, address }) {
            this.user.postcode = postcode;
            this.user.address1 = address;
        },
    },
};
</script>

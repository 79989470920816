<template>
    <v-card-text>
        <v-hover v-slot="{ hover }">
            <v-responsive :aspect-ratio="16 / 9">
                <v-card tile flat height="100%">
                    <v-overlay v-if="src" absolute z-index="0" style="pointer-events:none">
                        <video v-bind="{ src }" height="200" />
                    </v-overlay>
                    <v-fade-transition>
                        <v-overlay v-show="hover && !!src" absolute z-index="0" color="black">
                            <v-btn x-large text icon color="primary" @click="showFileDialog">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn x-large text icon color="red" @click="remove({ withFile: true })">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-overlay>
                    </v-fade-transition>
                    <v-fade-transition>
                        <v-btn v-show="!src" tile height="100%" width="100%" @click="showFileDialog">
                            <v-icon x-large>mdi-video-off</v-icon>
                        </v-btn>
                    </v-fade-transition>
                </v-card>
            </v-responsive>
        </v-hover>
        <v-file-input v-model="file" v-show="false" accept="video/mp4,video/x-m4v,video/*" ref="file-input" />
    </v-card-text>
</template>

<script>
import api from "@/api";
export default {
    props: {
        value: { type: Object, default: () => ({}) }, // software
    },
    data: () => ({
        file: null,
    }),
    computed: {
        src() {
            if (!!this.file) return URL.createObjectURL(this.file);
            else return this.value?.video?.url;
        },
    },
    methods: {
        async remove({ withFile = false } = {}) {
            const { video, _video, ...software } = this.value;
            if (_video) {
                await api.console.commonFiles.delete({ _id: _video });
                this.$emit("input", software);
            }
            if (withFile) this.file = null;
        },

        async save() {
            if (!this.file) return;
            await this.remove();
            const { file } = await api.console.commonFiles.post({ directory: this.value?._id, index: "video" }, this.file);
            const { software } = await api.console.membership.softwares.put({ _id: this.value?._id, _video: file._id });
            this.$emit("input", software);
        },

        showFileDialog() {
            this.$refs["file-input"]?.$el.getElementsByTagName("input")?.[0]?.click?.();
        },
    },
};
</script>

<style></style>

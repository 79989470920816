<template>
    <v-card flat tile>
        <v-row no-gutters>
            <v-col>
                <branch-assignment-zones v-model="branches" v-bind="{ zones, mappedZones }" @input="emit" />
            </v-col>
            <v-col cols="auto"> <v-divider vertical /> </v-col>
            <v-col>
                <branch-assignment-actions v-model="branches" v-bind="{ zones, mappedZones }" @input="emit" />
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import Draggable from "vuedraggable";
import BranchAssignmentZones from "./branch-assignment-zones.vue";
import BranchAssignmentActions from "./branch-assignment-actions.vue";

import api from "@/api";

export default {
    components: {
        Draggable,
        BranchAssignmentZones,
        BranchAssignmentActions,
    },
    props: {
        value: { type: Array, default: () => [] }, // branches
        zones: { type: Array, default: () => [] },
        zoneTree: { type: Array, default: () => [] },
    },
    data: () => ({
        branches: [],
    }),
    computed: {
        mappedZones() {
            return this.zones.map((zone) => {
                const branches = this.value.filter(({ _assignedZones }) => _assignedZones.includes(zone._id));
                return { zone, branches };
            });
        },
        // newlyAssignedBranches() {
        //     return this.branches.filter(({ _id, _assignedZones = [] }) => {
        //         const branchBefore = (this.values || []).find((branch) => branch._id == _id);
        //         const _assignedZonesBefore = branchBefore?._assignedZones || [];
        //         const hasDifferrentLength = _assignedZones.length != _assignedZonesBefore.length;
        //         if (hasDifferrentLength) return true;
        //         else if (_assignedZones.length == 0) return false;
        //         else return _assignedZones.some((_zone) => !_assignedZonesBefore.includes(_zone));
        //     });
        // },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.branches = JSON.parse(JSON.stringify(this.value || []));
        },
        emit(branches) {
            this.$emit("input", branches);
        },
        // async save() {
        //     this.branches = await Promise.all(this.newlyAssignedBranches.map(async ({ _id, _assignedZones }) => (await api.console.branches.put({ _id, _assignedZones }))?.branch));
        //     this.$emit("input", this.branches);
        //     this.alert("저장되었습니다.");
        // },
    },
};
</script>

<style></style>

<template>
    <v-dialog v-model="show" max-width="400">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card>
            <v-card-title>
                {{ title }}
                <v-btn text icon absolute right @click="show = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider />
            <v-text-field label="제목" v-model="item.name" v-bind="inputAttrs" class="ma-3" />
            <v-textarea label="상세" v-model="item.desc" v-bind="inputAttrs" class="ma-3" />
            <v-file-input label="파일" v-model="item.file" v-bind="inputAttrs" class="ma-3" />
            <v-divider />
            <v-card-actions>
                <v-btn outlined color="grey" @click="init">초기화</v-btn>
                <v-spacer />
                <v-btn color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { inputAttrs } from "@/assets/variables";
const initItem = () => ({
    _category: null,
    name: null,
    file: null,
});
export default {
    props: {
        value: { type: Object, default: () => ({}) },
        categories: { type: Array, default: () => [] },
    },
    data: () => ({
        show: false,
        item: initItem(),
        inputAttrs,
    }),
    computed: {
        isCreate() {
            return !this.value?._id;
        },
        title() {
            return this.isCreate ? "도큐먼트 생성" : "도큐먼트 상세";
        },
        src() {
            if (this.item?.file) return URL.createObjectURL(this.item?.file);
            else return null;
        },
    },
    watch: {
        show(show) {
            if (show) this.init();
        },
    },
    methods: {
        async init() {
            this.item = initItem();
            if (!this.isCreate) {
                const { document } = await api.console.shop.documents.get({ _id: this.value?._id });
                if (document?.file?.url) document.file = await api.getResource(document.file.url, true);
                else document.file = null;

                this.item = document;
            }
        },
        async save() {
            const { post, put } = api.console.shop.documents;

            const { _file, file, ...item } = this.item;

            if (_file) {
                await api.console.commonFiles.delete({ _id: _file });
                item._file = null;
            }
            let { document } = this.isCreate ? await post(item) : await put(item);
            if (file) {
                const { file: newFile } = await api.console.commonFiles.post({ directory: document?._id, index: "file" }, file);
                if (newFile) [{ document }] = [await put({ _id: document?._id, _file: newFile?._id })];
            }

            this.$emit("input", document);
            alert("저장되었습니다");
            this.show = false;
        },
        showFileDialog() {
            this.$refs["file-input"]?.$el.getElementsByTagName("input")?.[0]?.click?.();
        },
    },
};
</script>

<style></style>

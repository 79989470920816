<template>
    <v-expand-transition>
        <div v-show="showsSearch">
            <v-card flat outlined class="mb-3">
                <v-card-title class="subtitle-2 font-weight-bold">{{ label }}</v-card-title>
                <v-card-text>
                    <slot />
                </v-card-text>
                <v-card-actions v-if="!hideAction">
                    <v-spacer />
                    <v-btn color="primary" rounded text v-bind="{ loading }" @click="$emit('search')"> <v-icon small class="mr-1">mdi-magnify</v-icon> Search </v-btn>
                </v-card-actions>
            </v-card>
        </div>
    </v-expand-transition>
</template>

<script>
export default {
    props: {
        label: { type: String, default: "Filters" },
        loading: { type: Boolean, default: false },
        hideAction: { type: Boolean, default: false },
        showsSearch: { type: Boolean, default: false },
    },
};
</script>

<style></style>


<template>
    <v-layout justify-center v-if="setting">
        <v-responsive max-width="720" class="px-3">
            <list-heading title="메인 비주얼 슬라이드 설정" class="mt-8" />
            <v-card class="my-4" style="overflow: hidden">
                <v-row no-gutters>
                    <v-col cols="6" style="border-right: 1px solid rgba(0, 0, 0, 0.12)">
                        <v-card-title class="subtitle-2 font-weight-bold"> <span>Desktop</span> </v-card-title> <v-divider />
                        <v-responsive :aspect-ratio="1 / 1">
                            <image-input v-model="setting.main.productDesktop" width="100%" height="100%" contain tile flat color="grey lighten-4" />
                        </v-responsive>
                    </v-col>
                    <v-col cols="6">
                        <v-card-title class="subtitle-2 font-weight-bold"> <span>Mobile</span> </v-card-title> <v-divider />
                        <v-responsive :aspect-ratio="1 / 1">
                            <image-input v-model="setting.main.productMobile" width="100%" height="100%" contain tile flat color="grey lighten-4" />
                        </v-responsive>
                    </v-col>
                </v-row>
                <v-divider />
                <v-card-actions class="justify-center">
                    <v-btn plain text block color="primary" @click="save">
                        <v-icon class="mr-2">mdi-content-save</v-icon>
                        <span>저장</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";

import ImageInput from "@/components/console/dumb/image-input.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";

export default {
    components: {
        ImageInput,
        ListHeading,
    },
    data: () => ({
        setting: null,
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            await this.search();
        },

        async search() {
            var { setting } = await api.console.setting.get();
            setting.main = { ...(setting.main || {}) };
            this.setting = setting;
        },

        async save() {
            const { _id } = this.setting || {};
            const { _productDesktop, _productMobile, productDesktop, productMobile, ...main } = this.setting?.main || {};

            main._productDesktop = productDesktop instanceof File ? (await api.console.commonFiles.post({ directory: "main-product-display", index: "desktop" }, productDesktop))?.file?._id : productDesktop?._id;
            if (_productDesktop && main._productDesktop != _productDesktop) await api.console.commonFiles.delete({ _id: _productDesktop });

            main._productMobile = productMobile instanceof File ? (await api.console.commonFiles.post({ directory: "main-product-display", index: "mobile" }, productMobile))?.file?._id : productMobile?._id;
            if (_productMobile && main._productMobile != _productMobile) await api.console.commonFiles.delete({ _id: _productMobile });

            await api.console.setting.put({ _id, main });

            alert("저장되었습니다.");
        },
    },
};
</script>

<template>
    <v-menu v-model="showTimePicker" ref="timeMenu" :close-on-content-click="false" :return-value.sync="time" transition="scale-transition" offset-y min-width="290px">
        <template v-slot:activator="timePicker">
            <v-menu v-model="showDatePicker" ref="dateMenu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="290px">
                <template v-slot:activator="{ attrs, on }">
                    <v-text-field v-bind="{ ...attrs, ...timePicker.attrs, ...$attrs, label, placeholder, dense }" v-on="on" v-model="datetime" :hide-details="hideDetails" :class="className" append-icon="event" :clearable="clearable" readonly />
                </template>
                <v-date-picker v-model="date" :type="type" no-title scrollable @input="($refs.dateMenu.save(date) || true) && ((showDatePicker = false) || true) && (showTimePicker = true)"></v-date-picker>
            </v-menu>
        </template>
        <v-time-picker v-if="showTimePicker" v-model="time" no-title scrollable @click:minute="($refs.timeMenu.save(time) || true) && ((showTimepicker = false) || true) && (datetime = [date, time].join(' '))"></v-time-picker>
    </v-menu>
</template>

<script>
export default {
    props: {
        value: { type: String, default: undefined },

        type: { type: String, default: "date" },
        className: { type: String, default: "" },
        label: { type: String, default: undefined },
        dense: { type: Boolean, default: false },
        clearable: { type: Boolean, default: false },
        placeholder: { type: String, default: undefined },
        hideDetails: { type: Boolean, default: false },
    },
    data() {
        return {
            datetime: this.$props.value?.toDateTime?.(),

            date: this.$props.value?.toDateTime?.()?.split?.(" ")?.[0],
            time: this.$props.value?.toDateTime?.()?.split?.(" ")?.[1],

            showDatePicker: false,
            showTimePicker: false,
        };
    },
    watch: {
        value() {
            this.datetime = this.$props.value?.toDateTime?.();
            this.date = this.value?.toDateTime?.()?.split?.(" ")?.[0];
            this.time = this.value?.toDateTime?.()?.split?.(" ")?.[1];
        },
        datetime(datetime) {
            if (datetime) this.$emit("input", new Date(datetime).toISOString());
        },
    },
    methods: {
        test(on) {
            console.log({ on });
        },
    },
};
</script>

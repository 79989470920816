<template>
    <v-card flat tile>
        <v-row no-gutters>
            <v-col cols="12" lg="5">
                <v-card-subtitle class="grey lighten-4 caption"> 발급 쿠폰 입력 </v-card-subtitle>
                <v-divider />
                <v-card-text>
                    <v-row align="center">
                        <v-col cols="6"> <v-text-field label="쿠폰명" v-model="form.name" v-bind="inputAttrs" /> </v-col>
                        <v-col cols="6"> <v-currency-field label="쿠폰금액" v-model="form.amount" prefix="USD" v-bind="inputAttrs" /> </v-col>
                        <v-col cols="12"> <v-textarea label="쿠폰설명" v-model="form.desc" v-bind="inputAttrs" /> </v-col>
                        <v-col cols="6"> <v-date-field label="쿠폰사용가능(시작일)" v-model="form.applicationStartsAt" v-bind="inputAttrs" /> </v-col>
                        <v-col cols="6"> <v-date-field label="쿠폰사용가능(종료일)" v-model="form.applicationEndsAt" v-bind="inputAttrs" /> </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="pa-0">
                    <v-col cols="6">
                        <v-select v-model="form.hasSpecificTarget" label="발급유형" :items="hasSpecificTargetItems" v-bind="inputAttrs" @input="$data._users = []" />
                    </v-col>
                    <v-spacer />
                    <v-col cols="auto"> <v-btn color="primary" v-bind="{ loading }" @click="issue">발급하기</v-btn> </v-col>
                </v-card-actions>
            </v-col>
            <v-col cols="12" lg="auto">
                <v-divider class="d-none d-lg-block" vertical />
                <v-divider class="d-block d-lg-none" />
            </v-col>
            <v-col cols="12" lg="">
                <v-slide-x-transition>
                    <coupon-issue-users v-show="form.hasSpecificTarget" v-model="$data._users" />
                </v-slide-x-transition>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import api from "@/api";
import dayjs from "dayjs";
import { ACADEMY_COUPON_TYPES, inputAttrs } from "@/assets/variables";

import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import CouponIssueUsers from "./coupon-issue-users.vue";

const initForm = () => ({
    type: ACADEMY_COUPON_TYPES.LECTURE_AMOUNT.value,
    name: null,
    desc: null,
    amount: 0,
    hasSpecificTarget: false,
    applicationStartsAt: dayjs().format("YYYY-MM-DD"),
    applicationEndsAt: dayjs()
        .add(1, "month")
        .format("YYYY-MM-DD"),
});

export default {
    components: {
        VDateField,
        CouponIssueUsers,
    },
    data: () => ({
        form: initForm(),
        _users: [],

        loading: false,
        inputAttrs,
        hasSpecificTargetItems: [
            { value: false, text: "전체쿠폰" },
            { value: true, text: "타겟쿠폰" },
        ],
    }),
    methods: {
        init() {
            this.form = initForm();
            this.$data._users = [];
        },
        validates() {
            var nameIsShort = (this.form.name?.length || 0) < 5;
            if (nameIsShort) throw new Error("쿠폰명은 5자 이상입니다");

            var noAmount = (+this.form.amount || 0) <= 0;
            if (noAmount) throw new Error("쿠폰금액을 입력해주세요");

            var noStartsAt = !this.form.applicationEndsAt;
            if (noStartsAt) throw new Error("시작일을 입력해주세요");

            var noEndsAt = !this.form.applicationEndsAt;
            if (noEndsAt) throw new Error("종료일을 입력해주세요");

            var startsAfterEnds = dayjs(this.form.applicationStartsAt).isAfter(this.form.applicationEndsAt, "date");
            if (startsAfterEnds) throw new Error("시작일이 종료일 이후입니다");

            var hasNoTarget = this.form.hasSpecificTarget && this.$data._users.length < 1;
            if (hasNoTarget) throw new Error("타겟을 선택해주세요");
        },
        async issue() {
            try {
                this.loading = true;
                this.validates();

                if (this.form.hasSpecificTarget) await Promise.all(this.$data._users.map(async (_user) => (await api.console.academy.coupons.post({ ...this.form, _user }))?.coupon));
                else await api.console.academy.coupons.post(this.form);

                alert("발급되었습니다.");
                this.init();
            } catch (error) {
                alert(error.message);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>

<template>
    <v-dialog v-model="shows" width="400">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card>
            <v-card-title> Memo </v-card-title>
            <v-card-text class="py-0">
                <v-textarea v-model="form.memo" v-bind="inputAttrs" auto-grow rows="9" />
            </v-card-text>
            <v-card-actions> <v-spacer /> <v-btn text color="primary" @click="save">Save</v-btn> </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { initForm, inputAttrs } from "@/assets/variables";

export default {
    props: {
        value: { type: Object, default: initForm },
    },
    data: () => ({
        form: initForm(),

        shows: false,
        inputAttrs,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initForm(this.value);
        },
        save() {
            let { _id, memo } = this.form;
            let form = { _id, memo };

            this.$emit("update", form);
            this.shows = false;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-small-dialog__activator {
        flex: 1 0 auto;
    }
}
</style>
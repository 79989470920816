<template>
    <v-row class="pa-0">
        <template v-for="(depth, index) in limit">
            <v-col :key="index" cols="12" :sm="12 / limit" class="py-3 py-sm-0">
                <v-select
                    v-bind="{
                        ...$attrs,
                        label: `${depth}차 카테고리`,

                        value: select(depth),

                        items: [{ name: '없음', _id: null }].concat(items(depth)),
                        itemText: 'name',
                        itemValue: '_id',
                        itemDisabled,
                    }"
                    @input="emit"
                ></v-select>
            </v-col>
        </template>
    </v-row>
</template>

<script>
export default {
    props: {
        value: { type: [Object, String], default: null }, // category || _category

        _disableds: { type: Array, default: () => [] }, // _categories
        categories: { type: Array, default: () => [] },

        returnObject: { type: Boolean, default: false },
    },
    data: () => ({
        category: null,
    }),
    computed: {
        limit() {
            return this.findDepth();
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.category = this.findCategory(this.returnObject ? this.value?._id : this.value);
        },

        emit(_category) {
            if (!_category) _category = this.category?._parent || null;

            this.category = this.findCategory(_category);
            this.$emit("input", this.returnObject ? this.category : _category);
        },

        findCategory(_category, categories = null) {
            if (!categories) categories = this.categories;

            for (var category of categories) {
                if (category._id == _category) return category;
                else if (category.children) {
                    var result = this.findCategory(_category, category.children);
                    if (result) return result;
                }
            }

            return null;
        },

        items(depth, categories = this.categories) {
            if (depth == 1) return categories;
            if (this.category) {
                for (var category of categories) {
                    if (category._id == this.category._id) {
                        if (depth <= this.category.depth) return categories;
                        else if (this.category.depth + 1 == depth) return category.children;
                    } else if (category.children) {
                        var result = this.items(depth, category.children);
                        if (result.length) return depth <= category.depth ? categories : result;
                    }
                }
            }

            return [];
        },

        select(depth) {
            if (this.category) return [...this.category._parents, this.category._id][depth - 1] || null;
            return null;
        },

        findDepth(categories = null, depth = 1) {
            var maxDepth = depth;
            if (!categories) categories = this.categories;
            for (var category of categories) {
                if (category.children && category.children.length) {
                    var result = this.findDepth(category.children, depth + 1);
                    if (maxDepth < result) maxDepth = result;
                }
            }
            return maxDepth;
        },
        itemDisabled(item) {
            return this._disableds.includes(item?._id || item);
        },
    },
};
</script>

<template>
    <v-dialog v-model="dialog" width="400" persistent>
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card light v-bind="{ loading }">
            <v-card-title> {{ title }} <v-spacer /> <v-icon @click="dialog = false">mdi-close</v-icon> </v-card-title>
            <v-card-text class="py-0">
                <v-row>
                    <v-col cols="12"> <v-select v-model="form.type" label="유형" v-bind="{ ...inputAttrs, loading }" :items="snsTypes" disabled /> </v-col>
                    <v-col cols="12"> <v-text-field v-model="form.index" type="number" label="#" v-bind="{ ...inputAttrs, loading }" :disabled="isCreate" /> </v-col>
                    <v-col cols="12"> <v-text-field v-model="form.name" label="계정이름" v-bind="{ ...inputAttrs, loading }" /> </v-col>
                    <v-col cols="12"> <v-text-field v-model="form.href" label="계정주소" v-bind="{ ...inputAttrs, loading }" /> </v-col>
                    <v-col cols="12"> <v-text-field v-model="form.username" label="Username" v-bind="{ ...inputAttrs, loading }" /> </v-col>
                    <v-col cols="12"> <v-text-field v-model="form.clientId" label="ClientID" v-bind="{ ...inputAttrs, loading }" /> </v-col>
                    <v-col cols="12">
                        <v-text-field label="API 연동" v-bind="{ ...inputAttrs, loading }" :value="form.isContentsUpdateScheduled ? '활성화' : '비활성화'" readonly class="switch-input-field" @click="form.isContentsUpdateScheduled = !form.isContentsUpdateScheduled">
                            <template #prepend-inner> <v-switch v-model="form.isContentsUpdateScheduled" v-bind="switchAttrs" /> </template>
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-col class="py-0">
                    <v-switch v-model="form.shows" dense v-bind="switchAttrs">
                        <span slot="label" class="subtitle-2"> {{ form.shows ? "노출" : "숨김" }} </span>
                    </v-switch>
                </v-col>
                <v-btn text color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { inputAttrs, switchAttrs, SNS_TYPES } from "@/assets/variables";

const initSnsAccount = () => ({
    type: SNS_TYPES.INSTAGRAM.value,

    index: null,
    name: null,
    href: null,

    username: null,
    clientId: null,

    shows: true,
    isContentsUpdateScheduled: true,
});

const snsTypes = Object.values(SNS_TYPES);

export default {
    props: {
        _id: { type: String, default: null },
    },
    data: () => ({
        form: initSnsAccount(),

        dialog: false,
        loading: false,

        snsTypes,
        inputAttrs,
        switchAttrs,
    }),
    computed: {
        isCreate() {
            return !this._id;
        },
        title() {
            let title = "SNS 계정";
            title += this.isCreate ? " 생성" : " 수정";
            return title;
        },
    },
    watch: {
        dialog() {
            if (this.dialog) this.init();
        },
    },
    methods: {
        async init() {
            this.loading = true;
            try {
                if (this.isCreate) this.form = initSnsAccount();
                else {
                    let { _id } = this;
                    this.form = (await api.console.sns.accounts.get({ _id }))?.account;
                    this.$emit("input", { ...this.form });
                }
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        validates() {
            let { name, href } = this.form;
            if (!name) throw new Error("이름을 등록하셔야 합니다");
            if (!href) throw new Error("계정주소를 등록하셔야 합니다");
        },
        async save() {
            if (this.loading) return;

            this.loading = true;
            try {
                this.validates();

                let { post, put } = api.console.sns.accounts;
                let { ...form } = this.form;
                let account = (await (this.isCreate ? post : put)(form))?.account;

                alert(this.isCreate ? "생성되었습니다" : "수정되었습니다");
                this.$emit("input", account);
                this.dialog = false;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.switch-input-field ::v-deep {
    input {
        cursor: pointer;
        padding: 8px;
    }
    .v-input__slot {
        cursor: initial;
    }
}
</style>

<template>
    <v-layout justify-center>
        <v-responsive max-width="1280" width="100%">
            <list-heading v-model="showsSearch" title="General Inquiries" showsFilterButton />
            <form-list-search v-bind="{ showsSearch }" />

            <v-data-table v-bind="{ items, headers, loading }" :items-per-page="-1" disable-pagination disable-sort hide-default-footer class="v-sheet--outlined">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>

                <template #[`item.memo`]="{ item, value }">
                    <form-list-item-memo :value="item" v-on="{ update }">
                        <template #activator="{ attrs, on }">
                            <v-card flat tile color="transparent" class="fill-height py-4 white-space-pre-line" v-bind="attrs" v-on="on">
                                {{ value || "-" }}
                            </v-card>
                        </template>
                    </form-list-item-memo>
                </template>

                <template #[`item.state`]="{ item }">
                    <form-list-item-state :value="item" v-bind="{ loading }" v-on="{ update }" />
                </template>
            </v-data-table>

            <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import FormListSearch from "@/components/console/form/form-list-search.vue";
import FormListItemMemo from "@/components/console/form/form-list-item-memo.vue";
import FormListItemState from "@/components/console/form/form-list-item-state.vue";

const code = "general-inquiry";

const headers = [
    { width: 100, text: "Inquired At", value: "createdAt", formatter: (value) => value?.toDateTime?.() },
    { width: 160, text: "Inquirer", value: "meta", formatter: ({ name, email }) => [name, email].filter((item) => item).join("\n") },
    { width: 400, text: "Inquiry", value: "content", cellClass: "pb-4" },
    { width: 400, text: "Memo", value: "memo" },
    { width: 160, text: "State", value: "state", cellClass: "px-1" },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), divider: index != array.length - 1, class: (item.class || "") + " white-space-pre-line", cellClass: (item.cellClass || "") + " caption line-height-1-5 white-space-pre-line vertical-align-top" }));

export default {
    components: {
        ListHeading,
        FormListSearch,
        FormListItemMemo,
        FormListItemState,
    },
    data: () => ({
        forms: [],

        limit: 10,
        summary: { totalCount: 0 },

        headers,

        loading: false,
        showsSearch: true,
    }),
    computed: {
        items() {
            return [...this.forms];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            query.code = code;

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.forms = [];
            this.summary = { totalCount: 0 };

            await this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, forms } = await api.console.forms.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.forms = forms;
            } finally {
                this.loading = false;
            }
        },

        async update(form) {
            if (this.loading) return;
            else this.loading = true;

            try {
                form = (await api.console.forms.put(form))?.form;
                alert("저장되었습니다");
                this.updateItem(form);
            } finally {
                this.loading = false;
                this.search();
            }
        },

        updateItem(form) {
            const index = this.forms.findIndex(({ _id }) => _id == form._id);
            if (0 <= index) this.forms.splice(index, 1, form);
            else {
                this.forms = [form, ...this.forms];
                this.summary.totalCount += 1;
            }
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .line-height-1-5 {
        line-height: 1.5;
    }
    .max-width-0 {
        max-width: 0;
    }
    .white-space-pre-line {
        white-space: pre-line;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .v-pagination button {
        box-shadow: none !important;
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .v-small-dialog__activator__content {
        width: 100%;
    }

    th:first-of-type:not(:last-of-type),
    td:first-of-type:not(:last-of-type) {
        border-right: thin solid rgba(0, 0, 0, 0.12);
    }

    .vertical-align-top {
        vertical-align: baseline;
    }
}
</style>

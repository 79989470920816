var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "480",
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.isCreate ? "강의 생성" : "강의 상세") + " "), _c('v-btn', {
    attrs: {
      "text": "",
      "icon": "",
      "absolute": "",
      "right": ""
    },
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "pa-0",
    staticStyle: {
      "max-height": "calc(90vh - 58px - 1px - 1px - 52px )",
      "overflow-y": "auto"
    }
  }, [_c('v-text-field', _vm._b({
    staticClass: "ma-4",
    attrs: {
      "label": "강의명"
    },
    model: {
      value: _vm.item.name,
      callback: function ($$v) {
        _vm.$set(_vm.item, "name", $$v);
      },
      expression: "item.name"
    }
  }, 'v-text-field', _vm.inputAttrs, false)), _c('v-file-input', _vm._b({
    staticClass: "ma-4",
    attrs: {
      "label": "첨부파일"
    },
    model: {
      value: _vm.item.reference,
      callback: function ($$v) {
        _vm.$set(_vm.item, "reference", $$v);
      },
      expression: "item.reference"
    }
  }, 'v-file-input', _vm.inputAttrs, false)), _c('v-file-input', _vm._b({
    staticClass: "ma-4",
    attrs: {
      "label": "강의영상",
      "accept": "video/mp4,video/x-m4v,video/*",
      "prepend-icon": "mdi-file-video",
      "placeholder": "파일을 선택해주세요"
    },
    on: {
      "change": function ($event) {
        _vm.item.duration = 0;
      }
    },
    model: {
      value: _vm.item.video,
      callback: function ($$v) {
        _vm.$set(_vm.item, "video", $$v);
      },
      expression: "item.video"
    }
  }, 'v-file-input', _vm.inputAttrs, false), [_c('v-layout', {
    staticClass: "mt-n2",
    staticStyle: {
      "height": "40px"
    },
    attrs: {
      "slot": "append",
      "align-center": ""
    },
    slot: "append"
  }, [_vm._v(_vm._s(_vm.duration))])], 1)], 1), _c('v-file-input', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "file-input",
    staticClass: "ma-0",
    model: {
      value: _vm.item.thumb,
      callback: function ($$v) {
        _vm.$set(_vm.item, "thumb", $$v);
      },
      expression: "item.thumb"
    }
  }), _c('v-expand-transition', [_c('v-responsive', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !!_vm.src,
      expression: "!!src"
    }],
    staticClass: "px-4"
  }, [_c('video', _vm._b({
    attrs: {
      "preload": "metadata",
      "width": "100%"
    },
    on: {
      "loadedmetadata": function (e) {
        var _e$target;
        return _vm.item.duration = (((_e$target = e.target) === null || _e$target === void 0 ? void 0 : _e$target.duration) || 0) * 1000;
      }
    }
  }, 'video', {
    src: _vm.src
  }, false))])], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "grey"
    },
    on: {
      "click": _vm.init
    }
  }, [_vm._v("초기화")]), _c('v-col', {
    staticClass: "py-0 text-center caption"
  }, [_vm._v(" ※ 온디맨드 상세창의 저장 버튼도 누르셔야 변경됩니다. ")]), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.validate
    }
  }, [_vm._v("저장")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-dialog v-model="shows" max-width="480" persistent>
        <template #activator="{attrs, on}">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card>
            <v-card-title>
                {{ isCreate ? "강의 생성" : "강의 상세" }} <v-btn text icon absolute right @click="shows = false"> <v-icon>mdi-close</v-icon> </v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text class="pa-0" style="max-height: calc(90vh - 58px - 1px - 1px - 52px ); overflow-y: auto;">
                <v-text-field label="강의명" v-model="item.name" v-bind="inputAttrs" class="ma-4" />
                <v-file-input label="첨부파일" v-model="item.reference" v-bind="inputAttrs" class="ma-4" />
                <v-file-input label="강의영상" accept="video/mp4,video/x-m4v,video/*" v-model="item.video" v-bind="inputAttrs" class="ma-4" prepend-icon="mdi-file-video" placeholder="파일을 선택해주세요" @change="item.duration = 0">
                    <v-layout slot="append" class="mt-n2" style="height:40px" align-center>{{ duration }}</v-layout>
                </v-file-input>
            </v-card-text>
            <v-file-input v-model="item.thumb" v-show="false" ref="file-input" class="ma-0" />
            <v-expand-transition>
                <v-responsive v-show="!!src" class="px-4">
                    <video v-bind="{ src }" preload="metadata" @loadedmetadata="(e) => (item.duration = (e.target?.duration || 0) * 1000)" width="100%" />
                </v-responsive>
            </v-expand-transition>
            <v-divider />
            <v-card-actions>
                <v-btn outlined color="grey" @click="init">초기화</v-btn>
                <v-col class="py-0 text-center caption">
                    ※ 온디맨드 상세창의 저장 버튼도 누르셔야 변경됩니다.
                </v-col>
                <v-btn color="primary" @click="validate">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { inputAttrs } from "@/assets/variables";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
const initItem = () => ({
    id: Math.random()
        .toFixed(10)
        .toString()
        .slice(2),
    name: null,
    video: null,
    duration: 0,
    reference: null,
});
export default {
    components: {
        NaverSmarteditor,
    },
    props: {
        value: { type: Object, default: null },
        units: { type: Array, default: () => [] },
        lecture: { type: Object, default: () => ({}) },
    },
    data: () => ({
        shows: false,
        item: initItem(),
        inputAttrs,
    }),
    computed: {
        isCreate() {
            return this.units.includes(this.item);
        },
        src() {
            return this.item?.video instanceof File ? URL.createObjectURL(this.item?.video) : this.value?.video?.url || null;
        },
        duration() {
            return new Date(null, null, null, null, null, null, this.item.duration || 0).toTimeString().split(" ")[0];
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        shows(shows) {
            if (shows) this.init();
        },
    },
    methods: {
        init() {
            if (!!this.value) this.sync();
            else this.item = initItem();
        },
        sync() {
            this.item = JSON.parse(JSON.stringify(this.value));
        },
        validate() {
            try {
                if (!this.item.video) throw new Error("강의영상이 등록되지 않았습니다.");

                this.$emit("input", this.item);
                this.shows = false;
            } catch (error) {
                alert(error.message);
            }
        },
        async save() {
            const { post, put } = api.console.academy.lectures.units;
            let {
                item: { _video = null, video = null, _reference = null, reference = null, id, duration, ...item },
                lecture: { _id: _lecture },
            } = this;

            let { unit } = await (!item?._id ? post({ ...item, _lecture }) : put({ ...item, _lecture }));
            const directory = unit._id;

            if (video instanceof File) {
                if (!!_video) await api.console.commonFiles.delete({ _id: _video });
                const { file } = await api.console.commonFiles.post({ directory, index: "video", duration }, video);
                _video = file._id;
            } else if (!video) {
                if (!!_video) await api.console.commonFiles.delete({ _id: _video });
                _video = null;
            }

            if (reference instanceof File) {
                if (!!_reference) await api.console.commonFiles.delete({ _id: _reference });
                const { file } = await api.console.commonFiles.post({ directory, index: "reference" }, reference);
                _reference = file._id;
            } else if (!reference) {
                if (!!_reference) await api.console.commonFiles.delete({ _id: _reference });
                _reference = null;
            }

            [{ unit }] = [await put({ _id: directory, _lecture, _video, _reference })];

            this.$emit("input", { ...unit, id });
        },
    },
};
</script>

<style></style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "px-1",
    attrs: {
      "max-width": "1080",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm.user._id ? _c('span', [_vm._v("User Item")]) : _c('span', [_vm._v("Creat User Item")])]), _c('v-card', {
    staticClass: "my-4"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('v-card-subtitle', {
    staticClass: "font-weight-bold grey lighten-4"
  }, [_vm._v("Account Info.")]), _c('v-divider'), _c('v-row', {
    staticClass: "ma-1"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Username",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": ""
    },
    model: {
      value: _vm.user.username,
      callback: function ($$v) {
        _vm.$set(_vm.user, "username", $$v);
      },
      expression: "user.username"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Password",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.password,
      callback: function ($$v) {
        _vm.$set(_vm.user, "password", $$v);
      },
      expression: "user.password"
    }
  })], 1)], 1), _c('v-divider'), _c('v-card-subtitle', {
    staticClass: "font-weight-bold grey lighten-4"
  }, [_vm._v("Base Info.")]), _c('v-divider'), _vm.user.type == _vm.PERSON.value ? [_c('form-name-input', {
    staticClass: "ma-1",
    attrs: {
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user,
      callback: function ($$v) {
        _vm.user = $$v;
      },
      expression: "user"
    }
  }), _c('v-divider'), _c('v-row', {
    staticClass: "ma-1"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "E-mail Address",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.email,
      callback: function ($$v) {
        _vm.$set(_vm.user, "email", $$v);
      },
      expression: "user.email"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('form-phone-input', {
    model: {
      value: _vm.user,
      callback: function ($$v) {
        _vm.user = $$v;
      },
      expression: "user"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.occupations,
      "label": "Speciality",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.occupation,
      callback: function ($$v) {
        _vm.$set(_vm.user, "occupation", $$v);
      },
      expression: "user.occupation"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Business License Number",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.businessLicenseNumber,
      callback: function ($$v) {
        _vm.$set(_vm.user, "businessLicenseNumber", $$v);
      },
      expression: "user.businessLicenseNumber"
    }
  })], 1)], 1)] : _vm._e(), _c('v-divider'), _c('v-card-subtitle', {
    staticClass: "font-weight-bold grey lighten-4"
  }, [_vm._v("Location Info.")]), _c('v-divider'), _vm.user.type == _vm.PERSON.value ? [_c('branch-view-zones', _vm._b({
    attrs: {
      "cols": "6",
      "sm": "6",
      "className": "ma-1 px-0"
    },
    model: {
      value: _vm.user._zone,
      callback: function ($$v) {
        _vm.$set(_vm.user, "_zone", $$v);
      },
      expression: "user._zone"
    }
  }, 'branch-view-zones', {
    zones: _vm.zones,
    inputAttrs: {
      outline: false,
      'persistent-placeholder': true,
      'hide-details': true
    }
  }, false), [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "State/Province",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.address.state,
      callback: function ($$v) {
        _vm.$set(_vm.user.address, "state", $$v);
      },
      expression: "user.address.state"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "City",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.address.city,
      callback: function ($$v) {
        _vm.$set(_vm.user.address, "city", $$v);
      },
      expression: "user.address.city"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Street Address",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.address.street,
      callback: function ($$v) {
        _vm.$set(_vm.user.address, "street", $$v);
      },
      expression: "user.address.street"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Zip/Postal Code",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.address.zip,
      callback: function ($$v) {
        _vm.$set(_vm.user.address, "zip", $$v);
      },
      expression: "user.address.zip"
    }
  })], 1)], 1)] : _vm._e(), _vm.user.type == _vm.BRANCH.value ? [_c('v-divider'), _c('v-card-subtitle', {
    staticClass: "font-weight-bold grey lighten-4"
  }, [_vm._v("Contact Person")]), _c('v-divider'), _c('v-row', {
    staticClass: "ma-2 my-4"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Mame",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.workerName,
      callback: function ($$v) {
        _vm.$set(_vm.user, "workerName", $$v);
      },
      expression: "user.workerName"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "TEL",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.workerPhone,
      callback: function ($$v) {
        _vm.$set(_vm.user, "workerPhone", $$v);
      },
      expression: "user.workerPhone"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "E-mail",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.workerEmail,
      callback: function ($$v) {
        _vm.$set(_vm.user, "workerEmail", $$v);
      },
      expression: "user.workerEmail"
    }
  })], 1)], 1)] : _vm._e()], 2), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticClass: "d-none d-md-block",
    attrs: {
      "vertical": ""
    }
  }), _c('v-divider', {
    staticClass: "d-block d-md-none"
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('v-card-subtitle', {
    staticClass: "font-weight-bold grey lighten-4"
  }, [_vm._v("Memo")]), _c('v-divider'), _c('v-card-text', [_vm.user.type == _vm.PERSON.value ? _c('v-textarea', {
    staticClass: "pa-0 ma-0",
    attrs: {
      "hide-details": "",
      "rows": "7",
      "auto-grow": ""
    },
    model: {
      value: _vm.user.note,
      callback: function ($$v) {
        _vm.$set(_vm.user, "note", $$v);
      },
      expression: "user.note"
    }
  }) : _vm._e()], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', {
    staticClass: "align-center grey lighten-4"
  }, [_c('v-switch', {
    staticClass: "my-0 mx-4 pa-0",
    attrs: {
      "color": "red lighten-1",
      "dense": "",
      "inset": "",
      "hide-details": ""
    },
    model: {
      value: _vm.user.isWarning,
      callback: function ($$v) {
        _vm.$set(_vm.user, "isWarning", $$v);
      },
      expression: "user.isWarning"
    }
  }, [_c('span', {
    staticClass: "subtitle-2 font-weight-bold",
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v(" User to Watch out ")])]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("Save")]), _c('user-withdraw', {
    on: {
      "input": function ($event) {
        return _vm.$router.go(-1);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "ml-2",
          attrs: {
            "color": "red darken-1",
            "dark": ""
          }
        }, 'v-btn', attrs, false), on), [_vm._v("Withdraw")])];
      }
    }]),
    model: {
      value: _vm.user,
      callback: function ($$v) {
        _vm.user = $$v;
      },
      expression: "user"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "ma-2 my-4",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  })], 1)], 1), _c('daum-postcode', {
    ref: "DaumPostcode",
    on: {
      "input": _vm.setAddress
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
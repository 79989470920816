var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1080",
      "width": "100%"
    }
  }, [_c('list-heading', {
    staticClass: "mt-8",
    attrs: {
      "title": "Digital Guide S/W"
    }
  }), _c('v-tabs', {
    attrs: {
      "show-arrows": ""
    }
  }, [_vm._l(_vm.softwares, function (_ref) {
    var _id = _ref._id,
      name = _ref.name;
    return [_c('v-tab', {
      key: _id,
      attrs: {
        "to": `${_vm.baseRoute}/${_id}`,
        "exact": ""
      }
    }, [_c('div', {
      staticClass: "text-truncate",
      staticStyle: {
        "max-width": "100px"
      }
    }, [_vm._v(_vm._s(name || "-"))])]), _c('v-divider', {
      key: `${_id}-divider`,
      attrs: {
        "vertical": ""
      }
    })];
  }), _c('v-spacer'), _c('v-tab', {
    attrs: {
      "to": _vm.baseRoute,
      "exact": ""
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 2), _c('v-divider'), _c('v-tabs-items', {
    attrs: {
      "value": _vm._software,
      "touchless": "",
      "continuous": false
    }
  }, [_vm._l(_vm.softwares, function (software, i) {
    return [_c('software-view', {
      key: software === null || software === void 0 ? void 0 : software._id,
      on: {
        "remove": _vm.remove
      },
      model: {
        value: _vm.softwares[i],
        callback: function ($$v) {
          _vm.$set(_vm.softwares, i, $$v);
        },
        expression: "softwares[i]"
      }
    })];
  }), _c('software-view', {
    on: {
      "input": function (item) {
        return _vm.softwares.push(item);
      }
    }
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-data-table v-model="selected" v-bind="{ headers, items }" hide-default-footer disable-filtering disable-sort disable-pagination :items-per-page="-1" show-select single-select item-key="tempId">
        <template #[`item.type`]="{ item }">
            <v-select v-model="item.type" :items="websiteTypes" flat solo hide-details @change="emit" />
            <!-- <v-edit-dialog large :return-value.sync="item.type" @save="emit">
                <div>{{ COMPANY_WEBSITE_TYPES[value]?.text || value }}</div>
                <template #input>
                    <v-select v-model="item.type" :items="websiteTypes" v-bind="inputAttrs" dense class="mt-4" single-line />
                </template>
            </v-edit-dialog> -->
        </template>
        <template #[`item.href`]="{ item }">
            <v-text-field v-model="item.href" placeholder="https://www.youtube.com" flat solo hide-details @input="emit" />
            <!-- <v-edit-dialog large :return-value.sync="item.href" @save="emit">
                <div>{{ COMPANY_WEBSITE_TYPES[value]?.text || value }}</div>
                <template #input>
                    <v-text-field v-model="item.href" v-bind="inputAttrs" dense class="mt-4" single-line placeholder="https://www.youtube.com" />
                </template>
            </v-edit-dialog> -->
        </template>
        <template #[`item.actions`]="{ item }">
            <v-btn icon text color="error" @click="remove(item)">
                <v-icon>mdi-minus</v-icon>
            </v-btn>
        </template>
        <template #footer>
            <v-divider />
            <v-row class="ma-0">
                <v-col cols="auto" class="px-4 py-2">
                    <v-btn icon text color="primary" :disabled="upDisabled" @click="upItem">
                        <v-icon>mdi-chevron-up</v-icon>
                    </v-btn>
                </v-col>
                <v-col cols="auto" class="px-4 py-2">
                    <v-btn icon text color="primary" :disabled="downDisabled" @click="downItem">
                        <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                </v-col>
                <v-spacer />
                <v-col cols="auto" class="px-4 py-2">
                    <v-btn icon text color="primary" :disabled="3 < items.length" @click="add">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </v-data-table>
</template>

<script>
import { COMPANY_WEBSITE_TYPES, inputAttrs } from "@/assets/variables";

const headers = [
    { value: "type", text: "유형", width: 110 },
    { value: "href", text: "주소" },
    { value: "actions", text: "", width: +60 },
];
const websiteTypes = Object.values(COMPANY_WEBSITE_TYPES);

export default {
    props: {
        value: { type: Array, default: () => [] }, // company.websites
    },
    data: () => ({
        items: [],
        selected: [],

        headers,
        inputAttrs,
        websiteTypes,
        COMPANY_WEBSITE_TYPES,
    }),
    computed: {
        upDisabled() {
            if ((this.items || []).length < 1 || !this.selected[0] || !this.items.some(({ tempId }) => tempId == this.selected[0]?.tempId)) return true;
            return this.selected?.[0]?.tempId == this.items?.[0]?.tempId;
        },
        downDisabled() {
            if ((this.items || []).length < 1 || !this.selected[0] || !this.items.some(({ tempId }) => tempId == this.selected[0]?.tempId)) return true;
            return [...(this.selected || [])]?.pop()?.tempId == [...(this.items || [])]?.pop()?.tempId;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.items = [...this.value].map((item) => ({ ...item, tempId: item.tempId ?? Math.random() }));
        },
        emit() {
            this.$emit("input", this.items);
        },
        add() {
            this.items.push({
                tempId: Math.random(),
                type: null,
                href: null,
            });
            this.emit();
        },
        remove(item) {
            this.items = this.items.filter(({ tempId }) => tempId != item.tempId);
            this.emit();
        },
        upItem() {
            const index = (this.items || []).findIndex(({ tempId }) => tempId == this.selected[0]?.tempId);
            this.items.splice(index - 1, 2, this.items[index], this.items[index - 1]);
            this.emit();
        },
        downItem() {
            const index = (this.items || []).findIndex(({ tempId }) => tempId == this.selected[0]?.tempId);
            this.items.splice(index, 2, this.items[index + 1], this.items[index]);
            this.emit();
        },
    },
};
</script>

<style>
</style>
var render = function render(){
  var _vm$value;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tab-item', {
    staticStyle: {
      "height": "590px"
    },
    attrs: {
      "value": ((_vm$value = _vm.value) === null || _vm$value === void 0 ? void 0 : _vm$value._id) || 'create',
      "eager": ""
    }
  }, [_c('v-card', _vm._b({
    attrs: {
      "flat": "",
      "tile": ""
    }
  }, 'v-card', {
    loading: _vm.loading
  }, false), [_c('v-fade-transition', [_c('v-overlay', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "light": "",
      "color": "white",
      "absolute": ""
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "color": "primary",
      "indeterminate": "",
      "width": "5",
      "size": "100"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "4",
      "md": "3"
    }
  }, [_c('v-card-subtitle', {
    staticClass: "caption font-weight-bold"
  }, [_vm._v("썸네일"), _c('span', {
    staticClass: "pl-1 font-weight-medium grey--text"
  })]), _c('software-thumb', {
    ref: "thumb",
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }), _c('v-divider'), _c('v-card-subtitle', {
    staticClass: "caption font-weight-bold"
  }, [_vm._v("동영상"), _c('span', {
    staticClass: "pl-1 font-weight-medium grey--text"
  })]), _c('software-video', {
    ref: "video",
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  })], 1), _c('v-col', {
    staticClass: "d-none d-sm-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    staticClass: "d-block d-sm-none",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    staticStyle: {
      "height": "537px"
    }
  }, [_c('v-row', {
    staticClass: "ma-2"
  }, [_c('v-col', {
    staticClass: "pa-2"
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "제목"
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2"
  }, [_c('v-text-field', _vm._b({
    staticClass: "files-text-field",
    attrs: {
      "label": "파일",
      "readonly": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('software-file-list', {
          ref: "file-list",
          model: {
            value: _vm.form,
            callback: function ($$v) {
              _vm.form = $$v;
            },
            expression: "form"
          }
        })];
      },
      proxy: true
    }])
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    staticClass: "pa-2",
    attrs: {
      "cols": "12"
    }
  }, [_vm.isOn ? _c('naver-smarteditor', {
    model: {
      value: _vm.form.desc,
      callback: function ($$v) {
        _vm.$set(_vm.form, "desc", $$v);
      },
      expression: "form.desc"
    }
  }) : _vm._e()], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider'), _c('v-card-actions', [!_vm.isCreate ? _c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "red"
    },
    on: {
      "click": _vm.remove
    }
  }, [_vm._v("삭제")]) : _vm._e(), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm$branch, _vm$branch$company, _vm$branch2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "900",
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', {
    staticClass: "d-flex flex-column",
    attrs: {
      "min-height": "480"
    }
  }, [_c('v-fade-transition', [_c('v-overlay', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.branch,
      expression: "!branch"
    }],
    attrs: {
      "absolute": "",
      "light": "",
      "color": "white"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": "100",
      "width": "5"
    }
  })], 1)], 1), _c('v-card-title', [_vm._v(" 지역배정 - " + _vm._s((_vm$branch = _vm.branch) === null || _vm$branch === void 0 ? void 0 : (_vm$branch$company = _vm$branch.company) === null || _vm$branch$company === void 0 ? void 0 : _vm$branch$company.name) + " "), _c('v-spacer'), _vm._v(" "), _c('v-btn', {
    attrs: {
      "text": "",
      "icon": ""
    },
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-divider'), !!_vm.branch ? _c('v-card-text', {
    staticClass: "pa-0",
    staticStyle: {
      "flex-grow": "1"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', [_c('v-data-table', _vm._b({
    attrs: {
      "items": (_vm$branch2 = _vm.branch) === null || _vm$branch2 === void 0 ? void 0 : _vm$branch2.assignedZones,
      "dense": "",
      "show-select": ""
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c('v-card-subtitle', {
          staticClass: "d-flex align-center grey lighten-4",
          staticStyle: {
            "position": "relative"
          }
        }, [_vm._v(" 배정된 지역 "), _c('v-fade-transition', [_c('v-btn', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.zonesToRemove.length > 0,
            expression: "zonesToRemove.length > 0"
          }],
          attrs: {
            "absolute": "",
            "right": "",
            "small": "",
            "outlined": "",
            "color": "red"
          },
          on: {
            "click": _vm.removeZones
          }
        }, [_vm._v(" 선택한 항목 모두 제거 ")])], 1)], 1), _c('v-divider')];
      },
      proxy: true
    }, {
      key: `item.actions`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-btn', {
          attrs: {
            "small": "",
            "text": "",
            "icon": "",
            "color": "red"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-minus")])], 1)];
      }
    }], null, true),
    model: {
      value: _vm.zonesToRemove,
      callback: function ($$v) {
        _vm.zonesToRemove = $$v;
      },
      expression: "zonesToRemove"
    }
  }, 'v-data-table', {
    headers: _vm.headers
  }, false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', [_c('v-data-table', _vm._b({
    attrs: {
      "items": _vm.unassignedZones,
      "dense": "",
      "show-select": ""
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function () {
        return [_c('v-card-subtitle', {
          staticClass: "d-flex align-center grey lighten-4",
          staticStyle: {
            "position": "relative"
          }
        }, [_vm._v(" 미배정 지역 "), _c('v-fade-transition', [_c('v-btn', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.zonesToAdd.length > 0,
            expression: "zonesToAdd.length > 0"
          }],
          attrs: {
            "absolute": "",
            "right": "",
            "small": "",
            "outlined": "",
            "color": "primary"
          },
          on: {
            "click": _vm.addZones
          }
        }, [_vm._v(" 선택한 항목 모두 추가 ")])], 1)], 1), _c('v-divider')];
      },
      proxy: true
    }, {
      key: `item.actions`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-btn', {
          attrs: {
            "small": "",
            "text": "",
            "icon": "",
            "color": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.add(item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
      }
    }], null, true),
    model: {
      value: _vm.zonesToAdd,
      callback: function ($$v) {
        _vm.zonesToAdd = $$v;
      },
      expression: "zonesToAdd"
    }
  }, 'v-data-table', {
    headers: _vm.headers
  }, false))], 1)], 1)], 1) : _vm._e(), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
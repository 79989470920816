var render = function render(){
  var _vm$form, _vm$form$meta, _vm$form$meta$assigne, _vm$form$meta$assigne2, _vm$form$meta$assigne3, _vm$form$meta$assigne4, _vm$form2, _vm$form2$createdAt, _vm$form2$createdAt$t, _vm$form3, _vm$form3$writer, _vm$form3$writer$phon, _vm$form3$writer$phon2, _vm$form4, _vm$form4$writer, _vm$form5, _vm$form5$meta, _vm$form5$meta$catego, _vm$form5$meta$catego2, _vm$form5$meta$catego3, _vm$form5$meta$catego4, _vm$form6, _vm$form6$meta, _vm$form6$meta$produc, _vm$form6$meta$produc2, _vm$form6$meta$produc3, _vm$form6$meta$produc4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "px-3",
    attrs: {
      "max-width": "800",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v(_vm._s(_vm.headline))]), _vm.isCode(['product-inquiry']) && _vm.isUserConsole ? _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-row', {
    staticClass: "mx-0",
    attrs: {
      "align": "baseline"
    }
  }, [_c('v-col', {
    staticClass: "subtitle-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 담당지사 ")]), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto",
      "align-self": "stretch"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    staticClass: "subtitle-2"
  }, [_vm._v(" " + _vm._s((_vm$form = _vm.form) === null || _vm$form === void 0 ? void 0 : (_vm$form$meta = _vm$form.meta) === null || _vm$form$meta === void 0 ? void 0 : (_vm$form$meta$assigne = _vm$form$meta.assignedBranches) === null || _vm$form$meta$assigne === void 0 ? void 0 : (_vm$form$meta$assigne2 = _vm$form$meta$assigne.map) === null || _vm$form$meta$assigne2 === void 0 ? void 0 : (_vm$form$meta$assigne3 = _vm$form$meta$assigne2.call(_vm$form$meta$assigne, function (_ref) {
    var company = _ref.company;
    return company === null || company === void 0 ? void 0 : company.name;
  })) === null || _vm$form$meta$assigne3 === void 0 ? void 0 : (_vm$form$meta$assigne4 = _vm$form$meta$assigne3.join) === null || _vm$form$meta$assigne4 === void 0 ? void 0 : _vm$form$meta$assigne4.call(_vm$form$meta$assigne3, ", ")) + " ")]), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('assigned-branches-view', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var attrs = _ref2.attrs,
          on = _ref2.on;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "plain": "",
            "text": "",
            "color": "primary"
          }
        }, 'v-btn', attrs, false), on), [_vm._v("변경하기")])];
      }
    }], null, false, 1855431482),
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("기본정보")]), _c('v-divider'), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('v-row', {
    staticClass: "mx-n4 px-5"
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 작성자 ")]), _vm._v(" "), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _vm._v(" "), _c('v-col', [_vm._v(_vm._s(_vm.form.writer.name || "-") + " ")]), _vm._v(" "), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _vm._v(" "), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 작성일 ")]), _vm._v(" "), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _vm._v(" "), _c('v-col', [_vm._v(_vm._s(((_vm$form2 = _vm.form) === null || _vm$form2 === void 0 ? void 0 : (_vm$form2$createdAt = _vm$form2.createdAt) === null || _vm$form2$createdAt === void 0 ? void 0 : (_vm$form2$createdAt$t = _vm$form2$createdAt.toDateTime) === null || _vm$form2$createdAt$t === void 0 ? void 0 : _vm$form2$createdAt$t.call(_vm$form2$createdAt)) || "-") + " ")])], 1), _c('v-divider'), _c('v-row', {
    staticClass: "mx-n4 px-5"
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 연락처 ")]), _vm._v(" "), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _vm._v(" "), _c('v-col', [_vm._v(_vm._s(((_vm$form3 = _vm.form) === null || _vm$form3 === void 0 ? void 0 : (_vm$form3$writer = _vm$form3.writer) === null || _vm$form3$writer === void 0 ? void 0 : (_vm$form3$writer$phon = _vm$form3$writer.phone) === null || _vm$form3$writer$phon === void 0 ? void 0 : (_vm$form3$writer$phon2 = _vm$form3$writer$phon.phoneNumberFormat) === null || _vm$form3$writer$phon2 === void 0 ? void 0 : _vm$form3$writer$phon2.call(_vm$form3$writer$phon)) || ((_vm$form4 = _vm.form) === null || _vm$form4 === void 0 ? void 0 : (_vm$form4$writer = _vm$form4.writer) === null || _vm$form4$writer === void 0 ? void 0 : _vm$form4$writer.phone) || "-") + " ")]), _vm._v(" "), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _vm._v(" "), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 이메일 ")]), _vm._v(" "), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _vm._v(" "), _c('v-col', [_vm._v(_vm._s(_vm.form.writer.email || "-") + " ")])], 1)], 1)], 1), !_vm.isCode(['product-inquiry']) ? _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-text', [_c('v-text-field', _vm._b({
    attrs: {
      "value": _vm.form.meta.type,
      "label": "유형"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1)], 1) : _vm._e(), !_vm.isCode(['product-inquiry']) ? _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("문의 상세")]), _c('v-divider'), _c('v-card-text', [_c('v-text-field', _vm._b({
    staticClass: "mb-6",
    attrs: {
      "value": _vm.form.subject,
      "label": "제목",
      "readonly": ""
    }
  }, 'v-text-field', _vm.inputAttrs, false)), _c('v-textarea', _vm._b({
    attrs: {
      "value": _vm.form.content,
      "label": "내용",
      "readonly": ""
    }
  }, 'v-textarea', _vm.inputAttrs, false))], 1)], 1) : _vm._e(), !_vm.isCode(['product-inquiry']) ? _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("내용")]), _c('v-divider'), _c('v-card-text', [_c('v-textarea', {
    attrs: {
      "value": _vm.form.content,
      "hide-details": "",
      "dense": "",
      "auto-grow": ""
    }
  })], 1)], 1) : _vm._e(), _vm.isCode(['product-inquiry']) ? _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("부가정보")]), _c('v-divider'), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('v-row', {
    staticClass: "mx-n4 px-5"
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 구분 ")]), _vm._v(" "), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _vm._v(" "), _c('v-col', [_vm._v(_vm._s(_vm.form.meta.isNewUser ? "New" : "Existing") + " ")]), _vm._v(" "), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _vm._v(" "), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 거래이력 ")]), _vm._v(" "), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _vm._v(" "), _c('v-col', [_vm._v(_vm._s(_vm.form.meta.hasUsedDentium ? "Used or in use" : "Never Used") + " ")])], 1), _c('v-divider'), _c('v-row', {
    staticClass: "mx-n4 px-5"
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 관심제품 ")]), _vm._v(" "), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _vm._v(" "), _c('v-col', [_vm._v(_vm._s(((_vm$form5 = _vm.form) === null || _vm$form5 === void 0 ? void 0 : (_vm$form5$meta = _vm$form5.meta) === null || _vm$form5$meta === void 0 ? void 0 : (_vm$form5$meta$catego = _vm$form5$meta.categories) === null || _vm$form5$meta$catego === void 0 ? void 0 : (_vm$form5$meta$catego2 = _vm$form5$meta$catego.map) === null || _vm$form5$meta$catego2 === void 0 ? void 0 : (_vm$form5$meta$catego3 = _vm$form5$meta$catego2.call(_vm$form5$meta$catego, function (_ref3) {
    var name = _ref3.name;
    return name;
  })) === null || _vm$form5$meta$catego3 === void 0 ? void 0 : (_vm$form5$meta$catego4 = _vm$form5$meta$catego3.join) === null || _vm$form5$meta$catego4 === void 0 ? void 0 : _vm$form5$meta$catego4.call(_vm$form5$meta$catego3, ", ")) || "-") + " ")])], 1), _c('v-divider'), _c('v-row', {
    staticClass: "mx-n4 px-5"
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 제품명 ")]), _vm._v(" "), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _vm._v(" "), _c('v-col', [_vm._v(_vm._s(((_vm$form6 = _vm.form) === null || _vm$form6 === void 0 ? void 0 : (_vm$form6$meta = _vm$form6.meta) === null || _vm$form6$meta === void 0 ? void 0 : (_vm$form6$meta$produc = _vm$form6$meta.products) === null || _vm$form6$meta$produc === void 0 ? void 0 : (_vm$form6$meta$produc2 = _vm$form6$meta$produc.map) === null || _vm$form6$meta$produc2 === void 0 ? void 0 : (_vm$form6$meta$produc3 = _vm$form6$meta$produc2.call(_vm$form6$meta$produc, function (_ref4) {
    var name = _ref4.name;
    return name;
  })) === null || _vm$form6$meta$produc3 === void 0 ? void 0 : (_vm$form6$meta$produc4 = _vm$form6$meta$produc3.join) === null || _vm$form6$meta$produc4 === void 0 ? void 0 : _vm$form6$meta$produc4.call(_vm$form6$meta$produc3, ", ")) || "-") + " ")])], 1)], 1)], 1) : _vm._e(), _vm.isCode(['product-inquiry']) ? _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("문의내용")]), _c('v-divider'), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('v-row', {
    staticClass: "mx-n4 px-5"
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" Inquiry ")]), _vm._v(" "), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _vm._v(" "), _c('v-col', {
    staticClass: "white-space-pre-line"
  }, [_vm._v(_vm._s(_vm.form.content))])], 1), _c('v-divider'), _c('v-row', {
    staticClass: "mx-n4 px-5"
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" Other Message ")]), _vm._v(" "), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _vm._v(" "), _c('v-col', {
    staticClass: "white-space-pre-line"
  }, [_vm._v(_vm._s(_vm.form.content))])], 1)], 1)], 1) : _vm._e(), _vm.isCode(['product-inquiry']) ? _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("답변")]), _c('v-divider'), _c('v-card-text', [_c('naver-smarteditor', {
    attrs: {
      "id": "reply",
      "rows": "10"
    },
    model: {
      value: _vm.form.reply,
      callback: function ($$v) {
        _vm.$set(_vm.form, "reply", $$v);
      },
      expression: "form.reply"
    }
  })], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "grey"
    },
    on: {
      "click": function ($event) {
        _vm.form.reply = null;
      }
    }
  }, [_vm._v("초기화")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1) : _vm._e(), !_vm.isCode(['product-inquiry']) ? _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1) : _vm._e()], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }
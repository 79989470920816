var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "400"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.title) + " "), _c('v-btn', {
    attrs: {
      "text": "",
      "icon": "",
      "absolute": "",
      "right": ""
    },
    on: {
      "click": function ($event) {
        _vm.show = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-divider'), _vm.item.thumb ? _c('v-img', {
    attrs: {
      "src": _vm.createObjectURL(_vm.item.thumb),
      "contain": ""
    }
  }) : _vm._e(), _c('v-file-input', _vm._b({
    staticClass: "ma-3",
    attrs: {
      "label": "썸네일",
      "placeholder": "282*400(px)"
    },
    model: {
      value: _vm.item.thumb,
      callback: function ($$v) {
        _vm.$set(_vm.item, "thumb", $$v);
      },
      expression: "item.thumb"
    }
  }, 'v-file-input', _vm.inputAttrs, false)), !_vm.code ? [_c('v-select', _vm._b({
    staticClass: "ma-3",
    attrs: {
      "label": "카테고리",
      "items": _vm.categories,
      "item-text": "name",
      "item-value": "_id"
    },
    model: {
      value: _vm.item._category,
      callback: function ($$v) {
        _vm.$set(_vm.item, "_category", $$v);
      },
      expression: "item._category"
    }
  }, 'v-select', _vm.inputAttrs, false))] : _vm._e(), _c('v-text-field', _vm._b({
    staticClass: "ma-3",
    attrs: {
      "label": "제목"
    },
    model: {
      value: _vm.item.name,
      callback: function ($$v) {
        _vm.$set(_vm.item, "name", $$v);
      },
      expression: "item.name"
    }
  }, 'v-text-field', _vm.inputAttrs, false)), _c('v-file-input', _vm._b({
    staticClass: "ma-3",
    attrs: {
      "label": "파일"
    },
    model: {
      value: _vm.item.file,
      callback: function ($$v) {
        _vm.$set(_vm.item, "file", $$v);
      },
      expression: "item.file"
    }
  }, 'v-file-input', _vm.inputAttrs, false)), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "grey"
    },
    on: {
      "click": _vm.init
    }
  }, [_vm._v("초기화")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-layout justify-center>
        <v-responsive max-width="1080" width="100%">
            <list-heading title="Digital Guide S/W" class="mt-8" />
            <v-tabs show-arrows>
                <template v-for="{ _id, name } in softwares">
                    <v-tab :key="_id" :to="`${baseRoute}/${_id}`" exact>
                        <div class="text-truncate" style="max-width: 100px">{{ name || "-" }}</div>
                    </v-tab>
                    <v-divider vertical :key="`${_id}-divider`" />
                </template>
                <v-spacer />
                <v-tab :to="baseRoute" exact> <v-icon>mdi-plus</v-icon> </v-tab>
            </v-tabs>
            <v-divider />
            <v-tabs-items :value="_software" touchless :continuous="false">
                <template v-for="(software, i) in softwares">
                    <software-view v-model="softwares[i]" :key="software?._id" @remove="remove" />
                </template>
                <software-view @input="(item) => softwares.push(item)" />
            </v-tabs-items>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import SoftwareView from "@/components/console/membership/software-view.vue";

export default {
    components: {
        ListHeading,
        SoftwareView,
    },
    props: {
        _software: { type: String, default: "create" },
    },
    data: () => ({
        softwares: [],
        baseRoute: "/console/membership-softwares",
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            const { softwares } = await api.console.membership.softwares.gets();
            this.softwares = softwares;
        },
        async remove(item) {
            const go = confirm(`${item.name} - 데이터를 삭제하시겠습니까?`);
            if (go) {
                await api.console.membership.softwares.delete({ _id: item._id });
                this.softwares = this.softwares.filter((software) => software?._id != item._id);

                let path = this.baseRoute;

                let { _id } = this.softwares[0] || {};
                if (_id) path += `/${_id}`;

                this.$router.replace(path);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    td.white-space-pre-line {
        white-space: pre-line;
    }
    td.text-truncate {
        max-width: 0;
    }
}
</style>

<template>
    <v-layout justify-center>
        <v-responsive max-width="800" width="100%">
            <list-heading title="지역 관리" class="mt-8" />
            <v-tabs class="mt-4">
                <v-tab to="/console/zones">목록</v-tab>
                <v-tab to="/console/zone-order">순서</v-tab>
                <!-- <v-tab to="/console/zone-tree">계층</v-tab> -->
                <v-spacer />
                <v-fade-transition>
                    <v-btn v-show="tab == 'zones'" v-model="showsSearch" plain text tile height="100%" color="primary" @click="showsSearch = !showsSearch">필터</v-btn>
                </v-fade-transition>
            </v-tabs>
            <v-divider />
            <v-tabs-items v-model="tab" touchless>
                <v-tab-item value="zones">
                    <zone-list v-bind="{ showsSearch }" />
                </v-tab-item>
                <v-tab-item value="zone-order">
                    <zone-order v-bind="{ showsSearch }" />
                </v-tab-item>
                <v-tab-item value="zone-tree">
                    <zone-tree />
                </v-tab-item>
            </v-tabs-items>
        </v-responsive>
    </v-layout>
</template>

<script>
import ZoneList from "@/components/console/zones/zone-list.vue";
import ZoneTree from "@/components/console/zones/zone-tree.vue";
import ZoneOrder from "@/components/console/zones/zone-order.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";

export default {
    components: {
        ZoneList,
        ZoneTree,
        ZoneOrder,
        ListHeading,
    },
    data: () => ({
        showsSearch: false,
    }),
    computed: {
        tab() {
            return this.$route.path.match(/\/console\/([a-zA-Z\-]*)/)?.[1] || "zones";
        },
    },
};
</script>

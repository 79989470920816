<template>
    <v-data-table v-model="users" v-bind="{ headers, items }" dense show-select :items-per-page="5" @input="emit">
        <template #top>
            <v-card-subtitle class="grey lighten-4 caption">
                발급 대상 선택
            </v-card-subtitle>
            <v-divider />
            <v-text-field v-model="filter.searchValue" rounded outlined class="pa-4" v-bind="inputAttrs" append-outer-icon="mdi-magnify" clearable @keydown.enter="search" @click:append-outer="search">
                <template #prepend-inner>
                    <v-select v-model="filter.searchKey" :items="searchKeys" hide-details class="inner-select" />
                </template>
            </v-text-field>
            <v-divider />
        </template>
        <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
        <template #footer>
            <v-divider />
            <v-chip-group class="px-4">
                <div v-if="users.length < 1" class="d-flex subtitle-2 py-2">발급 대상을 선택해주세요</div>
                <v-chip v-for="user in users" :key="user._id" close @click:close="remove(user)">{{ user.name }}</v-chip>
            </v-chip-group>
        </template>
    </v-data-table>
</template>

<script>
import ListSearch from "../dumb/list-search.vue";
import { inputAttrs, USER_TYPES } from "@/assets/variables";
import api from "@/api";

const searchKeys = [
    { text: "아이디", value: "username" },
    { text: "이름", value: "name" },
    { text: "이메일", value: "email" },
    { text: "연락처", value: "phone" },
];

const headers = [
    { text: "아이디", value: "username" },
    { text: "이름", value: "name" },
    { text: "이메일", value: "email" },
    { text: "연락처", value: "phone" },
];

export default {
    components: {
        ListSearch,
    },
    props: {
        value: { type: Array, default: () => [] }, // _users
    },
    data: () => ({
        filter: {
            type: USER_TYPES.PERSON.value,
            searchKey: "username",
            searchValue: null,
        },
        headers,
        inputAttrs,
        searchKeys,

        items: [],
        users: [],
    }),
    mounted() {
        this.search();
    },
    methods: {
        emit() {
            const _users = this.users.map(({ _id }) => _id);
            this.$emit("input", _users);
        },
        remove(user) {
            const index = this.users.findIndex((item) => item == user);
            this.users.splice(index, 1);
            this.emit();
        },
        async search() {
            const params = this.filter;
            const { users } = await api.console.users.gets({ params });
            this.items = users;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .v-input__prepend-inner {
    width: 100px;
    margin-top: 4px !important;
}
::v-deep .v-input__prepend-inner .v-input__slot {
    &::before,
    &::after {
        display: none;
    }
    .v-input__append-inner {
        margin: 3px 0 !important;
    }
}
.inner-select {
    margin: 0 !important;
    padding: 0 !important;
}
::v-deep td.text-truncate {
    max-width: 0;
}
</style>

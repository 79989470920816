<template>
    <v-dialog v-model="show" max-width="400">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card>
            <v-card-title>
                {{ title }}
                <v-btn text icon absolute right @click="show = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider />
            <v-img v-if="item.thumb" :src="createObjectURL(item.thumb)" contain></v-img>
            <v-file-input label="썸네일" v-model="item.thumb" v-bind="inputAttrs" placeholder="282*400(px)" class="ma-3" />
            <template v-if="!code">
                <v-select label="카테고리" v-model="item._category" :items="categories" item-text="name" item-value="_id" v-bind="inputAttrs" class="ma-3" />
            </template>
            <v-text-field label="제목" v-model="item.name" v-bind="inputAttrs" class="ma-3" />
            <v-file-input label="파일" v-model="item.file" v-bind="inputAttrs" class="ma-3" />
            <v-divider />
            <v-card-actions>
                <v-btn outlined color="grey" @click="init">초기화</v-btn>
                <v-spacer />
                <v-btn color="primary" @click="save">저장</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import { inputAttrs } from "@/assets/variables";
const initItem = (item = {}) => ({
    _category: item?._category || null,
    code: item?.code || null,
    name: item?.name || null,
    file: item?.file || null,
});
const CODES = {
    ["abstracts"]: "Abstract",
};
export default {
    props: {
        value: { type: Object, default: () => ({}) },
        code: { type: String, default: null },
        categories: { type: Array, default: () => [] },
    },
    data: () => ({
        show: false,
        item: initItem(),
        inputAttrs,
    }),
    computed: {
        isCreate() {
            return !this.value?._id;
        },
        title() {
            const title = CODES[this.code] || "Catalog";
            return this.isCreate ? `${title} 생성` : `${title} 수정`;
        },
        src() {
            if (this.item?.file) return URL.createObjectURL(this.item?.file);
            else return null;
        },
    },
    watch: {
        show(show) {
            if (show) this.init();
        },
    },
    methods: {
        async init() {
            this.item = initItem({ code: this.code });
            if (!this.isCreate) {
                const { catalogue } = await api.console.shop.catalogues.get({ _id: this.value?._id });
                if (catalogue?.file?.url) catalogue.file = await api.getResource(catalogue.file.url, true);
                if (catalogue?.thumb) catalogue.thumb = await api.getResource(catalogue.thumb, true);
                else catalogue.file = null;

                this.item = catalogue;
            }
        },
        async save() {
            const { post, put } = api.console.shop.catalogues;

            const { _file, file, thumb, ...item } = this.item;

            if (_file) {
                await api.console.commonFiles.delete({ _id: _file });
                item._file = null;
            }
            let { catalogue } = this.isCreate ? await post(item) : await put(item);
            if (thumb) await api.console.shop.catalogues.postThumb(catalogue, thumb);
            if (file) {
                const { file: newFile } = await api.console.commonFiles.post({ directory: catalogue?._id, index: "file" }, file);
                if (newFile) [{ catalogue }] = [await put({ _id: catalogue?._id, _file: newFile?._id })];
            }

            this.$emit("input", catalogue);
            alert("저장되었습니다");
            this.show = false;
        },
        showFileDialog() {
            this.$refs["file-input"]?.$el.getElementsByTagName("input")?.[0]?.click?.();
        },
        createObjectURL(file) {
            try {
                return URL.createObjectURL(file);
            } catch (error) {
                return;
            }
        },
    },
};
</script>

<style></style>

<template>
    <v-layout justify-center>
        <v-responsive max-width="1440" width="100%">
            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">{{ headline }}</v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showsSearch = !showsSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-expand-transition>
                <v-card v-show="showsSearch" class="elevation-1 mx-auto">
                    <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                    <v-divider />
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="3">
                                <v-date-field v-model="filter.createdAt[0]" label="작성일자(시작)" persistent-placeholder dense hide-details />
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-date-field v-model="filter.createdAt[1]" label="작성일자(종료)" persistent-placeholder dense hide-details />
                            </v-col>
                            <v-col cols="6" md="3">
                                <v-select v-model="filter.searchKey" :items="searchKeys" label="검색조건" persistent-placeholder dense hide-details />
                            </v-col>
                            <v-col cols="6" md="3">
                                <v-text-field v-model="filter.searchValue" label="검색어" persistent-placeholder :disabled="!filter.searchKey" dense hide-details />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-actions>
                        <v-spacer />
                        <v-btn color="primary" rounded text @click="onClickSearch"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>

            <v-data-table v-bind="{ ...tableAttrs, items, headers }" show-expand style="overflow:hidden;">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }">{{ header.formatter(value) }}</template>
                <template #[`item.index`]="{ item }"> {{ summary.totalCount - (page - 1) * limit - forms.indexOf(item) }} </template>
                <template #[`item.actions`]="{ item }">
                    <v-icon v-if="!!item.reply" small @click="email(item)" title="이메일">mdi-email</v-icon>
                    <v-icon small @click="edit(item)" title="수정" class="ml-2">mdi-pencil</v-icon>
                    <v-icon small @click="remove(item)" title="삭제" class="ml-2">mdi-delete</v-icon>
                </template>
                <template #expanded-item="{headers, item}">
                    <td :colspan="headers.length" class="pa-0">
                        <v-card flat tile color="transparent">
                            <v-row no-gutters>
                                <v-col cols="2"> <v-card-subtitle>Inquiry</v-card-subtitle> </v-col>
                                <v-col cols="auto"> <v-divider vertical /> </v-col>
                                <v-col> <v-card-text class="white-space-pre-line" v-html="item.content" /> </v-col>
                            </v-row>
                            <v-divider />
                            <v-row no-gutters>
                                <v-col cols="2"> <v-card-subtitle>Other Message</v-card-subtitle> </v-col>
                                <v-col cols="auto"> <v-divider vertical /> </v-col>
                                <v-col> <v-card-text class="white-space-pre-line" v-html="item.meta.message" /> </v-col>
                            </v-row>
                        </v-card>
                    </td>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="search()"></v-pagination>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";

export default {
    components: {
        VDateField,
    },
    data() {
        return {
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showsSearch: true,

            category: null,
            filter: {
                code: this.$route.query.code,
                searchKey: this.$route.query.searchKey,
                searchValue: this.$route.query.searchValue,
                createdAt: [(this.$route.query.createdAt || [])[0], (this.$route.query.createdAt || [])[1]],
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            summary: { totalCount: 0 },
            forms: [],

            searchKeys: [
                { text: "전체", value: null },
                // { text: "작성자", value: "writer.name" },
                { text: "제목", value: "subject" },
                { text: "내용", value: "content" },
            ],
            searchKey: null,
            searchValue: null,
        };
    },
    computed: {
        isUserConsole() {
            return (this.$store.state.payload?.scope || []).includes("console");
        },
        code() {
            return this.$route.query.code;
        },
        headline() {
            if (this.code == "product-inquiry") return "프로덕트 문의 목록";
            return "폼메일 목록";
        },
        items() {
            return this.forms;
        },
        headers() {
            return [
                { width: 50, value: "index", divider: true },
                { width: 180, text: "담당지사", value: "meta.assignedBranches", formatter: (value = []) => value.map(({ company }) => company?.name).join(", "), divider: true, shows: this.isUserConsole },
                { width: 100, text: "작성자", value: "writer", formatter: ({ name, phone, email } = {}) => `name:    ${name}\nemail:   ${email}\ncontact: ${phone}`, cellClass: "white-space-pre font-family-monospace line-height-1-2 caption", divider: true },
                { width: 90, text: "구분", value: "meta.isNewUser", formatter: (value) => (value ? "New" : "Existing"), divider: true },
                { width: 130, text: "거래이력", value: "meta.hasUsedDentium", formatter: (value) => (value ? "Used or in use" : "Never used"), divider: true },
                { text: "관심제품", value: "meta.categories", formatter: (value = []) => value.map(({ name }) => name).join(", "), cellClass: "text-truncate", divider: true },
                { text: "제품명", value: "meta.products", formatter: (value = []) => value.map(({ name }) => name).join(", "), cellClass: "text-truncate", divider: true },
                { width: 250, text: "제목", value: "subject", shows: !["product-inquiry"].includes(this.code) },
                { width: 170, text: "작성일", value: "createdAt", formatter: (value) => value.toDateTime(), divider: true },
                // { width: 170, text: "답변이메일전송일", value: "emailedAt", formatter: (value) => value?.toDateTime?.() || "-" },
                { width: 100, text: "", value: "actions", align: "right" },
            ].filter(({ shows = true }) => shows);
        },
        tableAttrs() {
            return {
                "disable-sort": true,
                "disable-pagination": true,
                "hide-default-footer": true,
                class: "elevation-1 mt-4",
            };
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.search(false);
        },
        async search(routable = true) {
            try {
                if (routable) {
                    this.$router.push({
                        query: Object.assign({}, this.filter, { page: this.page }),
                    });
                }

                var { summary, forms } = await api.console.forms.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.filter,
                });

                this.forms = forms;
                this.summary = summary;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        edit(form) {
            this.$router.push(`/console/forms/${form._id}`);
        },
        async email(form) {
            try {
                if (confirm(`${form.isEmailed ? "답변 이메일 전송 이력이 있습니다.\n계속해서 " : ""}답변 이메일을 전송하시겠습니까?`)) {
                    await api.console.forms.email(form);
                    await this.search(false);

                    alert("전송되었습니다");
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async remove(form) {
            try {
                if (confirm("폼메일을 삭제하시겠습니까?")) {
                    await api.console.forms.delete(form);
                    await this.search(false);

                    alert("삭제되었습니다");
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        onClickSearch() {
            this.page = 1;
            this.search();
        },
    },
    watch: {
        $route() {
            this.search(false);

            Object.assign(this.filter, this.$route.query);
            this.page = +this.$route.query.page || 1;
        },
    },
};
</script>
<style lang="scss" scoped>
::v-deep {
    td.text-truncate {
        max-width: 0 !important;
    }
    td.line-height-1-2 {
        line-height: 1.2 !important;
    }
    td.white-space-pre {
        white-space: pre !important;
    }
    td.font-family-monospace {
        font-family: "NanumGothicCoding", "DejaVu Sans Mono", monospace !important;
    }
    .white-space-pre-line {
        white-space: pre-line !important;
    }
}
</style>

<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="720" width="100%" height="100%" class="px-3">
            <list-heading :title="isCreate ? '상품 등록' : '상품 상세'" class="mt-8" />

            <v-card class="my-4" style="overflow: hidden">
                <v-card-subtitle class="grey lighten-4 font-weight-bold">기본정보</v-card-subtitle>
                <v-divider />
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <category-select v-model="product._category" v-bind="{ categories }" outlined dense hide-details />
                        </v-col>
                        <v-col col="12" sm="6">
                            <v-select label="유형" v-model="product.type" :items="productTypes" v-bind="inputAttrs" />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field label="상품명" v-model="product.name" v-bind="inputAttrs" />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-select label="카탈로그" v-model="product._catalogue" :items="[{ _id: null, name: '없음' }].concat(catalogues)" item-text="name" item-value="_id" v-bind="inputAttrs">
                                <template #append-outer>
                                    <catalogue-view v-bind="{ categories }" @input="(catalogue) => (product._catalogue = catalogue?._id) && catalogues.push(catalogue)">
                                        <template #activator="{ attrs, on }">
                                            <v-btn text icon tile color="primary" title="신규 생성" class="ml-3" v-bind="attrs" v-on="on">
                                                <v-icon>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                    </catalogue-view>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <product-image-download v-model="product" v-bind="inputAttrs" label="이미지 다운로드" ref="image-download" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider />

                <v-card-subtitle class="grey lighten-4 font-weight-bold">이미지</v-card-subtitle>
                <v-divider />
                <v-row no-gutters>
                    <v-col cols="12" sm=""> <product-thumbnail v-model="product" ref="thumbnail" /> </v-col>
                    <v-col cols="auto"> <v-divider vertical /> </v-col>
                    <v-col cols="12" sm=""> <product-image-mobile v-model="product" ref="image-mobile" /> </v-col>
                    <v-col cols="auto"> <v-divider vertical /> </v-col>
                    <v-col cols="12" sm=""> <product-image-desktop v-model="product" ref="image-desktop" /> </v-col>
                </v-row>

                <v-card-subtitle class="grey lighten-4 font-weight-bold">상세정보</v-card-subtitle>
                <v-divider />
                <v-card-text>
                    <naver-smarteditor v-model="product.content" bSkipXssFilter />
                </v-card-text>
                <v-divider />

                <product-documents v-model="product.documents" v-bind="{ documents }" />
                <v-divider />

                <v-card-subtitle class="grey lighten-4 font-weight-bold">연관상품</v-card-subtitle>
                <v-divider />
                <autocomplete-product v-model="product._products__related" :params="params__productsRelated" multiple customSelection flat solo class="rounded-0" hide-details>
                    <template #selection="{ item, parent }">
                        <v-chip text outlined label close @click:close="parent.selectItem(item)"> {{ item.name }}</v-chip>
                    </template>
                </autocomplete-product>
            </v-card>

            <v-row justify="center">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { inputAttrs, PRODUCT_TYPES, ZONE_TYPES } from "@/assets/variables";

import ListHeading from "@/components/console/dumb/list-heading.vue";
import CatalogueView from "@/components/console/shop/catalogue-view.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import ProductThumbnail from "@/components/console/shop/products/product-thumbnail.vue";
import ProductDocuments from "@/components/console/shop/products/product-documents.vue";
import ProductImageMobile from "@/components/console/shop/products/product-image-mobile.vue";
import AutocompleteProduct from "@/components/dumb/autocomplete-product.vue";
import ProductImageDesktop from "@/components/console/shop/products/product-image-desktop.vue";
import CategorySelect from "@/components/console/shop/categories/category-select.vue";
import ProductImageDownload from "@/components/console/shop/products/product-image-download.vue";

const productTypes = Object.values(PRODUCT_TYPES);

export default {
    components: {
        ListHeading,
        CatalogueView,
        NaverSmarteditor,
        ProductThumbnail,
        ProductDocuments,
        ProductImageMobile,
        AutocompleteProduct,
        ProductImageDesktop,
        CategorySelect,
        ProductImageDownload,
    },
    props: {
        _product: { type: String, default: null },
    },
    data: () => ({
        inputAttrs,
        ready: false,
        ///////////////////////////////////////////////
        // 상품정보
        ///////////////////////////////////////////////
        product: {
            _slides: [],
            _category: null,
            _thumbnail: null,
            _catalogue: null,
            _countries: [],
            _products__related: [],

            name: null,
            slides: [],
            summary: null,
            content: null,
            category: null,
            thumbnail: null,
            catalogue: null,
        },

        countries: [],
        documents: [],
        catalogues: [],
        categories: [],

        productTypes,
    }),
    computed: {
        isCreate() {
            return !this._product;
        },
        params__productsRelated() {
            return { _id: JSON.stringify({ $ne: this._product }) };
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            if (!this.isCreate) {
                var { product } = await api.console.shop.products.get({ _id: this._product });
                this.product = product;
            }

            const { zones } = await api.console.shop.zones.gets({ params: { sort: { name: 1 }, type: ZONE_TYPES.COUNTRY.value } });
            this.countries = zones;

            var { documents } = await api.console.shop.documents.gets();
            this.documents = documents;

            var { catalogues } = await api.console.shop.catalogues.gets();
            this.catalogues = catalogues;

            var { categories } = await api.console.shop.categories.gets({ params: { depth: 1 } });
            this.categories = categories;

            this.ready = true;
        },
        async save() {
            try {
                ////////////////////////////////////////////////////////////////
                // 1. 기본정보 및 상세정보
                ////////////////////////////////////////////////////////////////
                const { post, put } = api.console.shop.products;

                let { _countries = [], countries = [], _documents = [], documents = [], _thumbnail, thumbnail, _slides = [], slides = [], _image__download, image__download, ...product } = this.product;

                _countries = countries.map(({ _id }) => _id);
                _documents = documents.map(({ _id }) => _id);

                [{ product }] = [await (this.isCreate ? post({ ...product, _countries }) : put({ ...product, _countries, _documents }))];
                this.product = { ...product, _countries, countries, _documents, documents, _thumbnail, thumbnail, _slides, slides, _image__download, image__download };

                await this.$refs["thumbnail"]?.save?.();

                await this.$refs["image-mobile"]?.save?.();

                await this.$refs["image-desktop"]?.save?.();

                await this.$refs["image-download"]?.save?.();
                ////////////////////////////////////////////////////////////////
                // 2. 자료
                ////////////////////////////////////////////////////////////////
                this.$nextTick(async () => {
                    await this.$refs["slide-list"]?.save?.();
                    const { _id: _product } = this.product;
                    [{ _slides, slides }] = [this.product];
                    const _slidesDeleted = _slides.filter((_slide) => !slides.some(({ _id }) => _id == _slide));
                    await Promise.all(_slidesDeleted.map(async (_id) => await api.console.commonFiles.delete({ _id, _product })));

                    [{ product }] = [await put({ _id: _product, _slides: slides.map(({ _id }) => _id) })];
                    alert("저장되었습니다");
                    this.init();
                });
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
::v-deep .v-input__append-outer {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    margin: 0 !important;
}
</style>

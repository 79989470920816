var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "px-3",
    attrs: {
      "max-width": "720"
    }
  }, [_c('list-heading', {
    staticClass: "mt-8",
    attrs: {
      "title": "프로덕트 상단 배너 설정"
    }
  }), _c('v-card', {
    staticClass: "my-4",
    staticStyle: {
      "overflow": "hidden"
    }
  }, [_c('v-row', {
    staticStyle: {
      "min-height": "calc(100vh - 48px - 12px - 32px - 56px - 16px - 16px - 12px)"
    },
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticStyle: {
      "border-right": "1px solid rgba(0,0,0,0.12)"
    },
    attrs: {
      "cols": "6"
    }
  }, [_c('banner-input', {
    attrs: {
      "label": "Desktop",
      "sublabel": "(1920*500px)",
      "name": "프로덕트 상단 배너 Desktop",
      "code": "product-top-banners-desktop"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('banner-input', {
    attrs: {
      "label": "Mobile",
      "sublabel": "(720*480px)",
      "name": "프로덕트 상단 배너 Mobile",
      "code": "product-top-banners-mobile"
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-layout justify-center>
        <v-responsive max-width="1600" width="100%">
            <list-heading v-model="showsSearch" title="지사 관리" class="mt-8" />
            <v-tabs class="mt-4">
                <v-tab to="/console/branches">목록</v-tab>
                <v-tab to="/console/branch-assignment">지역배정</v-tab>
            </v-tabs>
            <v-divider />
            <v-tabs-items v-model="tab" class="mb-4">
                <v-tab-item value="branches">
                    <v-data-table :items="items" :headers="headers" hide-default-footer disable-sort>
                        <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                        <template #[`item.company.websites`]="{ value }">
                            <v-btn v-for="{ icon, href } in value || []" :key="href" icon text target="_blank" v-bind="{ href }">
                                <v-icon> {{ icon }} </v-icon>
                            </v-btn>
                        </template>
                        <template #[`item.actions`]="{ item }">
                            <branch-view :value="item" v-bind="{ zones }" @input="save">
                                <template #activator="{ attrs, on }">
                                    <v-btn v-bind="attrs" v-on="on" text icon color="primary"><v-icon>mdi-pencil</v-icon></v-btn>
                                </template>
                            </branch-view>
                            <v-btn icon text color="red" @click="remove(item)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </template>
                        <template #footer>
                            <v-divider />
                            <v-row class="mx-0">
                                <v-spacer />
                                <v-col cols="auto">
                                    <branch-view v-bind="{ zones }" @input="save">
                                        <template #activator="{ attrs, on }">
                                            <v-btn large text icon color="primary" v-bind="attrs" v-on="on">
                                                <v-icon large>mdi-plus</v-icon>
                                            </v-btn>
                                        </template>
                                    </branch-view>
                                </v-col>
                            </v-row>
                        </template>
                    </v-data-table>
                    <v-divider />
                    <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="py-4" @input="search()" />
                </v-tab-item>
                <v-tab-item value="branch-assignment">
                    <branch-assignment v-model="branches" v-bind="{ zones }" />
                </v-tab-item>
            </v-tabs-items>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import BranchView from "@/components/console/branches/branch-view.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import BranchAssignment from "@/components/console/branches/branch-assignment.vue";
import { BRANCH_GROUPS, COMPANY_WEBSITE_TYPES } from "@/assets/variables";
const headers = [
    { value: "index", width: 60, divider: true, align: "right" },
    { text: "그룹", value: "company.group", width: 160, divider: true, cellClass: "text-truncate", formatter: (key) => BRANCH_GROUPS[key]?.text || "-" },
    { text: "지역", value: "zone.fullName", width: 199, divider: true, cellClass: "text-truncate" },
    { text: "지사명/연락처/팩스", value: "company", width: 270, divider: true, cellClass: "white-space-pre-line caption", formatter: ({ name, phone, fax }) => `${name}\nt: ${phone} / f: ${fax}` },
    { text: "이메일", value: "company.email", divider: true, width: 180 },
    { text: "주소", value: "company.address", divider: true, width: 460 },
    { text: "웹사이트", value: "company.websites", divider: true, width: 161, cellClass: "px-2" },
    { text: "", value: "actions", width: 110, align: "right" },
];

export default {
    components: {
        BranchView,
        ListHeading,
        BranchAssignment,
    },
    data() {
        return {
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showsSearch: true,

            filter: {
                searchKey: this.$route.query.searchKey || null,
                searchValue: this.$route.query.searchValue || null,
                mode_auction: this.$route.query.mode_auction || null,
            },

            page: +this.$route.query.page || 1,
            totalCount: 0,
            limit: 10,

            headers,
            zones: [],
            branches: [],
        };
    },
    computed: {
        tab() {
            return this.$route.path.match(/\/console\/([a-zA-Z\-]*)/)?.[1] || "branches";
        },
        pageCount() {
            return Math.ceil(this.totalCount / this.limit) || 1;
        },
        items() {
            return (this.branches || []).map((item, index) => ({ ...item, index: +this.totalCount - (+this.page - 1) * +this.limit - index }));
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        $route() {
            this.search(false);

            Object.assign(this.filter, this.$route.query);
            this.page = +this.$route.query.page || 1;
        },
        "filter.mode_auction"() {
            this.branches = [];
            this.page = 1;
            this.search(true);
        },
    },
    methods: {
        async init() {
            const path = `/console/${this.tab}`;
            if (this.$route.path != path) this.$router.replace(path);

            const { zones } = await api.console.shop.zones.gets({ params: { sort: { fullName: 1 } } });
            this.zones = (zones || []).sort((a, b) => a?.fullName?.localeCompare?.(b?.fullName));

            this.search(false);
        },
        manualSearch() {
            this.page = 1;
            this.search();
        },
        async search(routable = true) {
            if (routable) this.$router.push({ query: Object.assign({}, this.filter, { page: this.page }) });

            var {
                summary: { totalCount },
                branches,
            } = await api.console.branches.gets({
                headers: { skip: (this.page - 1) * this.limit, limit: this.limit },
                params: this.filter,
            });

            this.branches = branches;
            this.totalCount = totalCount;
        },
        async remove(item) {
            const go = confirm(`지사를 삭제하시겠습니까?\n\n지사명:    "${item?.branch?.name || ""}`);
            if (!go) return;

            await api.console.branches.delete(item);
            this.branches = this.branches.filter((content) => content._id !== item._id);
            alert("지사가 삭제되었습니다.");
        },
        async save(item, callback = () => {}) {
            // pre save
            item = JSON.parse(JSON.stringify(item));
            if (item?.password) {
                item.password = CryptoAES.encrypt(item.password);
            }

            const isCreate = !item?._id;
            const { post, put } = api.console.branches;
            const { branch } = isCreate ? await post(item) : await put(item);
            alert("저장되었습니다.");
            this.update(branch);
            callback();
        },
        async update(item) {
            const index = this.branches.findIndex(({ _id }) => _id == item._id);
            if (index > -1) this.branches.splice(index, 1, item);
            else {
                this.branches = [item, ...this.branches];
                this.totalCount += 1;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    td.text-truncate {
        max-width: 0px;
    }
    td.white-space-pre-line {
        white-space: pre-line;
        line-height: 1.25;
    }
}
</style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    attrs: {
      "tile": ""
    }
  }, [_c('v-layout', {
    staticClass: "grey lighten-4",
    attrs: {
      "align-center": ""
    }
  }, [_c('v-card-subtitle', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" 도큐먼트 ")]), _c('v-spacer'), _c('document-view', {
    on: {
      "input": _vm.pushItem
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "ml-3",
          attrs: {
            "text": "",
            "icon": "",
            "tile": "",
            "color": "primary",
            "title": "신규 생성"
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
      }
    }])
  })], 1), _c('v-divider'), _c('v-card-text', [_c('draggable', {
    staticClass: "row",
    attrs: {
      "draggable": ".draggable-item"
    },
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, [_vm._l(_vm.selected, function (document) {
    return _c('v-col', {
      key: document._id,
      staticClass: "draggable-item",
      attrs: {
        "cols": "12",
        "sm": "6"
      }
    }, [_c('v-card', {
      attrs: {
        "outlined": "",
        "tile": ""
      }
    }, [_c('v-card-title', [_vm._v(" " + _vm._s(document.name) + " "), _c('v-spacer'), _c('v-btn', {
      attrs: {
        "text": "",
        "icon": ""
      },
      on: {
        "click": function ($event) {
          return _vm.pullItem(document);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-card-text', [_vm._v(_vm._s(document.desc))]), _c('v-card-actions', [_c('v-btn', {
      attrs: {
        "color": "primary",
        "download": "",
        "href": document.url
      }
    }, [_vm._v("Download")])], 1)], 1)], 1);
  }), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-dialog', {
    attrs: {
      "width": "240"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var attrs = _ref2.attrs,
          on = _ref2.on;
        return [_c('v-card', {
          attrs: {
            "outlined": "",
            "tile": ""
          }
        }, [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "text": "",
            "block": ""
          }
        }, 'v-btn', attrs, false), on), [_vm._v(" 목록에서 추가하기 ")])], 1)];
      }
    }])
  }, [_c('v-select', {
    attrs: {
      "multiple": "",
      "items": _vm.documents,
      "item-text": "name",
      "item-value": "_id",
      "return-object": "",
      "solo": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })], 1)], 1)], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-chip-group>
        <draggable v-model="files" draggable=".file-item" @input="emit">
            <template v-for="(file, index) in files">
                <software-file-item :key="file?.id" ref="file-item" :value="file" v-bind="{ software: value, index }" @input="update" @remove="remove(file)" />
            </template>
            <template #footer>
                <v-chip v-if="(files?.length || 0) < 1" small color="transparent" class="pa-0" @click="$refs['file-input']?.$el.getElementsByTagName('input')?.[0]?.click?.()">
                    <v-icon>mdi-plus</v-icon>
                    <v-file-input v-model="file" v-show="false" ref="file-input" />
                </v-chip>
            </template>
        </draggable>
    </v-chip-group>
</template>

<script>
import Draggable from "vuedraggable";
import SoftwareFileItem from "./software-file-item.vue";
export default {
    components: {
        Draggable,
        SoftwareFileItem,
    },
    props: {
        value: { type: Object, default: () => ({}) }, // software
    },
    data: () => ({
        file: null,
        files: [],
    }),
    mounted() {
        this.init();
    },
    watch: {
        value() {
            this.init();
        },
        file(file) {
            if (!!file) {
                this.files.push(file);
                this.file = null;

                const { files } = this;
                this.$emit("input", { ...this.value, files });
            }
        },
    },
    methods: {
        init() {
            this.files = this.value.files;
        },

        emit() {
            const { files } = this;
            this.$emit("input", { ...this.value, files });
        },

        update(item) {
            const index = this.files.findIndex(({ name }) => name == item.name);
            if (index > -1) this.files.splice(index, 1, item);
            else this.files.push(item);
            this.emit();
        },

        remove(file) {
            this.files = this.files.filter((item) => item != file);
            this.emit();
        },

        async save() {
            for (const ref of this.$refs["file-item"] || []) {
                await ref?.save?.();
            }
            this.emit();
        },

        showFileDialog() {
            this.$refs["file-input"]?.$el.getElementsByTagName("input")?.[0]?.click?.();
        },
    },
};
</script>

<style></style>

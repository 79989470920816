<template>
    <v-layout justify-center>
        <v-responsive max-width="720" width="100%">
            <list-heading title="강연자 관리" class="mt-8" />
            <v-data-table v-bind="{ headers, items }" hide-default-footer disable-sort disable-filtering disable-pagination show-expand class="my-4">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.src`]="{ value }">
                    <image-popup size="40" :src="value" />
                </template>
                <template #[`item.isOn`]="{ item, value }">
                    <v-switch v-model="item.isOn" inset dense hide-details class="mt-0 ml-1 mr-n4 pa-0" @change="(isOn) => save({ _id: item._id, isOn })" />
                    <span class="caption">{{ value ? "진행중" : "종료" }}</span>
                </template>
                <template #[`item.actions`]="{ item }">
                    <lecturer-view :value="item" @input="update">
                        <template #activator="{attrs,on}">
                            <v-btn small text icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                    </lecturer-view>
                    <v-btn small text icon @click="remove(item)">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </template>
                <template #expanded-item="{ item, headers}">
                    <td :colspan="headers.length" style="white-space: pre-line;">{{ item.desc }}</td>
                </template>
                <template #footer>
                    <v-divider />
                    <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="py-3" @input="search" />
                </template>
            </v-data-table>
            <lecturer-view @input="update">
                <template #activator="{attrs,on}">
                    <v-btn x-large fab color="primary" fixed right bottom v-bind="attrs" v-on="on">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </template>
            </lecturer-view>
        </v-responsive>
    </v-layout>
</template>

<script>
import lecturerView from "@/components/console/academy/lecturer-view.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import api from "@/api";
import ImagePopup from "@/components/console/dumb/image-popup.vue";

const headers = [
    { width: 40, value: "index", align: "right" },
    { width: 80, text: "썸네일", value: "src" },
    { text: "직함", value: "title" },
    { text: "이름", value: "name" },
    { width: 110, text: "작성일", value: "createdAt", formatter: (value) => value?.toDateTime() },
    { width: 90, value: "actions", align: "right" },
];

export default {
    components: {
        lecturerView,
        ListHeading,
        ImagePopup,
    },
    data: () => ({
        page: 1,
        limit: 10,
        filter: {},
        summary: { totalCount: 0 },

        headers,
        lecturers: [],
    }),
    computed: {
        items() {
            return this.lecturers.map((item, i) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - i }));
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
    },
    mounted() {
        this.init().then(this.search);
    },
    methods: {
        async init() {
            this.page = +this.$route.query.page || 1;
            this.lecturers = [];
            this.summary = { totalCount: 0 };
        },
        async search() {
            try {
                const { page, skip, limit } = this;
                this.$router.push({ query: Object.assignDefined({}, this.$route.query, this.filter, { page }) });

                var { summary, lecturers } = await api.console.academy.lecturers.gets({
                    headers: { skip, limit },
                    params: this.filter,
                });

                this.lecturers = lecturers;
                this.summary = summary;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.email);
                else alert(error.email.toString());
            }
        },
        async update(item) {
            const index = this.lecturers.findIndex(({ _id }) => _id == item._id);
            if (index > -1) this.lecturers.splice(index, 1, item);
            else {
                this.lecturers = [item, ...this.lecturers];
                this.summary.totalCount += 1;
            }
        },
        async remove({ _thumb, _id, name }) {
            const go = confirm(`${name} - 데이터를 삭제하시겠습니까?`);
            if (go) {
                if (_thumb) await api.console.commonFiles.delete({ _id: _thumb });
                await api.console.academy.lecturers.delete({ _id });
                this.lecturers = this.lecturers.filter((lecturer) => lecturer?._id != _id);
            }
        },
        async save(item) {
            const { lecturer } = await api.console.academy.lecturers.put(item);
            this.update(lecturer);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep td.text-truncate {
    max-width: 0;
}
</style>

<template>
    <span v-if="readonly"> {{ item?.name || "-" }} </span>
    <v-autocomplete v-else :value="value" v-bind="{ ...$attrs, items, itemText, itemValue: '_id', multiple }" @change="(changedValue) => $emit('input', changedValue)" @click="alert">
        <slot />
        <template #selection="props" v-if="customSelection">
            <slot name="selection" v-bind="props" />
        </template>
    </v-autocomplete>
</template>

<script>
import api from "@/api";

export default {
    props: {
        value: { type: [String, Array], default: null },
        params: { type: [Object, Array], default: () => ({}) },
        multiple: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },
        customSelection: { type: Boolean, default: false },
    },
    data: () => ({
        items: [],
    }),
    computed: {
        item() {
            return this.items.find(({ _id }) => (this.multiple ? this.value.includes(_id) : _id == this.value));
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.init();
        },
    },
    methods: {
        async init() {
            let { params } = this;
            this.items = (await api.console.shop.products.gets({ params }))?.products || [];
        },
        itemText({ name }) {
            return `${name}`;
        },
        alert() {
            if (this.$attrs.readonly && this.$attrs.readonlyMessage) alert(this.$attrs.readonlyMessage);
            if (this.$attrs.disabled && this.$attrs.disabledMessage) alert(this.$attrs.readonlyMessage);
        },
    },
};
</script>

<style></style>

<template>
    <v-card tile flat>
        <v-expand-transition>
            <!-- continents -->
            <v-list v-show="!loading" expand class="py-0">
                <draggable v-bind="{ list: zoneTree, group: 'continents', draggable: '.continent' }">
                    <template v-for="continent in zoneTree">
                        <v-list-group class="continent" :key="continent._id">
                            <draggable v-bind="{ list: continent.countries, group: 'countries', draggable: '.country' }" slot="activator" style="width:100%">
                                <v-list-item style="position:sticky;">
                                    <span>{{ continent.name }}</span>
                                    <span v-if="continent?.countries?.length">({{ continent?.countries?.length || 0 }})</span>
                                </v-list-item>
                            </draggable>
                            <v-divider />

                            <!-- countries -->
                            <v-list v-if="continent?.countries?.length" min-height="48" max-height="40vh" style="overflow-y: auto" color="rgba(0,0,0,0.05)" class="py-0" expand>
                                <draggable v-bind="{ list: continent.countries, group: 'countries', draggable: '.country' }">
                                    <template v-for="(country, index) in continent.countries">
                                        <v-list-group sub-group :key="country._id" class="country">
                                            <draggable v-bind="{ list: country.regions, group: 'regions', draggable: '.region' }" slot="activator">
                                                <v-list-item slot="header">
                                                    <span>{{ country.name }}</span>
                                                    <span v-if="country?.regions?.length">({{ country?.regions?.length || 0 }})</span>
                                                </v-list-item>
                                            </draggable>
                                            <v-divider />

                                            <!-- regions -->
                                            <v-list v-if="country?.regions?.length" min-height="48" max-height="40vh" style="overflow-y: auto" color="rgba(0,0,0,0.05)" class="py-0" expand>
                                                <draggable v-bind="{ list: country.regions, group: 'regions', draggable: '.region' }">
                                                    <template v-for="(region, index) in country.regions">
                                                        <v-list-item :key="region?._id" class="region">
                                                            <v-list-item-avatar />
                                                            <span>{{ region.name }}</span>
                                                        </v-list-item>
                                                        <v-divider v-if="index != country.regions.length - 1" :key="`${region?._id}-divider`" />
                                                    </template>
                                                </draggable>
                                            </v-list>
                                        </v-list-group>
                                        <v-divider v-if="index != continent.countries.length - 1" :key="`${country._id}-divider`" />
                                    </template>
                                </draggable>
                            </v-list>
                        </v-list-group>
                        <v-divider :key="`${continent._id}-divider`" />
                    </template>
                </draggable>
                <v-fade-transition>
                    <v-overlay v-show="loadingSave" absolute>
                        <v-card width="320" rounded="xl" class="d-flex">
                            <v-progress-linear :value="loadingSaveValue" height="16" />
                        </v-card>
                    </v-overlay>
                </v-fade-transition>
            </v-list>
        </v-expand-transition>
        <v-expand-transition>
            <v-row v-show="!loading" no-gutters>
                <v-spacer />
                <v-col cols="auto">
                    <v-btn plain text tile height="48" color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>
        </v-expand-transition>
        <v-expand-transition>
            <v-sheet v-show="loading" height="50vh">
                <v-overlay light color="white" absolute>
                    <v-progress-circular color="black" size="100" width="5" indeterminate />
                </v-overlay>
            </v-sheet>
        </v-expand-transition>
    </v-card>
</template>

<script>
import Draggable from "vuedraggable";

import api from "@/api";
import { ZONE_TYPES } from "@/assets/variables";

export default {
    components: {
        Draggable,
    },
    data() {
        return {
            zones: [],
            zoneTree: [],
            loading: true,

            loadingSave: false,
            loadingSaveLength: 0,
            loadingSavedLength: 0,
        };
    },
    computed: {
        tab() {
            return this.$route.path.match(/\/console\/([a-zA-Z\-]*)/)?.[1] || "zones";
        },
        countries() {
            return (this.zoneTree || []).flatMap(({ countries }) => countries);
        },
        countriesChanged() {
            return this.countries
                .map((country) => {
                    const _continent = this.zoneTree.find((continent) => (continent?.countries || []).includes(country))?._id || country?._continent;
                    const hasContinentChanged = _continent != country?._continent;

                    if (!hasContinentChanged) return null;
                    else return { ...country, _continent };
                })
                .filter((item) => !!item);
        },
        regions() {
            return (this.countries || []).flatMap(({ regions }) => regions);
        },
        regionsChanged() {
            return this.regions
                .map((region) => {
                    const _country = this.countries.find((country) => (country?.regions || []).includes(region))?._id;
                    const hasCountryChanged = _country != region?._country;

                    const _continent = this.countriesChanged.find(({ _id }) => _id == _country)?._continent || region?._continent;
                    const hasContinentChanged = _continent != region?._continent;

                    if (!hasCountryChanged && !hasContinentChanged) return null;
                    else return { ...region, _continent, _country };
                })
                .filter((item) => !!item);
        },
        loadingSaveValue() {
            return (this.loadingSavedLength / this.loadingSaveLength) * 100;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        "$route.path"(path) {
            if (path.split("/").pop() == "zone-tree") this.search();
        },
    },
    methods: {
        async init() {
            const path = `/console/${this.tab}`;
            if (this.$route.path != path) this.$router.replace({ path });

            this.search();
        },
        async search() {
            var { zones } = await api.console.shop.zones.gets();
            this.zones = zones;

            var { zoneTree } = await api.console.shop.zones.getZonesTree();
            this.zoneTree = zoneTree;

            this.$nextTick(() => (this.loading = false));
        },
        async save() {
            const items = [...this.countriesChanged, ...this.regionsChanged];
            this.loadingSavedLength = 0;
            this.loadingSaveLength = items.length;
            this.loadingSave = true;
            for (const item of items) {
                await api.console.shop.zones.put(item);
                this.loadingSavedLength += 1;
            }
            this.loadingSave = false;
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep td.text-truncate {
    max-width: 0px;
}
</style>

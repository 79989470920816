<template>
    <v-card v-bind="$attrs" @click="openFileInput">
        <v-img v-bind="{ ...$attrs, src }">
            <v-fade-transition>
                <v-overlay v-show="!src" light color="transparent" absolute>
                    <v-icon color="grey">mdi-image</v-icon>
                </v-overlay>
            </v-fade-transition>
        </v-img>
        <v-file-input v-model="file" ref="fileInput" v-bind="$attrs" class="d-none" @change="$emit('input', file)"></v-file-input>
    </v-card>
</template>

<script>
export default {
    props: {
        value: { type: [Object, File], default: null },
    },
    data: () => ({
        file: null,
    }),
    computed: {
        src() {
            if (this.file instanceof File) return URL.createObjectURL(this.file);
            else return this.file?.url;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.file = this.value;
        },
        openFileInput() {
            this.$refs.fileInput.$el.getElementsByTagName("input")[0].click();
        },
    },
};
</script>

<style>
</style>
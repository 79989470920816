var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "console-terms",
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_vm.setting ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "720px",
      "width": "100%"
    }
  }, [_c('v-row', {
    staticClass: "mt-8",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pt-0 pb-0 headline text-start",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("설정")]), _c('v-spacer'), _c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "pt-0 pb-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "color": "secondary",
      "rounded": ""
    },
    on: {
      "click": function ($event) {
        _vm.showsSearch = !_vm.showsSearch;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-filter-variant")]), _vm._v("검색필터")], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v(" Footer 정보 변경 ")]), _c('v-divider'), _c('footer-info', {
    model: {
      value: _vm.setting.footer.info,
      callback: function ($$v) {
        _vm.$set(_vm.setting.footer, "info", $$v);
      },
      expression: "setting.footer.info"
    }
  })], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v(" Footer SNS 변경 ")]), _c('v-divider'), _c('footer-sns', {
    model: {
      value: _vm.setting.footer.sns,
      callback: function ($$v) {
        _vm.$set(_vm.setting.footer, "sns", $$v);
      },
      expression: "setting.footer.sns"
    }
  })], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto pt-0"
    }
  }, [_vm._v("SMTP 설정 (이메일 전송 설정) 변경")]), _c('v-col', {
    staticClass: "primary--text caption pt-0 pl-0"
  }, [_vm._v(" \"Naver 메일 -> 환경설정 -> POP3/IMAP 설정 -> IMAP/SMTP 설정 -> IMAP/SMTP 사용\" 을 사용함으로 변경 ")])], 1)], 1), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "발신자 아이디 (네이버)",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.setting.smtp.username,
      callback: function ($$v) {
        _vm.$set(_vm.setting.smtp, "username", $$v);
      },
      expression: "setting.smtp.username"
    }
  }), _c('v-text-field', {
    staticClass: "mt-6",
    attrs: {
      "type": "password",
      "label": "비밀번호",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.setting.smtp.password,
      callback: function ($$v) {
        _vm.$set(_vm.setting.smtp, "password", $$v);
      },
      expression: "setting.smtp.password"
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', [_c('v-row', [_c('v-col', {
    staticClass: "py-0 subtitle-2 font-weight-bold",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("SEO(검색어 최적화 설정)")])], 1)], 1), _c('v-card-text', [_c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "타이틀: Title",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "input": function ($event) {
        _vm.meta('title').content = _vm.setting.seo.title;
      }
    },
    model: {
      value: _vm.setting.seo.title,
      callback: function ($$v) {
        _vm.$set(_vm.setting.seo, "title", $$v);
      },
      expression: "setting.seo.title"
    }
  }), _c('v-text-field', {
    staticClass: "mt-6",
    attrs: {
      "label": "메타태그: Title",
      "persistent-placeholder": "",
      "disabled": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.meta('title').content,
      callback: function ($$v) {
        _vm.$set(_vm.meta('title'), "content", $$v);
      },
      expression: "meta('title').content"
    }
  }), _c('v-text-field', {
    staticClass: "mt-6",
    attrs: {
      "label": "메타태그: Author",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.meta('author').content,
      callback: function ($$v) {
        _vm.$set(_vm.meta('author'), "content", $$v);
      },
      expression: "meta('author').content"
    }
  }), _c('v-text-field', {
    staticClass: "mt-6",
    attrs: {
      "label": "메타태그: Subject",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.meta('subject').content,
      callback: function ($$v) {
        _vm.$set(_vm.meta('subject'), "content", $$v);
      },
      expression: "meta('subject').content"
    }
  }), _c('v-text-field', {
    staticClass: "mt-6",
    attrs: {
      "label": "메타태그: Copyright",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.meta('copyright').content,
      callback: function ($$v) {
        _vm.$set(_vm.meta('copyright'), "content", $$v);
      },
      expression: "meta('copyright').content"
    }
  }), _c('v-textarea', {
    staticClass: "mt-6",
    attrs: {
      "label": "메타태그: Description",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.meta('description').content,
      callback: function ($$v) {
        _vm.$set(_vm.meta('description'), "content", $$v);
      },
      expression: "meta('description').content"
    }
  }), _c('v-textarea', {
    staticClass: "mt-6",
    attrs: {
      "label": "메타태그: Keywords",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.meta('keywords').content,
      callback: function ($$v) {
        _vm.$set(_vm.meta('keywords'), "content", $$v);
      },
      expression: "meta('keywords').content"
    }
  })], 1)], 1), _c('v-btn', {
    attrs: {
      "fixed": "",
      "bottom": "",
      "right": "",
      "fab": "",
      "x-large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_c('v-icon', [_vm._v("mdi-content-save")])], 1)], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-row class="row--small">
        <v-col cols="auto" v-if="!hideTitle">
            <v-select v-model="name_title" name="name_title" :items="titles" label="Title" style="width: 100px" v-bind="$attrs" @input="emit">
                <template #label> Title <span style="color: #ff0000">*</span> </template>
            </v-select>
        </v-col>
        <v-col>
            <v-text-field v-model="name_first" name="name_first" label="First Name" v-bind="$attrs" hide-details="auto" @input="emit">
                <template #label> First Name <span style="color: #ff0000">*</span> </template>
            </v-text-field>
        </v-col>
        <v-col v-if="!hideMiddle">
            <v-text-field v-model="name_middle" name="name_middle" label="Middle Name" hide-details="auto" v-bind="$attrs" @input="emit">
                <template #label> Middle Name </template>
            </v-text-field>
        </v-col>
        <v-col>
            <v-text-field v-model="name_last" name="name_last" hide-details="auto" v-bind="$attrs" @input="emit">
                <template #label> Last Name <span style="color: #ff0000">*</span> </template>
            </v-text-field>
        </v-col>
    </v-row>
</template>

<script>
import { PERSON_TITLES } from "@/assets/variables";

const titles = Object.values(PERSON_TITLES);

export default {
    props: {
        value: { type: Object, default: () => ({}) }, // person

        hideTitle: { type: Boolean, default: false },
        hideMiddle: { type: Boolean, default: false },
    },
    data: () => ({
        name_title: null,
        name_first: null,
        name_middle: null,
        name_last: null,

        titles,
    }),
    computed: {
        name() {
            let { name_title, name_first, name_middle, name_last } = this;

            if (this.hideTitle) {
                if (!name_first || !name_last) return;
            } else {
                if (!name_title || !name_first || !name_last) return;
            }

            let name = "";
            if (name_title) name += `${PERSON_TITLES[name_title]?.text} `;
            if (name_first) name += `${name_first} `;
            if (name_middle) name += `${name_middle} `;
            if (name_last) name += `${name_last} `;
            return name.trim();
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
        name() {
            this.emit();
        },
    },
    methods: {
        sync() {
            let { name_title, name_first, name_middle, name_last } = this.value;

            this.name_title = name_title || null;
            this.name_first = name_first || null;
            this.name_middle = name_middle || null;
            this.name_last = name_last || null;
        },
        emit() {
            let { name_title, name_first, name_middle, name_last, name } = this;

            this.$emit("input", {
                ...this.value,
                name_title,
                name_first,
                name_middle,
                name_last,
                name,
            });
        },
    },
};
</script>

<style>
</style>
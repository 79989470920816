var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-chip', _vm._b({
    attrs: {
      "small": "",
      "label": "",
      "outlined": "",
      "close": ""
    },
    on: {
      "click:close": function ($event) {
        return _vm.$emit('remove');
      }
    }
  }, 'v-chip', {
    href: _vm.href,
    download: _vm.download
  }, false), [_vm._v(" " + _vm._s(_vm.download) + " "), _c('v-file-input', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "file-input",
    model: {
      value: _vm.file,
      callback: function ($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.setting ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "px-3",
    attrs: {
      "max-width": "720"
    }
  }, [_c('list-heading', {
    staticClass: "mt-8",
    attrs: {
      "title": "메인 비주얼 슬라이드 설정"
    }
  }), _c('v-card', {
    staticClass: "my-4",
    staticStyle: {
      "overflow": "hidden"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticStyle: {
      "border-right": "1px solid rgba(0, 0, 0, 0.12)"
    },
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_c('span', [_vm._v("Desktop")])]), _vm._v(" "), _c('v-divider'), _c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('image-input', {
    attrs: {
      "width": "100%",
      "height": "100%",
      "contain": "",
      "tile": "",
      "flat": "",
      "color": "grey lighten-4"
    },
    model: {
      value: _vm.setting.main.productDesktop,
      callback: function ($$v) {
        _vm.$set(_vm.setting.main, "productDesktop", $$v);
      },
      expression: "setting.main.productDesktop"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_c('span', [_vm._v("Mobile")])]), _vm._v(" "), _c('v-divider'), _c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  }, [_c('image-input', {
    attrs: {
      "width": "100%",
      "height": "100%",
      "contain": "",
      "tile": "",
      "flat": "",
      "color": "grey lighten-4"
    },
    model: {
      value: _vm.setting.main.productMobile,
      callback: function ($$v) {
        _vm.$set(_vm.setting.main, "productMobile", $$v);
      },
      expression: "setting.main.productMobile"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', {
    staticClass: "justify-center"
  }, [_c('v-btn', {
    attrs: {
      "plain": "",
      "text": "",
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("mdi-content-save")]), _c('span', [_vm._v("저장")])], 1)], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }
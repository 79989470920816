var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1600",
      "width": "100%"
    }
  }, [_c('list-heading', {
    staticClass: "mt-8",
    attrs: {
      "title": "지사 관리"
    },
    model: {
      value: _vm.showsSearch,
      callback: function ($$v) {
        _vm.showsSearch = $$v;
      },
      expression: "showsSearch"
    }
  }), _c('v-tabs', {
    staticClass: "mt-4"
  }, [_c('v-tab', {
    attrs: {
      "to": "/console/branches"
    }
  }, [_vm._v("목록")]), _c('v-tab', {
    attrs: {
      "to": "/console/branch-assignment"
    }
  }, [_vm._v("지역배정")])], 1), _c('v-divider'), _c('v-tabs-items', {
    staticClass: "mb-4",
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', {
    attrs: {
      "value": "branches"
    }
  }, [_c('v-data-table', {
    attrs: {
      "items": _vm.items,
      "headers": _vm.headers,
      "hide-default-footer": "",
      "disable-sort": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.company.websites`,
      fn: function (_ref2) {
        var value = _ref2.value;
        return _vm._l(value || [], function (_ref3) {
          var icon = _ref3.icon,
            href = _ref3.href;
          return _c('v-btn', _vm._b({
            key: href,
            attrs: {
              "icon": "",
              "text": "",
              "target": "_blank"
            }
          }, 'v-btn', {
            href
          }, false), [_c('v-icon', [_vm._v(" " + _vm._s(icon) + " ")])], 1);
        });
      }
    }, {
      key: `item.actions`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('branch-view', _vm._b({
          attrs: {
            "value": item
          },
          on: {
            "input": _vm.save
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref5) {
              var attrs = _ref5.attrs,
                on = _ref5.on;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "text": "",
                  "icon": "",
                  "color": "primary"
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-pencil")])], 1)];
            }
          }], null, true)
        }, 'branch-view', {
          zones: _vm.zones
        }, false)), _c('v-btn', {
          attrs: {
            "icon": "",
            "text": "",
            "color": "red"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)];
      }
    }, {
      key: "footer",
      fn: function () {
        return [_c('v-divider'), _c('v-row', {
          staticClass: "mx-0"
        }, [_c('v-spacer'), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('branch-view', _vm._b({
          on: {
            "input": _vm.save
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref6) {
              var attrs = _ref6.attrs,
                on = _ref6.on;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "large": "",
                  "text": "",
                  "icon": "",
                  "color": "primary"
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "large": ""
                }
              }, [_vm._v("mdi-plus")])], 1)];
            }
          }])
        }, 'branch-view', {
          zones: _vm.zones
        }, false))], 1)], 1)];
      },
      proxy: true
    }], null, true)
  }), _c('v-divider'), _c('v-pagination', {
    staticClass: "py-4",
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function ($event) {
        return _vm.search();
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1), _c('v-tab-item', {
    attrs: {
      "value": "branch-assignment"
    }
  }, [_c('branch-assignment', _vm._b({
    model: {
      value: _vm.branches,
      callback: function ($$v) {
        _vm.branches = $$v;
      },
      expression: "branches"
    }
  }, 'branch-assignment', {
    zones: _vm.zones
  }, false))], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-layout justify-center>
        <v-responsive max-width="1240" width="100%">
            <list-heading title="SNS Accounts" class="mt-8" />
            <v-data-table v-bind="{ loading, headers, items }" hide-default-footer disable-sort disable-filtering disable-pagination class="my-4" ref="data-table">
                <template #body="props">
                    <draggable v-if="$refs['data-table']" tag="tbody" :list="props.items" @change="setNewIndex" :sort="!loading">
                        <v-nodes :vnodes="$refs['data-table'].genItems(props.items, props)" />
                    </draggable>
                </template>
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.src`]="{ value }">
                    <image-popup size="40" :src="value" />
                </template>
                <template v-slot:[`item.shows`]="{ item }">
                    <v-row justify="center" align="center">
                        <v-col cols="auto" class="pa-0">
                            <v-switch v-model="item.shows" v-bind="{ ...switchAttrs, loading }" @change="(shows) => update({ _id: item._id, shows })" />
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:[`item.isContentsUpdateScheduled`]="{ item }">
                    <v-row justify="center" align="center">
                        <v-col cols="auto" class="pa-0">
                            <v-switch v-model="item.isContentsUpdateScheduled" v-bind="{ ...switchAttrs, loading }" @change="(isContentsUpdateScheduled) => update({ _id: item._id, isContentsUpdateScheduled })" />
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:[`item.updateContents`]="{ item }">
                    <v-btn small text icon v-bind="{ loading }" @click="updateContents(item)">
                        <v-icon small>mdi-cloud-download</v-icon>
                    </v-btn>
                </template>
                <template #[`item.actions`]="{ item }">
                    <!-- <v-btn small text icon v-bind="{ loading }" :to="`${$route.path}/${item._id}`">
                        <v-icon small>mdi-view-list</v-icon>
                    </v-btn> -->
                    <account-view v-bind="item" @input="update">
                        <template #activator="{ attrs, on }">
                            <v-btn small text icon v-bind="{ ...attrs, loading }" v-on="on">
                                <v-icon small>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                    </account-view>
                    <v-btn small text icon v-bind="{ loading }" @click="remove(item)">
                        <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                </template>
                <template #footer>
                    <v-divider />
                    <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="py-3" @input="(page) => $router.push({ query: { ...$route.query, page } })"></v-pagination>
                </template>
            </v-data-table>
            <account-view @input="update">
                <template #activator="{ attrs, on }">
                    <v-btn x-large fab color="primary" fixed right bottom v-bind="attrs" v-on="on">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </template>
            </account-view>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { inputAttrs, SNS_TYPES, switchAttrs } from "@/assets/variables";

import Draggable from "vuedraggable";
import ImagePopup from "@/components/console/dumb/image-popup.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import AccountView from "@/components/console/sns/account-view.vue";

const headers = [
    { divider: true, width: +60, text: "#", value: "index", align: "end" },
    { divider: true, width: 110, text: "유형", value: "type", formatter: (key) => SNS_TYPES[key]?.text || key },
    { divider: true, width: 170, text: "계정이름", value: "name" },
    { divider: true, width: 400, text: "계정주소", value: "href" },
    { divider: true, width: +80, text: "노출", value: "shows", align: "center" },
    { divider: true, width: +80, text: "자동갱신", value: "isContentsUpdateScheduled", class: "px-0", align: "center" },
    { divider: true, width: 160, text: "마지막갱신", value: "contentsUpdatedAt", formatter: (value) => value?.toDateTime?.() || "갱신기록없음", cellClass: "caption" },
    { divider: true, width: +80, text: "지금갱신", value: "updateContents", class: "px-0", align: "center" },
    { width: 100, value: "actions", align: "center" },
].map((item) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-") }));
export default {
    components: {
        VNodes: { functional: true, render: (h, ctx) => ctx.props.vnodes },

        Draggable,
        ImagePopup,
        ListHeading,
        AccountView,
    },
    data: () => ({
        accounts: [],

        limit: 10,
        summary: { totalCount: 0 },

        loading: false,

        headers,
        inputAttrs,
        switchAttrs,
    }),
    computed: {
        items() {
            return [...this.accounts];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { query } = this.$route;
            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                console.error(error);
            }
        },

        async search() {
            if (this.loading) return;
            this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, accounts } = await api.console.sns.accounts.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.accounts = accounts;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        async update(account) {
            if (this.loading) return;
            this.loading = true;

            try {
                account = (await api.console.sns.accounts.put(account))?.account;
                this.updateItem(account);
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
                this.search();
            }
        },
        async remove(account) {
            if (this.loading) return;
            this.loading = true;

            const go = confirm(`"${account?.name}" 항목을 삭제하시겠습니까?`);
            if (go) {
                try {
                    [{ account }] = [await api.console.sns.accounts.delete(account)];
                    this.accounts = this.accounts.filter(({ _id }) => _id !== account._id);
                    alert(`"${account?.name}" 항목이 삭제되었습니다.`);
                    this.summary.totalCount -= 1;
                } catch (error) {
                    this.$handleError(error);
                } finally {
                    this.loading = false;
                    this.search();
                }
            }
        },

        async setNewIndex({ moved = {} }) {
            if (this.loading) return;
            this.loading = true;

            try {
                let {
                    element: { _id, index },
                    oldIndex,
                    newIndex,
                } = moved;
                console.log({ index, oldIndex, newIndex });
                index += oldIndex - newIndex;
                console.log({ index });
                let form = { _id, index };
                let { account } = await api.console.sns.accounts.put(form);
                this.updateItem(account);
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
                this.search();
            }
        },

        async updateContents(item) {
            if (this.loading) return;
            this.loading = true;

            try {
                const { account } = await api.console.sns.accounts.updateContents(item);
                this.updateItem(account);
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
                this.search();
            }
        },

        updateItem(account) {
            const index = this.accounts.findIndex(({ _id }) => _id == account._id);
            if (0 <= index) this.accounts.splice(index, 1, account);
            else {
                this.accounts = [account, ...this.accounts];
                this.summary.totalCount += 1;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    tr {
        cursor: grab !important;
    }
    tr:active {
        cursor: grabbing !important;
    }
    td.text-truncate {
        max-width: 0;
    }
    td.text-align-right {
        text-align: right !important;
    }
    td.white-space-pre-line {
        white-space: pre-line;
    }
}
</style>

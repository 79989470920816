var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('v-card-subtitle', {
    staticClass: "d-flex caption font-weight-bold"
  }, [_vm._v(" 강의 "), _c('v-spacer'), _c('lecture-unit-view', {
    on: {
      "input": _vm.update
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "x-small": "",
            "text": "",
            "icon": "",
            "color": "primary",
            "title": "신규 생성"
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
      }
    }])
  })], 1), _c('v-expand-transition', [_c('v-card-text', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.items.length,
      expression: "items.length"
    }]
  }, [_c('v-list', {
    staticClass: "unit-list",
    attrs: {
      "two-line": "",
      "dense": ""
    }
  }, [_c('draggable', {
    model: {
      value: _vm.items,
      callback: function ($$v) {
        _vm.items = $$v;
      },
      expression: "items"
    }
  }, _vm._l(_vm.items, function (item, index) {
    return _c('v-list-item', {
      key: item.id,
      staticClass: "unit-item"
    }, [_c('v-list-item-avatar', {
      staticClass: "my-0"
    }, [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.name))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(new Date(null, null, null, null, null, null, item.duration || 0).toTimeString().split(" ")[0]))])], 1), _c('v-list-item-action', {
      staticClass: "my-0 mr-2"
    }, [_c('v-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('lecture-unit-view', _vm._b({
      ref: "unit-item",
      refInFor: true,
      attrs: {
        "value": item
      },
      on: {
        "input": _vm.update
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref2) {
          var attrs = _ref2.attrs,
            on = _ref2.on;
          return [_c('v-btn', _vm._g(_vm._b({
            attrs: {
              "x-small": "",
              "text": "",
              "icon": ""
            }
          }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-pencil")])], 1)];
        }
      }], null, true)
    }, 'lecture-unit-view', {
      lecture: _vm.lecture
    }, false)), _c('v-btn', {
      attrs: {
        "x-small": "",
        "text": "",
        "icon": ""
      },
      on: {
        "click": function ($event) {
          return _vm.remove(item);
        }
      }
    }, [_c('v-icon', [_vm._v(" mdi-delete")])], 1)], 1)], 1)], 1);
  }), 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
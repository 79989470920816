var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-expand-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showsSearch,
      expression: "showsSearch"
    }]
  }, [_c('v-card', {
    staticClass: "mb-3",
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v(_vm._s(_vm.label))]), _c('v-card-text', [_vm._t("default")], 2), !_vm.hideAction ? _c('v-card-actions', [_c('v-spacer'), _c('v-btn', _vm._b({
    attrs: {
      "color": "primary",
      "rounded": "",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('search');
      }
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-magnify")]), _vm._v(" Search ")], 1)], 1) : _vm._e()], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-layout justify-center>
        <v-responsive class="px-3">
            <list-heading title="메인 미니멀리즘 설정" class="mt-8" />
            <v-card class="my-4" style="overflow: hidden">
                <banner-input mode="horizontal" label="미니멀리즘" sublabel="권장사이즈:330*500px" name="메인 미니멀리즘" code="main-product-banners" style="height: 653px" />
            </v-card>
        </v-responsive>
    </v-layout>
</template>

<script>
import ListHeading from "@/components/console/dumb/list-heading.vue";
import BannerInput from "@/components/console/banners/banner-input.vue";

export default {
    components: {
        ListHeading,
        BannerInput,
    },
};
</script>

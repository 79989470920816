<template>
    <v-container fluid fill-height class="console-terms">
        <v-layout v-if="setting" justify-center>
            <v-responsive max-width="720px" width="100%">
                <v-row class="mt-8" justify="center" align="center">
                    <v-col cols="auto" class="pt-0 pb-0 headline text-start">메인 유튜브 비디오 설정</v-col>
                    <v-spacer></v-spacer>
                </v-row>

                <v-data-table v-bind="{ headers, items }" disable-sort disable-pagination hide-default-footer class="v-sheet--outlined mt-4" :items-per-page="-1" ref="data-table">
                    <template #body="props">
                        <draggable v-if="$refs['data-table']" tag="tbody" :list="props.items" @change="setNewIndex" :sort="!loading">
                            <v-nodes :vnodes="$refs['data-table'].genItems(props.items, props)" />
                        </draggable>
                    </template>
                    <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                    <template #[`item.index`]="{ index }">
                        {{ index + 1 }}
                    </template>
                    <template #[`item.youtube`]="{ item }">
                        <v-text-field v-model="item.youtube" hide-details flat class="pa-3 my-0" @input="emit" />
                    </template>
                    <template #[`item.videoId`]="{ item }">
                        <div style="max-width: 100%; width: 100%"><youtube v-bind="item" style="margin-bottom: -7px; magin-right: -1px" width="150" height="82" :playerVars="{ controls: 0, showinfo: 0 }" /></div>
                    </template>
                    <template #[`item.actions`]="{ index }">
                        <v-btn text icon color="red" @click="pull(index)">
                            <v-icon>mdi-minus</v-icon>
                        </v-btn>
                    </template>
                    <template #footer>
                        <v-divider />
                        <v-row justify="end" class="ma-0">
                            <v-col cols="auto" class="px-4">
                                <v-btn text icon color="primary" @click="push">
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-divider />
                        <v-row justify="end">
                            <v-col cols="auto">
                                <v-btn text color="primary" @click="save">저장</v-btn>
                            </v-col>
                        </v-row>
                    </template>
                </v-data-table>
            </v-responsive>
        </v-layout>
    </v-container>
</template>

<script>
import api from "@/api";

import Draggable from "vuedraggable";
import { initSetting, initSettingMainVideo } from "@/assets/variables";

const headers = [
    { width: +60, text: "#", value: "index" },
    { width: 440, text: "링크", value: "youtube", cellClass: "pa-0" },
    { width: 150, text: "미리보기", value: "videoId", cellClass: "pa-0 max-width-0" },
    { width: +68, text: "", value: "actions" },
].map((item, index, array) => ({ ...item, formatter: item.formatter ?? ((value) => value ?? "-"), divider: index != array.length - 1, class: (item.class || "") + " white-space-pre-line", cellClass: (item.cellClass || "py-3") + " caption line-height-1-5 white-space-pre-line vertical-align-top" }));

export default {
    components: {
        VNodes: { functional: true, render: (h, ctx) => ctx.props.vnodes },
        Draggable,
    },
    data: () => ({
        setting: initSetting(),

        loading: false,

        headers,
    }),
    computed: {
        items() {
            return [...this.setting.main.videos];
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            await this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;
            try {
                var { setting } = await api.console.setting.get();
                this.setting = initSetting(setting);
            } finally {
                this.loading = false;
            }
        },
        async emit() {
            this.setting = initSetting(this.setting);
        },

        async push() {
            this.setting.main.videos.push(initSettingMainVideo());
        },

        async pull(index) {
            this.setting.main.videos.splice(index, 1);
        },

        async setNewIndex({ moved = {} }) {
            let { oldIndex, newIndex } = moved;
            this.setting.main.videos.splice(newIndex, 0, ...this.setting.main.videos.splice(oldIndex, 1));
        },

        async save() {
            if (this.loading) return;
            else this.loading = true;
            try {
                const { _id, main } = this.setting;
                await api.console.setting.put({ _id, main });

                alert("저장되었습니다.");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .max-width-0 {
        max-width: 0;
    }
}
</style>
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "console-terms",
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_vm.setting ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "720px",
      "width": "100%"
    }
  }, [_c('v-row', {
    staticClass: "mt-8",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pt-0 pb-0 headline text-start",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("메인 유튜브 비디오 설정")]), _c('v-spacer')], 1), _c('v-data-table', _vm._b({
    ref: "data-table",
    staticClass: "v-sheet--outlined mt-4",
    attrs: {
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": "",
      "items-per-page": -1
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (props) {
        return [_vm.$refs['data-table'] ? _c('draggable', {
          attrs: {
            "tag": "tbody",
            "list": props.items,
            "sort": !_vm.loading
          },
          on: {
            "change": _vm.setNewIndex
          }
        }, [_c('v-nodes', {
          attrs: {
            "vnodes": _vm.$refs['data-table'].genItems(props.items, props)
          }
        })], 1) : _vm._e()];
      }
    }, _vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.index`,
      fn: function (_ref2) {
        var index = _ref2.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: `item.youtube`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-text-field', {
          staticClass: "pa-3 my-0",
          attrs: {
            "hide-details": "",
            "flat": ""
          },
          on: {
            "input": _vm.emit
          },
          model: {
            value: item.youtube,
            callback: function ($$v) {
              _vm.$set(item, "youtube", $$v);
            },
            expression: "item.youtube"
          }
        })];
      }
    }, {
      key: `item.videoId`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticStyle: {
            "max-width": "100%",
            "width": "100%"
          }
        }, [_c('youtube', _vm._b({
          staticStyle: {
            "margin-bottom": "-7px",
            "magin-right": "-1px"
          },
          attrs: {
            "width": "150",
            "height": "82",
            "playerVars": {
              controls: 0,
              showinfo: 0
            }
          }
        }, 'youtube', item, false))], 1)];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref5) {
        var index = _ref5.index;
        return [_c('v-btn', {
          attrs: {
            "text": "",
            "icon": "",
            "color": "red"
          },
          on: {
            "click": function ($event) {
              return _vm.pull(index);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-minus")])], 1)];
      }
    }, {
      key: "footer",
      fn: function () {
        return [_c('v-divider'), _c('v-row', {
          staticClass: "ma-0",
          attrs: {
            "justify": "end"
          }
        }, [_c('v-col', {
          staticClass: "px-4",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          attrs: {
            "text": "",
            "icon": "",
            "color": "primary"
          },
          on: {
            "click": _vm.push
          }
        }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1)], 1), _c('v-divider'), _c('v-row', {
          attrs: {
            "justify": "end"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          attrs: {
            "text": "",
            "color": "primary"
          },
          on: {
            "click": _vm.save
          }
        }, [_vm._v("저장")])], 1)], 1)];
      },
      proxy: true
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items
  }, false))], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
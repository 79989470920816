var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--small"
  }, [!_vm.hideTitle ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-select', _vm._b({
    staticStyle: {
      "width": "100px"
    },
    attrs: {
      "name": "name_title",
      "items": _vm.titles,
      "label": "Title"
    },
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v(" Title "), _c('span', {
          staticStyle: {
            "color": "#ff0000"
          }
        }, [_vm._v("*")])];
      },
      proxy: true
    }], null, false, 1935776755),
    model: {
      value: _vm.name_title,
      callback: function ($$v) {
        _vm.name_title = $$v;
      },
      expression: "name_title"
    }
  }, 'v-select', _vm.$attrs, false))], 1) : _vm._e(), _c('v-col', [_c('v-text-field', _vm._b({
    attrs: {
      "name": "name_first",
      "label": "First Name",
      "hide-details": "auto"
    },
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v(" First Name "), _c('span', {
          staticStyle: {
            "color": "#ff0000"
          }
        }, [_vm._v("*")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.name_first,
      callback: function ($$v) {
        _vm.name_first = $$v;
      },
      expression: "name_first"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1), !_vm.hideMiddle ? _c('v-col', [_c('v-text-field', _vm._b({
    attrs: {
      "name": "name_middle",
      "label": "Middle Name",
      "hide-details": "auto"
    },
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v(" Middle Name ")];
      },
      proxy: true
    }], null, false, 3412401585),
    model: {
      value: _vm.name_middle,
      callback: function ($$v) {
        _vm.name_middle = $$v;
      },
      expression: "name_middle"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1) : _vm._e(), _c('v-col', [_c('v-text-field', _vm._b({
    attrs: {
      "name": "name_last",
      "hide-details": "auto"
    },
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v(" Last Name "), _c('span', {
          staticStyle: {
            "color": "#ff0000"
          }
        }, [_vm._v("*")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.name_last,
      callback: function ($$v) {
        _vm.name_last = $$v;
      },
      expression: "name_last"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-layout justify-center>
        <v-responsive max-width="1080" width="100%">
            <list-heading title="라이브 관리" class="mt-8" />
            <v-data-table v-bind="{ headers, items }" hide-default-footer disable-sort disable-filtering disable-pagination class="my-4">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.src`]="{ value }">
                    <image-popup size="40" :src="value" />
                </template>
                <template #[`item.state`]="{ item, value }">
                    <v-edit-dialog large :return-value.sync="item.state" @save="save({ _id: item._id, state: item.state })">
                        <div>{{ getStateText(value) }}</div>
                        <template #input>
                            <v-select v-model="item.state" :items="liveStates" v-bind="inputAttrs" dense class="my-4" single-line />
                        </template>
                    </v-edit-dialog>
                </template>
                <template #[`item.actions`]="{ item }">
                    <live-view :value="item" @input="update">
                        <template #activator="{attrs,on}">
                            <v-btn small text icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                    </live-view>
                    <v-btn small text icon @click="remove(item)">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </template>
                <template #footer>
                    <v-divider />
                    <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="py-3" @input="search" />
                </template>
            </v-data-table>
            <live-view @input="update">
                <template #activator="{attrs,on}">
                    <v-btn x-large fab color="primary" fixed right bottom v-bind="attrs" v-on="on">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </template>
            </live-view>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { ACADEMY_LIVE_STATES, inputAttrs } from "@/assets/variables";
import LiveView from "@/components/console/academy/live-view.vue";
import ImagePopup from "@/components/console/dumb/image-popup.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
const headers = [
    { width: 40, value: "index", align: "right" },
    { width: 80, text: "썸네일", value: "src" },
    { text: "제목", value: "name", class: "text-truncate" },
    { text: "링크", value: "href", class: "text-truncate" },
    { width: 180, text: "상태", value: "state", align: "center" },
    { width: 110, text: "진행일", value: "scheduledAt", formatter: (value) => value?.toDate?.() || "미정" },
    { width: 110, text: "작성일", value: "createdAt", formatter: (value) => value?.toDateTime?.() },
    { width: 90, value: "actions", align: "right" },
];
const liveStates = Object.values(ACADEMY_LIVE_STATES);
export default {
    components: {
        LiveView,
        ImagePopup,
        ListHeading,
    },
    data: () => ({
        headers,
        liveStates,
        inputAttrs,

        page: 1,
        limit: 10,
        filter: {},

        summary: { totalCount: 0 },
        lives: [],
    }),
    computed: {
        items() {
            return this.lives.map((item, i) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - i }));
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
    },
    mounted() {
        this.init().then(this.search);
    },
    methods: {
        async init() {
            this.page = +this.$route.query.page || 1;
            this.lives = [];
            this.summary = { totalCount: 0 };
        },
        async search() {
            try {
                const { page, skip, limit } = this;
                this.$router.push({ query: Object.assignDefined({}, this.$route.query, this.filter, { page }) });

                var { summary, lives } = await api.console.academy.lives.gets({
                    headers: { skip, limit },
                    params: this.filter,
                });

                this.lives = lives;
                this.summary = summary;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.email);
                else alert(error.email.toString());
            }
        },
        async update(item) {
            const index = this.lives.findIndex(({ _id }) => _id == item._id);
            if (index > -1) this.lives.splice(index, 1, item);
            else {
                this.lives = [item, ...this.lives];
                this.summary.totalCount += 1;
            }
        },
        async remove({ _thumb, _id, name }) {
            const go = confirm(`${name} - 데이터를 삭제하시겠습니까?`);
            if (go) {
                if (_thumb) await api.console.commonFiles.delete({ _id: _thumb });
                await api.console.academy.lives.delete({ _id });
                this.lives = this.lives.filter((live) => live?._id != _id);
            }
        },
        async save(item, props) {
            const { live } = await api.console.academy.lives.put(item);
            this.update(live);
        },
        getStateText(key) {
            return ACADEMY_LIVE_STATES[key]?.text || "-";
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep td.text-truncate {
    max-width: 0;
}
</style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-chip-group', [_c('draggable', {
    attrs: {
      "draggable": ".file-item"
    },
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        var _vm$files;
        return [(((_vm$files = _vm.files) === null || _vm$files === void 0 ? void 0 : _vm$files.length) || 0) < 1 ? _c('v-chip', {
          staticClass: "pa-0",
          attrs: {
            "small": "",
            "color": "transparent"
          },
          on: {
            "click": function ($event) {
              var _vm$$refs$fileInput, _vm$$refs$fileInput$$, _vm$$refs$fileInput$$2, _vm$$refs$fileInput$$3;
              (_vm$$refs$fileInput = _vm.$refs['file-input']) === null || _vm$$refs$fileInput === void 0 ? void 0 : (_vm$$refs$fileInput$$ = _vm$$refs$fileInput.$el.getElementsByTagName('input')) === null || _vm$$refs$fileInput$$ === void 0 ? void 0 : (_vm$$refs$fileInput$$2 = _vm$$refs$fileInput$$[0]) === null || _vm$$refs$fileInput$$2 === void 0 ? void 0 : (_vm$$refs$fileInput$$3 = _vm$$refs$fileInput$$2.click) === null || _vm$$refs$fileInput$$3 === void 0 ? void 0 : _vm$$refs$fileInput$$3.call(_vm$$refs$fileInput$$2);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-plus")]), _c('v-file-input', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false"
          }],
          ref: "file-input",
          model: {
            value: _vm.file,
            callback: function ($$v) {
              _vm.file = $$v;
            },
            expression: "file"
          }
        })], 1) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.files,
      callback: function ($$v) {
        _vm.files = $$v;
      },
      expression: "files"
    }
  }, [_vm._l(_vm.files, function (file, index) {
    return [_c('software-file-item', _vm._b({
      key: file === null || file === void 0 ? void 0 : file.id,
      ref: "file-item",
      refInFor: true,
      attrs: {
        "value": file
      },
      on: {
        "input": _vm.update,
        "remove": function ($event) {
          return _vm.remove(file);
        }
      }
    }, 'software-file-item', {
      software: _vm.value,
      index
    }, false))];
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "px-3",
    attrs: {
      "max-width": "720",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('list-heading', {
    staticClass: "mt-8",
    attrs: {
      "title": _vm.isCreate ? '상품 등록' : '상품 상세'
    }
  }), _c('v-card', {
    staticClass: "my-4",
    staticStyle: {
      "overflow": "hidden"
    }
  }, [_c('v-card-subtitle', {
    staticClass: "grey lighten-4 font-weight-bold"
  }, [_vm._v("기본정보")]), _c('v-divider'), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('category-select', _vm._b({
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.product._category,
      callback: function ($$v) {
        _vm.$set(_vm.product, "_category", $$v);
      },
      expression: "product._category"
    }
  }, 'category-select', {
    categories: _vm.categories
  }, false))], 1), _c('v-col', {
    attrs: {
      "col": "12",
      "sm": "6"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "label": "유형",
      "items": _vm.productTypes
    },
    model: {
      value: _vm.product.type,
      callback: function ($$v) {
        _vm.$set(_vm.product, "type", $$v);
      },
      expression: "product.type"
    }
  }, 'v-select', _vm.inputAttrs, false))], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "label": "상품명"
    },
    model: {
      value: _vm.product.name,
      callback: function ($$v) {
        _vm.$set(_vm.product, "name", $$v);
      },
      expression: "product.name"
    }
  }, 'v-text-field', _vm.inputAttrs, false))], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-select', _vm._b({
    attrs: {
      "label": "카탈로그",
      "items": [{
        _id: null,
        name: '없음'
      }].concat(_vm.catalogues),
      "item-text": "name",
      "item-value": "_id"
    },
    scopedSlots: _vm._u([{
      key: "append-outer",
      fn: function () {
        return [_c('catalogue-view', _vm._b({
          on: {
            "input": function (catalogue) {
              return (_vm.product._catalogue = catalogue === null || catalogue === void 0 ? void 0 : catalogue._id) && _vm.catalogues.push(catalogue);
            }
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref) {
              var attrs = _ref.attrs,
                on = _ref.on;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "ml-3",
                attrs: {
                  "text": "",
                  "icon": "",
                  "tile": "",
                  "color": "primary",
                  "title": "신규 생성"
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
            }
          }], null, false, 2110681160)
        }, 'catalogue-view', {
          categories: _vm.categories
        }, false))];
      },
      proxy: true
    }], null, false, 1244495933),
    model: {
      value: _vm.product._catalogue,
      callback: function ($$v) {
        _vm.$set(_vm.product, "_catalogue", $$v);
      },
      expression: "product._catalogue"
    }
  }, 'v-select', _vm.inputAttrs, false))], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('product-image-download', _vm._b({
    ref: "image-download",
    attrs: {
      "label": "이미지 다운로드"
    },
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  }, 'product-image-download', _vm.inputAttrs, false))], 1)], 1)], 1), _c('v-divider'), _c('v-card-subtitle', {
    staticClass: "grey lighten-4 font-weight-bold"
  }, [_vm._v("이미지")]), _c('v-divider'), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": ""
    }
  }, [_c('product-thumbnail', {
    ref: "thumbnail",
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": ""
    }
  }, [_c('product-image-mobile', {
    ref: "image-mobile",
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": ""
    }
  }, [_c('product-image-desktop', {
    ref: "image-desktop",
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  })], 1)], 1), _c('v-card-subtitle', {
    staticClass: "grey lighten-4 font-weight-bold"
  }, [_vm._v("상세정보")]), _c('v-divider'), _c('v-card-text', [_c('naver-smarteditor', {
    attrs: {
      "bSkipXssFilter": ""
    },
    model: {
      value: _vm.product.content,
      callback: function ($$v) {
        _vm.$set(_vm.product, "content", $$v);
      },
      expression: "product.content"
    }
  })], 1), _c('v-divider'), _c('product-documents', _vm._b({
    model: {
      value: _vm.product.documents,
      callback: function ($$v) {
        _vm.$set(_vm.product, "documents", $$v);
      },
      expression: "product.documents"
    }
  }, 'product-documents', {
    documents: _vm.documents
  }, false)), _c('v-divider'), _c('v-card-subtitle', {
    staticClass: "grey lighten-4 font-weight-bold"
  }, [_vm._v("연관상품")]), _c('v-divider'), _c('autocomplete-product', {
    staticClass: "rounded-0",
    attrs: {
      "params": _vm.params__productsRelated,
      "multiple": "",
      "customSelection": "",
      "flat": "",
      "solo": "",
      "hide-details": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref2) {
        var item = _ref2.item,
          parent = _ref2.parent;
        return [_c('v-chip', {
          attrs: {
            "text": "",
            "outlined": "",
            "label": "",
            "close": ""
          },
          on: {
            "click:close": function ($event) {
              return parent.selectItem(item);
            }
          }
        }, [_vm._v(" " + _vm._s(item.name))])];
      }
    }], null, false, 2503302992),
    model: {
      value: _vm.product._products__related,
      callback: function ($$v) {
        _vm.$set(_vm.product, "_products__related", $$v);
      },
      expression: "product._products__related"
    }
  })], 1), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }
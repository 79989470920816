<template>
    <v-select v-model="form.state" class="caption transparent" color="transparent" v-bind="{ ...attrs_input__verticalTable, items, loading }" @change="save" />
</template>

<script>
import { attrs_input__verticalTable, FORM_STATES, initForm } from "@/assets/variables";

const items = Object.values(FORM_STATES);

export default {
    props: {
        value: { type: Object, default: initForm },
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        form: initForm(),

        items,
        attrs_input__verticalTable,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initForm(this.value);
        },
        save() {
            let { _id, state } = this.form;
            let form = { _id, state };

            this.$emit("update", form);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-input__slot {
        background: none !important;
    }
}
</style>
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', _vm._b({
    ref: "data-table",
    staticClass: "my-4 v-sheet--outlined rounded-0",
    attrs: {
      "hide-default-footer": "",
      "disable-sort": "",
      "disable-filtering": "",
      "disable-pagination": "",
      "items-per-page": -1
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (props) {
        return [_vm.$refs['data-table'] ? _c('draggable', {
          attrs: {
            "tag": "tbody",
            "list": props.items,
            "sort": !_vm.loading
          },
          on: {
            "change": _vm.setNewIndex
          }
        }, [_c('v-nodes', {
          attrs: {
            "vnodes": _vm.$refs['data-table'].genItems(props.items, props)
          }
        })], 1) : _vm._e()];
      }
    }, {
      key: "top",
      fn: function () {
        return [_c('v-subheader', [_vm._v(_vm._s(_vm.label || "노출상품 설정"))]), _c('v-divider')];
      },
      proxy: true
    }, _vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.thumbnail`,
      fn: function (_ref2) {
        var value = _ref2.value;
        return [_c('image-popup', {
          attrs: {
            "size": "40",
            "src": value === null || value === void 0 ? void 0 : value.url
          }
        })];
      }
    }, {
      key: `item.onCategoryShows`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-row', {
          attrs: {
            "justify": "center",
            "align": "center"
          }
        }, [_c('v-col', {
          staticClass: "pa-0",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-switch', _vm._b({
          staticClass: "ml-1 mr-n4",
          attrs: {
            "color": "red lighten-1"
          },
          on: {
            "change": function (onCategoryShows) {
              return _vm.setNewShows({
                _id: item._id,
                onCategoryShows
              });
            }
          },
          model: {
            value: item.onCategoryShows,
            callback: function ($$v) {
              _vm.$set(item, "onCategoryShows", $$v);
            },
            expression: "item.onCategoryShows"
          }
        }, 'v-switch', _vm.switchAttrs, false))], 1)], 1)];
      }
    }], null, true)
  }, 'v-data-table', {
    loading: _vm.loading,
    headers: _vm.headers,
    items: _vm.items
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }
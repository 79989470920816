var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn-toggle', {
    staticClass: "d-flex",
    attrs: {
      "mandatory": "",
      "borderless": ""
    },
    on: {
      "change": _vm.emit
    },
    model: {
      value: _vm.group,
      callback: function ($$v) {
        _vm.group = $$v;
      },
      expression: "group"
    }
  }, [_vm._l(_vm.items, function (item, index) {
    return [_c('v-col', {
      key: item.value,
      staticClass: "pa-0"
    }, [_c('v-btn', _vm._b({
      attrs: {
        "value": item.value,
        "text": "",
        "tile": "",
        "block": "",
        "active-class": "primary--text"
      }
    }, 'v-btn', {
      loading: _vm.loading
    }, false), [_vm._v(" " + _vm._s(item.text) + " ")])], 1), index != _vm.items.length - 1 ? _c('v-divider', {
      key: `${index}-divider`,
      attrs: {
        "vertical": ""
      }
    }) : _vm._e()];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', _vm._b({
    attrs: {
      "hide-default-footer": "",
      "disable-filtering": "",
      "disable-sort": "",
      "disable-pagination": "",
      "items-per-page": -1,
      "show-select": "",
      "single-select": "",
      "item-key": "tempId"
    },
    scopedSlots: _vm._u([{
      key: `item.type`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-select', {
          attrs: {
            "items": _vm.websiteTypes,
            "flat": "",
            "solo": "",
            "hide-details": ""
          },
          on: {
            "change": _vm.emit
          },
          model: {
            value: item.type,
            callback: function ($$v) {
              _vm.$set(item, "type", $$v);
            },
            expression: "item.type"
          }
        })];
      }
    }, {
      key: `item.href`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-text-field', {
          attrs: {
            "placeholder": "https://www.youtube.com",
            "flat": "",
            "solo": "",
            "hide-details": ""
          },
          on: {
            "input": _vm.emit
          },
          model: {
            value: item.href,
            callback: function ($$v) {
              _vm.$set(item, "href", $$v);
            },
            expression: "item.href"
          }
        })];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-btn', {
          attrs: {
            "icon": "",
            "text": "",
            "color": "error"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-minus")])], 1)];
      }
    }, {
      key: "footer",
      fn: function () {
        return [_c('v-divider'), _c('v-row', {
          staticClass: "ma-0"
        }, [_c('v-col', {
          staticClass: "px-4 py-2",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          attrs: {
            "icon": "",
            "text": "",
            "color": "primary",
            "disabled": _vm.upDisabled
          },
          on: {
            "click": _vm.upItem
          }
        }, [_c('v-icon', [_vm._v("mdi-chevron-up")])], 1)], 1), _c('v-col', {
          staticClass: "px-4 py-2",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          attrs: {
            "icon": "",
            "text": "",
            "color": "primary",
            "disabled": _vm.downDisabled
          },
          on: {
            "click": _vm.downItem
          }
        }, [_c('v-icon', [_vm._v("mdi-chevron-down")])], 1)], 1), _c('v-spacer'), _c('v-col', {
          staticClass: "px-4 py-2",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          attrs: {
            "icon": "",
            "text": "",
            "color": "primary",
            "disabled": 3 < _vm.items.length
          },
          on: {
            "click": _vm.add
          }
        }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1)], 1)];
      },
      proxy: true
    }], null, true),
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }